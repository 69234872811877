<template>
  <div class="info">
    <div class="info__header">
      <div class="info__controls">
        <a href="javascript:void(0)" @click="$emit('back')" class="back">
          <svg class="icon icon-arrow">
            <use xlink:href="#icon-arrow"></use>
          </svg>
          {{ $t('back') }}
        </a>
        <div class="close js-manInfoClose" @click="$emit('toggle-info')">
          <svg class="icon icon-close">
            <use xlink:href="#icon-close"></use>
          </svg>
        </div>
      </div>
      <div class="info__title">
        <div class="title">
          <div class="title__gerb">
            <img :src="district.coat_of_arms" alt="{{district.title}}" class="zone-district__img">
          </div>
          {{district.title}}
        </div>
      </div>
    </div>
    <swiper
        :direction="'vertical'"
        :slidesPerView="'auto'"
        :freeMode="true"
        :scrollbar="true"
        :mousewheel="true"
        :modules="modules"
        class="info__container swiper"
    >
      <swiper-slide>
        <div class="info__group">
          <div class="district-head">
            <div class="district-head__photo">
              <img :src="district.head_photo" class="district-head__img" alt="{{district.head}}">
            </div>
            <div class="district-head__title">
              <div class="district-head__name">{{district.head}}</div>
              <div class="district-head__post">{{ $t('head_municipality') }} {{district.title}}</div>
            </div>
          </div>
        </div>
        <div class="info__group">
          <div class="info__group-header">
            <div class="subtitle">{{ $t('investment_projects') }}</div>
          </div>
          <div class="info__group-body">
            <div class="project-list">
              <a v-for="project in normalizedProjects" v-bind:key="project.title" href="javascript:void(0)" @click="$emit('project-selected', project)" class="project-list__item">
                <div class="project-list__icon"><img :src="project.industry_icon" alt="" class="project-list__img"></div>
                <div class="project-list__name">{{project.name}}</div>
              </a>
            </div>
          </div>
        </div>
        <div class="info__group">
          <div class="info__group-header">
            <div class="subtitle">{{ $t('investment_platforms') }}</div>
          </div>
          <div class="info__group-body">
            <div class="project-list">
              <a v-for="project in normalizedInvestPlatform" v-bind:key="project.title" href="javascript:void(0)" @click="$emit('project-selected', project)" class="project-list__item">
                <div class="project-list__icon"><img :src="project.industry_icon" alt="" class="project-list__img"></div>
                <div class="project-list__name">{{project.name}}</div>
              </a>
            </div>
          </div>
        </div>
        <div class="info__group">
          <div class="info__group-header">
            <a :href="district.link" class="subtitle subtitle--link">{{ $t('area_information') }}</a>
          </div>
          <div class="info__group-body">
            <div class="text-indicator">
              <div class="text-indicator__item">
                <div class="text-indicator__value">{{district.capital}}</div>
                <div class="text-indicator__name">{{ $t('administrative_center') }}</div>
              </div>
              <div class="text-indicator__item">
                <div class="text-indicator__value">{{district.area}} м<sup>2</sup></div>
                <div class="text-indicator__name">{{ $t('district_area') }}</div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import "swiper/swiper-bundle.min.css";
import "swiper/modules/free-mode/free-mode.min.css";
import "swiper/modules/scrollbar/scrollbar.min.css";

import { FreeMode, Scrollbar, Mousewheel } from "swiper";

export default {
  name: "DistrictInfo",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [FreeMode, Scrollbar, Mousewheel],
    };
  },
  props: {
    district: Object,
    projects: Array
  },
  computed: {
    normalizedProjects: function () {
      return this.projects.filter(projects => {
        return projects.district_code === this.district.code && projects.type_project_id === "investment_project"
      });
    },
    normalizedInvestPlatform: function () {
      return this.projects.filter(projects => {
        return projects.district_code === this.district.code && projects.type_project_id === "investment_platform"
      });
    }
  }
}
</script>

<style scoped>

</style>