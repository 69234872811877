<template>
  <div class="info">
    <div class="info__header">
      <div class="info__controls">
        <a href="javascript:void(0)" @click="$emit('back')" class="back">
          <svg class="icon icon-arrow">
            <use xlink:href="#icon-arrow"></use>
          </svg>
          {{ $t('back') }}
        </a>
        <div class="close js-manInfoClose" @click="$emit('toggle-info')">
          <svg class="icon icon-close">
            <use xlink:href="#icon-close"></use>
          </svg>
        </div>
      </div>
      <div class="info__title">
        <div class="title">{{ zone.title }}</div>
      </div>
    </div>
    <swiper
        :direction="'vertical'"
        :slidesPerView="'auto'"
        :freeMode="true"
        :scrollbar="true"
        :mousewheel="true"
        :modules="modules"
        class="info__container swiper"
    >
      <swiper-slide>
        <div class="info__group">
          <div class="info__group-header">
            <div class="subtitle">{{ $t('municipalities')}}</div>
          </div>
          <div class="info__group-body">
            <div v-for="district in normalizedDistricts" v-bind:key="district.title" class="zone-district">
              <div class="zone-district__item" @click="selectDistrict(district)">
                <div class="zone-district__gerb"><img :src="district.coat_of_arms" alt="" class="zone-district__img"></div>
                {{ district.title }}
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import "swiper/swiper-bundle.min.css";
import "swiper/modules/free-mode/free-mode.min.css";
import "swiper/modules/scrollbar/scrollbar.min.css";

import { FreeMode, Scrollbar, Mousewheel } from "swiper";

export default {
  name: "DistrictsList",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [FreeMode, Scrollbar, Mousewheel],
    };
  },
  props: {
    zone: Object,
    districts: Array
  },
  computed: {
    normalizedDistricts: function () {
      return this.districts.filter(district => district.zone_code === this.zone.code);
    },
  },
  methods: {
    selectDistrict(district) {
      this.$emit('district-selected', district.code);
    }
  }
}
</script>

<style scoped>

</style>