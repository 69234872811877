export default {
  "select_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an economic district"])},
  "select_district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select municipality"])},
  "select_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a project"])},
  "hide_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide description"])},
  "show_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show description"])},
  "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
  "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site"])},
  "interactive_catalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interactive catalog"])},
  "investment_proposals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of investment proposals"])},
  "type_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TYPE OF A PROPOSALS"])},
  "industry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INDUSTRY AFFILIATION"])},
  "municipalities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["municipalities"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "head_municipality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Head of the municipality"])},
  "investment_projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment projects"])},
  "investment_platforms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment sites"])},
  "area_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["about district"])},
  "administrative_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrative center"])},
  "district_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["District area"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
  "total_cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total cost of the project, million rubles"])},
  "investments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investments"])},
  "need_investments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The need to attract investments, million rubles"])},
  "return_investment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return on investment"])},
  "implementation_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Implementation period"])},
  "workplaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workplaces"])},
  "new_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of new jobs, units"])},
  "volume_revenues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The volume of revenues to the budget"])},
  "budget_revenue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annual revenues to the regional budget, million rubles"])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
  "investment_authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment commissioner"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose all"])},
  "cadastral_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cadastral number"])},
  "hazard_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hazard class"])},
  "project_capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project capacity"])},
  "industry_affiliation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industry affiliation"])},
  "declared_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declared area, m"])},
  "land_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land category"])},
  "permitted_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of permitted use"])},
  "additional_permitted_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional uses of the site"])},
  "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner (copyright holder)"])},
  "distance_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance from (km)"])},
  "distance_to_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the administrative center of the municipality"])},
  "nearest_town": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nearest town"])},
  "krasnodar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["city of Krasnodar"])},
  "highways": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["highways (federal, regional, local)"])},
  "nearest_railway_station": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nearest railway station"])},
  "nearest_railroad_tracks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nearest railroad tracks"])},
  "airport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["airport"])},
  "seaport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seaport"])},
  "power_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power supply"])},
  "name_power_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the power center"])},
  "distance_to_connection_point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance to the nearest connection point (m)"])},
  "free_power": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free power (MW)"])},
  "free_power_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To be defined under obtaining technical conditions"])},
  "gas_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas supply"])},
  "name_gds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the GDS"])},
  "throughput_capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Throughput capacity (thousand cubic meters per year)"])},
  "water_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water supply"])},
  "source_water_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source of water supply"])},
  "power_water_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power (cubic meters per day)"])}
}