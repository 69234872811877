export default {
  "select_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите экономический округ"])},
  "select_district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите муниципальное образование"])},
  "select_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите проект"])},
  "hide_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрыть описание"])},
  "show_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать описание"])},
  "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проект"])},
  "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Площадка"])},
  "interactive_catalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интерактивный каталог"])},
  "investment_proposals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["инвестиционных предложений"])},
  "type_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТИП ПРЕДЛОЖЕНИЯ"])},
  "industry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОТРАСЛЕВАЯ ПРИНАДЛЕЖНОСТЬ"])},
  "municipalities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["муниципальные образования"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
  "head_municipality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Глава муниципального образования"])},
  "investment_projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инвестиционные проекты"])},
  "investment_platforms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инвестиционные площадки"])},
  "area_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["информация о районе"])},
  "administrative_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Административный центр"])},
  "district_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Площадь района"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месторасположение"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стоимость"])},
  "total_cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая стоимость проекта, млн руб."])},
  "investments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инвестиции"])},
  "need_investments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребность в инвестициях, млн руб."])},
  "return_investment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок возврата инвестиций"])},
  "implementation_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок реализации"])},
  "workplaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рабочие места"])},
  "new_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Число новых рабочих мест, ед."])},
  "volume_revenues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поступления в бюджет"])},
  "budget_revenue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среднегодовая сумма налоговых платежей, млн руб."])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])},
  "investment_authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инвеступолномоченный"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронная почта"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
  "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать все"])},
  "cadastral_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кадастровый номер"])},
  "industry_affiliation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отраслевая принадлежность"])},
  "hazard_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Класс опасности"])},
  "project_capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проектная мощность"])},
  "declared_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Площадь декларированная, м"])},
  "land_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория земель"])},
  "permitted_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вид разрешенного использования"])},
  "additional_permitted_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительные виды использования участка"])},
  "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Собственник (правообладатель)"])},
  "distance_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаленность от (км)"])},
  "distance_to_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["административного центра муниципального образования"])},
  "nearest_town": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ближайшего населенного пункта"])},
  "krasnodar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["города Краснодара"])},
  "highways": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["автодороги (федерального, краевого, местного значения)"])},
  "nearest_railway_station": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ближайшей железнодорожной станции"])},
  "nearest_railroad_tracks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ближайших железнодорожных путей"])},
  "airport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["аэропорта"])},
  "seaport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["морского порта"])},
  "power_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электроснабжение"])},
  "name_power_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование центра питания"])},
  "distance_to_connection_point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаленность до ближайшей точки подключения (м)"])},
  "free_power": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свободная мощность (МВт)"])},
  "free_power_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["уточняется при получении ТУ"])},
  "gas_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Газоснабжение"])},
  "name_gds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование ГРС"])},
  "throughput_capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пропускная мощность (тыс. куб. м. в год)"])},
  "water_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Водоснабжение"])},
  "source_water_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Источник водоснабжения"])},
  "power_water_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мощность (куб. м. в сутки)"])}
}