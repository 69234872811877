<template>
  <svg v-show="level < 4" ref="svgMap" @mouseover="stopAnimation" @mouseleave="startAnimation" id="map"
       viewBox="0 0 880 750" class="js-MapSvg" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
    <g ref="map">
      <g data-zone="severnaya" @click="selectZone(0)" @mouseenter="hoverZone" class="js-MapZone">
        <g data-district="eyskiy_rayon" class="js-MapDistrict">
          <polygon id="Ейский" class="st0 js-MapDistrictPolygon" points="214.7,39.5 219.6,39.5 228.2,47.6 238,52.6 249.7,54.2 257.9,54.2 263.4,53.7
            272.9,49.3 280.5,44.4 287,43.3 296.1,42.5 302.7,38 305.3,34.9 306.5,34.9 307.3,37 305.8,42.5 313.1,49.7 326.9,51.5
            327.9,55.1 331.6,58.5 333.1,65.6 332.2,74.3 331.8,78.3 333.8,79.8 333.8,82.6 332.3,82.6 331.8,87.3 331.5,98 332.2,103.2
            333.8,103.2 334.5,101.9 345.4,106.9 344.7,111.4 346,128.1 347.1,137.2 346,139.7 341.5,143.8 339.4,145.9 338,154.8
            330.1,150.9 317.7,144.4 307.1,136.3 302.7,131.6 300.1,126.2 285.1,109.9 277.5,103.2 273.1,100.9 267.4,100.6 261.5,99.7
            256.6,98.5 254.3,100.2 254.5,102.3 260.7,102.8 260.4,107.6 259,108 254.7,106.4 247,100.1 241.7,93.3 230.9,71.6 225,55.2
            221,46.1 217.7,42.8 214.7,42 		"/>
          <g v-for="point in eyskiyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 283.1028 80.0981)" class="st1 st2 st3 js-MapDistrictNameEn">Yeysky</text>
          <text transform="matrix(1 0 0 1 281.8028 87.0981)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 280.4206 80.0981)" class="st1 st2 st3 js-MapDistrictName">Ейский</text>
          <text transform="matrix(1 0 0 1 282.1206 87.0981)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="yeysk-en" transform="matrix(1 0 0 1 295.683 55.123)"
                class="st5 st2 st6 js-MapCitiNameEn">Yeysk</text>
          <text id="yeysk_2_" transform="matrix(1 0 0 1 295.683 55.123)" class="st5 st2 st6 js-MapCitiName">Ейск</text>
          <circle id="Eisk" class="st7 js-MapDistrictCitiMarker" cx="301.4" cy="48.2" r="2.5"/>
        </g>
        <g data-district="pavlovskiy_rayon" class="js-MapDistrict">
          <polygon id="Павловский_1_" class="st0 js-MapDistrictPolygon" points="486.7,203.9 487.2,195.9 489.3,194 489.3,188.1 486.5,183.6 486,173.5
            490.5,167.5 495.7,158.2 501.4,151.8 509.2,144.2 515.3,138.4 515.6,135.3 518.1,133.3 532.9,135 537.8,137 548.8,138.4
            552.2,141.2 561.1,140.6 561.1,138.4 568.8,141.7 577.1,143.4 587,146.8 592.8,146.8 595.3,144.6 595.2,140.6 594.4,136.3
            597.9,133.5 600.8,133.5 612.1,133 619.4,134.1 618.8,136 613.8,141.2 613.5,147.5 615.5,151.2 616.2,155.5 617.5,158.8
            618.7,160.5 618.5,163.8 615.8,164.3 613.2,166.6 600.7,167.2 591.8,169.3 583.8,171 577.6,171.4 573.5,174.1 569,175
            568.9,180.2 570.3,181.7 571.8,187.8 569.4,189.9 565.6,191.1 561.1,193.5 557,193.7 555.1,192.5 552.7,193.6 537.5,194
            534.7,196.9 531.7,205.2 528.5,205.6 526.7,203.6 497.9,201.4 496.5,204.6 		"/>
          <g v-for="point in pavlovskiyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 522.8579 164.1978)" class="st1 st2 st3 js-MapDistrictNameEn">Pavlovsky</text>
          <text transform="matrix(1 0 0 1 529.7579 171.1978)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 519.1108 164.1978)" class="st1 st2 st3 js-MapDistrictName">Павловский</text>
          <text transform="matrix(1 0 0 1 530.2108 171.1978)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="pavlovskaya-en" transform="matrix(1 0 0 1 530.4944 152.6999)" class="st5 st2 st6 js-MapCitiNameEn">Pavlovskaya</text>
          <text id="pavlovskaya_2_" transform="matrix(1 0 0 1 530.4944 152.6999)" class="st5 st2 st6 js-MapCitiName">Павловская</text>
          <circle id="Pavlovskaya" class="st7 js-MapDistrictCitiMarker" cx="526.1" cy="151.2" r="2.5"/>
        </g>
        <g data-district="shcherbinovskiy_rayon" class="js-MapDistrict">
          <path id="Щербиновский" class="st0 js-MapDistrictPolygon" d="M324,37.3l-0.8-3l1.5-2.1l2.7-0.6l4.3,1.4l6.6,0.2l4.3,3.4h3.1l6.1,6.6v4.5
            l-1.4,2.1l-2,1.4l-2.5-0.5c0,0-2.2-0.6-2.5-0.6s-2.9,0.4-2.9,0.4l-3,3.4l-4.3-0.9l-6.3-1.3l1.1,3.5l3.7,3.4l1.5,7.1l-1.4,12.7
            l2,1.5v2.9h-1.5l-0.5,4.6L331.5,98l0.7,5.2h1.6l0.7-1.4l10.9,5l3.2-3.7l2.3,0.1l4.3,1.3l24.7,0.4l-1.3-8.8l-2.5-1.3l-3.5-3.3
            l0.5-9.8l2.9-1.4l9.6-0.9l3.2-0.6l1.6-1.6v-6.9l3-1.6l2-2.4l3.4-2.2v-1.6h-2.7l-1.9,3l-2.9-1.1V38.9l2.6-2.2l2.3-0.1V19.2h6.6V16
            h-9.1v2.9h-29.2V9h-8.7l-3.3,0.6l-2.3,1.5h-4.8l-5.3-2.7h-4.4l-2.4,2.5l-2.4,0.5l-4.4,4l-4.3,10.3l-1.9,8.5l0.7,4.1L324,37.3z"/>
          <g v-for="point in shcherbinovskiyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 332.3862 66.6954)"
                class="st1 st2 st3 js-MapDistrictNameEn">Shcherbinovsky</text>
          <text transform="matrix(1 0 0 1 354.2862 73.6954)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 335.3291 66.6954)" class="st1 st2 st3 js-MapDistrictName">Щербиновский</text>
          <text transform="matrix(1 0 0 1 354.6291 73.6954)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="staroshcherbinovskaya-en" transform="matrix(1 0 0 1 338.0002 68.1461)"
                class="st5 st2 st6 js-MapCitiNameEn">Staroshcherbinovskaya</text>
          <text id="staroshcherbinovskaya_2_" transform="matrix(1 0 0 1 338.0002 68.1461)"
                class="st5 st2 st6 js-MapCitiName">Старощербиновская</text>
          <circle id="Starosherbinovskaya" class="st7 js-MapDistrictCitiMarker" cx="357.1" cy="61.3" r="2.5"/>
        </g>
        <g data-district="starominskiy_rayon" class="js-MapDistrict">
          <path id="Староминский" class="st0 js-MapDistrictPolygon" d="M442.7,47.3l4.1,1.8l5.6,1.1l2.9,2.2l1,8.7l1.9,1.8l1.1,11.6l7.1,4.4v16.6
            l2.5,2.7l-1.6,1.4l-6.4,0.8l-9.3,1l-4,4.2l-4.8,1.4l-5.7,3l-1.5,5.2v3.8l-2.3,1.2l-11.2,1.5v-2.5l1.6-1.5l-2.9-4.2l-0.8-3.1
            l-3.1-0.1l-2.9-4.1c0,0-3.7-1.4-4.1-1.4s-7-0.5-7-0.5l-4.9-1.4l-7.9,0.5l-3.4,0.7l-2,1.4l-4.8-0.5l-1.3-8.8l-2.5-1.3l-3.5-3.3
            l0.5-9.8l2.9-1.4l9.6-0.9l3.2-0.6l1.6-1.6v-6.9l3-1.6l2-2.4l3.4-2.2v-1.6l0.6-3.4l5.2-2.3l1.2,0.9L407,59l3.3,0.4l2.5-1.8l1.4-1.8
            l1.9-0.1l1.1,2.7l3.6,2.9l0.6,2.2l4.9,0.4l3.5-1.2l2.3-2.9l1.5-0.1l1.5,3.3l2.2-1.9l1.9,0.7l2.9-5.2l0.5-2L442.7,47.3z"/>
          <g v-for="point in starominskiyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 403.57 83.1056)" class="st1 st2 st3 js-MapDistrictNameEn">Starominsky</text>
          <text transform="matrix(1 0 0 1 413.77 90.1056)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 399.8318 83.1056)" class="st1 st2 st3 js-MapDistrictName">Староминский</text>
          <text transform="matrix(1 0 0 1 414.1318 90.1056)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="starominskaya-en" transform="matrix(1 0 0 1 420.75 79.5151)" class="st5 st2 st6 js-MapCitiNameEn">Starominskaya</text>
          <text id="starominskaya_2_" transform="matrix(1 0 0 1 420.75 79.5151)" class="st5 st2 st6 js-MapCitiName">Староминиская</text>
          <circle id="Starominskaya" class="st7 js-MapDistrictCitiMarker" cx="416.5" cy="78.3" r="2.5"/>
        </g>
        <g data-district="kushchevskiy_rayon" class="js-MapDistrict">
          <polygon id="Кущевский" class="st0 js-MapDistrictPolygon" points="579.7,58.9 571.3,58.9 571.3,69.5 560.8,70.2 558.7,80 557.9,81.7
            555.4,82.9 554.2,87.8 544.7,87.8 542.6,86 541,82.8 537.7,82.4 535.8,81.1 536.5,77.8 531.7,75.7 525.3,78.4 525.3,84.5
            523.1,89.7 535,91.6 538.3,94.2 541,96 546.6,97.9 545.9,105.6 543.4,109.1 537.3,109.6 536.1,112.8 529.5,118.9 522.6,122.5
            517.8,123.7 514.1,122.2 511.5,120.3 500.6,119.7 498.4,117.4 496.4,105.4 487.3,103.4 486.7,94.1 481.5,94.5 480.5,96
            480.5,100.4 474.8,99.3 468.8,98.2 466.3,95.5 466.3,78.8 459.2,74.4 458.1,62.8 456.2,61.1 455.3,52.3 452.4,50.1 446.8,49.1
            442.7,47.3 442.7,45.5 451.6,45.5 451.6,26.5 465.5,26.5 466.7,25.2 475.5,25.2 480.1,23.7 480.1,20.7 484.2,20.7 485.4,23.9
            492.5,24.2 495,25.6 495.5,27.5 537,27.5 538.8,30.1 540.4,30.9 572,30.9 573.6,33.8 574.1,39.9 576.2,39.9 577.3,37.5
            581.2,37.5 581.2,42.6 579.8,44.7 		"/>
          <g v-for="point in kushchevskiyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 487.4364 62.2021)" class="st1 st2 st3 js-MapDistrictNameEn">Kushchevsky</text>
          <text transform="matrix(1 0 0 1 497.0364 69.2021)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 487.4364 62.2021)" class="st1 st2 st3 js-MapDistrictName">Кущевский</text>
          <text transform="matrix(1 0 0 1 497.0364 69.2021)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="kushchevskaya-en" transform="matrix(1 0 0 1 489.4868 69.3307)" class="st5 st2 st6 js-MapCitiNameEn">Kushchevskaya</text>
          <text id="kushchevskaya_2_" transform="matrix(1 0 0 1 492.4868 69.3307)" class="st5 st2 st6 js-MapCitiName">Кущевская</text>
          <circle id="Kushevskaya_1_" class="st7 js-MapDistrictCitiMarker" cx="503" cy="74.3" r="2.5"/>
        </g>
        <g data-district="kanevskoy_rayon" class="js-MapDistrict">
          <path id="Каневской" class="st0 js-MapDistrictPolygon" d="M347.1,171.5l5.6,3.5l2.7,4.9l1.8,5.2h6l1.9,5.5l0.6,5.5l1.3,1.8h6.6l3.5,1l1-3.5
            l-4.2-3l-0.3-6.3l-5.7-4.4l-0.4-2.5l-3.3-2.9l1.1-2.5l6.1,0.1l2.5,0.7l9.1-0.7l2.9-0.5l3.4-0.8l2.2,0.4l-0.8,8.1l1.9,3h6.4
            l2.7-1.6l5.7,1.5l1.1,6.4l3.3,3.8l4.6,0.5l3.7,1.2l4.8-0.1l9.6,2.2l2.9,0.8h10.9l4.5,3.3l13.9,0.5l3-1.6l8.7-0.4l5.5-4.6l3.4-0.1
            l2-1.9v-5.9l-2.7-4.5l-0.5-10.1l-19.4-0.1l-5.2,3.7l-7.4,5.7l-7.4-0.8l-4-4.1l-2-0.3v-7.9c0,0-1.6-2.9-1.6-3.3s2.2-8.1,2.2-8.1
            l2.6-3.5l-1.1-10.8l-8.5-3l-4.2-4.2l-6.1-5.9l-1.2-6.4l-0.7-2.9v-2.5l1.6-1.5l-2.9-4.2l-0.8-3.1l-3.1-0.1l-2.9-4.1l-4.1-1.4
            l-7-0.5l-4.9-1.4l-7.9,0.5l-3.4,0.7l-2,1.4l-4.8-0.5l-24.7-0.4l-4.3-1.3l-2.3-0.1l-3.2,3.7l-0.7,4.5l1.2,16.6l1.1,9.1l-1.1,2.5
            l-4.5,4.1l-2,2.2l-1.4,8.9l2.6,1.9l3.1,1.5l2.6,2.7L347.1,171.5z"/>
          <g v-for="point in kanevskoyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 373.8447 143.3415)" class="st1 st2 st3 js-MapDistrictNameEn">Kanevskoy</text>
          <text transform="matrix(1 0 0 1 380.1447 150.3415)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 371.6338 143.3415)" class="st1 st2 st3 js-MapDistrictName">Каневской</text>
          <text transform="matrix(1 0 0 1 380.5338 150.3415)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="kanevskaya-en" transform="matrix(1 0 0 1 387.1985 158.754)" class="st5 st2 st6 js-MapCitiNameEn">Kanevskaya</text>
          <text id="kanevskaya_2_" transform="matrix(1 0 0 1 388.1985 158.754)" class="st5 st2 st6 js-MapCitiName">Каневская</text>
          <circle id="Kanevskaya" class="st7 js-MapDistrictCitiMarker" cx="397.9" cy="164.3" r="2.5"/>
        </g>
        <g data-district="leningradskiy_rayon" class="js-MapDistrict">
          <polygon id="Ленинградский" class="st0 js-MapDistrictPolygon" points="518.1,133.3 517.8,123.7 514.1,122.2 511.5,120.3 500.6,119.7
            498.4,117.4 496.4,105.4 487.3,103.4 486.7,94.1 481.5,94.5 480.5,96 480.5,100.4 468.8,98.2 467.1,99.5 451.5,101.3 447.5,105.6
            442.7,106.9 437,109.9 435.5,115.1 435.5,118.9 433.2,120.2 422,121.7 423.9,130.9 434.3,141 442.7,144 443.8,154.8 441.2,158.4
            439,166.4 440.7,169.7 440.7,177.6 442.7,177.9 446.7,182 454,182.8 461.4,177.1 466.6,173.4 486,173.5 490.5,167.5 495.7,158.2
            501.4,151.8 509.2,144.2 515.3,138.4 515.6,135.3 		"/>
          <g v-for="point in leningradskiyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 450.0838 136.4391)"
                class="st1 st2 st3 js-MapDistrictNameEn">Leningradsky</text>
          <text transform="matrix(1 0 0 1 462.5838 143.4391)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 446.1869 136.4391)"
                class="st1 st2 st3 js-MapDistrictName">Ленинградский</text>
          <text transform="matrix(1 0 0 1 462.9869 143.4391)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="leningradskaya-en" transform="matrix(1 0 0 1 454.083 128.9463)"
                class="st5 st2 st6 js-MapCitiNameEn">Leningradskaya</text>
          <text id="leningradskaya_2_" transform="matrix(1 0 0 1 454.083 128.9463)" class="st5 st2 st6 js-MapCitiName">Ленинградская</text>
          <circle id="Leningradskaya" class="st7 js-MapDistrictCitiMarker" cx="467" cy="122.3" r="2.5"/>
        </g>
        <g data-district="krylovskiy_rayon" class="js-MapDistrict">
          <polygon id="Крыловский" class="st0 js-MapDistrictPolygon" points="619.4,134.1 612.1,133 600.8,133.5 597.9,133.5 594.4,136.3 595.2,140.6
            595.3,144.6 592.8,146.8 587,146.8 577.1,143.4 568.8,141.7 561.1,138.4 561.1,140.6 552.2,141.2 548.8,138.4 537.8,137
            532.9,135 518.1,133.3 517.8,123.7 522.6,122.5 529.5,118.9 536.1,112.8 537.3,109.6 543.4,109.1 545.9,105.6 546.6,97.9 541,96
            538.3,94.2 535,91.6 523.1,89.7 525.3,84.5 525.3,78.4 531.7,75.7 536.5,77.8 535.8,81.1 537.7,82.4 541,82.8 542.6,86
            544.7,87.8 554.2,87.8 555.4,82.9 557.9,81.7 558.7,80 560.8,70.2 571.3,69.5 571.3,77.2 583.1,77.2 595,76.9 595.6,82.7
            598.6,84.5 598.8,91.5 600.2,91.5 600.2,93.3 599.4,94.6 605.2,94.6 606.7,95.8 606.7,106.7 604.9,109.4 601,110.1 600.3,112.2
            601,114.3 604.4,115.1 604.1,121.2 612.1,121.7 612.9,123.7 612.7,126.8 616.3,127.3 621,127.9 621,130.5 		"/>
          <g v-for="point in krylovskiyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 557.6199 107.7979)" class="st1 st2 st3 js-MapDistrictNameEn">Krylovsky</text>
          <text transform="matrix(1 0 0 1 565.0199 114.7979)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 552.9547 107.7979)" class="st1 st2 st3 js-MapDistrictName">Крыловский</text>
          <text transform="matrix(1 0 0 1 565.4547 114.7979)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="krylovskaya-en" transform="matrix(1 0 0 1 563.4329 120.8614)" class="st5 st2 st6 js-MapCitiNameEn">Krylovskaya</text>
          <text id="krylovskaya_2_" transform="matrix(1 0 0 1 563.4329 120.8614)" class="st5 st2 st6 js-MapCitiName">Крыловская</text>
          <circle id="Krilovskaya" class="st7 js-MapDistrictCitiMarker" cx="559.2" cy="120" r="2.5"/>
        </g>
        <path id="sz-borderline" class="st8 js-MapZoneBorder" d="M487.2,195.9l-3.4,0.1l-5.5,4.6l-8.7,0.4l-3,1.6l-13.9-0.5l-4.5-3.3h-10.9l-2.9-0.8
          l-9.6-2.2l-4.8,0.1l-3.7-1.2l-4.6-0.5l-3.3-3.8l-1.1-6.4l-5.7-1.5l-2.7,1.6h-6.4l-1.9-3l0.8-8.1l-2.2-0.4l-3.4,0.8l-2.9,0.5
          l-9.1,0.7l-2.5-0.7l-6.1-0.1l-1.1,2.5l3.3,2.9l0.4,2.5l5.7,4.4l0.3,6.3l4.2,3l-1,3.5l-3.5-1H367l-1.3-1.8l-0.6-5.5l-1.9-5.5h-6
          l-1.8-5.2l-2.7-4.9l-5.6-3.5l-0.7-10.5l-2.6-2.7l-3.1-1.5l-2.6-1.9l-7.9-4l-12.4-6.4l-10.6-8.2l-4.4-4.6l-2.6-5.5l-15-16.2
          l-7.6-6.7l-4.4-2.3l-5.7-0.3l-5.9-1l-4.9-1.2l-2.3,1.8l0.3,2l6.1,0.5l-0.3,4.8L259,108l-4.4-1.6l-7.6-6.3l-5.3-6.8l-10.8-21.7
          L225,55.2l-4.1-9.1l-3.3-3.3l-3-0.8v-2.5h4.9l8.6,8.1l9.8,5l11.7,1.6h8.2l5.5-0.5l9.6-4.4l7.5-4.9l6.6-1.1l9.1-0.8l6.6-4.5l2.6-3.1
          h1.2l0.8,2.2l-1.5,5.5l7.2,7.2l13.8,1.8l6.3,1.3l4.3,0.9l3-3.4c0,0,2.6-0.4,2.9-0.4s2.5,0.6,2.5,0.6l2.5,0.5l2-1.4l1.4-2.1v-4.5
          l-6.1-6.6h-3.1l-4.3-3.4l-6.6-0.2l-4.3-1.4l-2.7,0.6l-1.5,2.1l0.8,3l-2.8,0.9l-0.7-4.1l1.9-8.5l4.3-10.3l4.4-4l2.4-0.5l2.4-2.5h4.4
          l5.3,2.7h4.8l2.3-1.5l3.3-0.6h8.7v9.9h29.2V16h9.1v3.2h-6.6v17.5l-2.3,0.1l-2.6,2.2v25.4l2.9,1.1l1.9-3h2.7l0.6-3.4l5.2-2.3
          l1.2,0.9L407,59l3.3,0.4l2.5-1.8l1.4-1.8l1.9-0.1l1.1,2.7l3.6,2.9l0.6,2.2l4.9,0.4l3.5-1.2l2.3-2.9l1.5-0.1l1.5,3.3l2.2-1.9
          l1.9,0.7l2.9-5.2l0.5-2l0.1-7.2v-1.8h8.9v-19h13.9l1.2-1.4h8.7l4.6-1.5v-3h4.1l1.2,3.3l7.1,0.3l2.5,1.4l0.5,1.9H537l1.8,2.6
          l1.6,0.8H572l1.6,2.9l0.5,6.1h2.1l1.1-2.4h3.9v5.1l-1.4,2.1l-0.1,14.1h-8.5v10.6v7.7H583l11.9-0.3l0.6,5.7l3,1.8l0.2,7h1.4v1.8
          l-0.7,1.3h5.7l1.5,1.2v10.9l-1.8,2.7l-3.9,0.7l-0.6,2l0.6,2.2l3.4,0.7l-0.3,6.2l8,0.4l0.8,2l-0.2,3.1l3.5,0.5l4.7,0.6v2.6l-1.6,3.5
          l-0.5,1.9l-5,5.2l-0.3,6.3l2,3.7l0.6,4.3l1.3,3.4l1.3,1.6l-0.3,3.4l-2.6,0.5l-2.6,2.3l-12.5,0.6l-8.9,2.1l-8,1.7l-6.2,0.4l-4.1,2.7
          L569,175l-0.1,5.2l1.5,1.5l1.5,6.1l-2.4,2.2l-3.8,1.2l-4.5,2.4l-4.1,0.2l-1.9-1.2l-2.5,1.1l-15.2,0.5l-2.8,2.8l-3,8.4l-3.2,0.4
          l-1.8-2l-28.8-2.2l-1.4,3.2l-9.8-0.7L487.2,195.9z"/>
      </g>
      <g data-zone="krasnodarskaya" @click="selectZone(1)" @mouseenter="hoverZone" class="js-MapZone">
        <g data-district="dinskoy_rayon" class="js-MapDistrict">
          <polygon id="Динской" class="st9 js-MapDistrictPolygon" points="480,354.8 478.5,356.4 471.7,357.8 468.8,357.8 462.1,355.2 458.8,350.6
            452.5,350.2 450.2,353.5 446.6,354.5 444.9,354.8 436,353.4 432.8,347.2 431.4,344.2 424,343.7 420.1,341.1 415.3,340.1
            404.8,340.1 404,343.1 407.2,343.5 409.7,344.1 410.6,345.8 409.2,347.7 407.1,348.8 405.6,348.4 405.7,344.7 401.6,342.8
            395.3,342.9 390.1,341.2 389.2,338.4 389.8,335.9 382.9,333.5 379.9,331.9 376.9,332.4 376.3,335.4 374.8,337.6 372.5,338.4
            369.6,339.2 364.7,343.4 362.2,345.3 357.7,341.9 358.7,335 352.9,332.1 349.9,325 353.5,322.5 354.4,318.8 357.9,317.5
            360.8,319.7 362.2,322.2 364.4,322.2 367.9,320.6 372.3,316.4 372.6,313.3 374.4,313.3 377.7,310.6 384.4,309.1 385.2,306.8
            384.1,304 385,301.8 388.6,301.8 391.9,302.8 393.4,306.4 397.1,307.1 401.4,307.6 403.8,309.7 405.3,309.7 407,306.2
            411.2,305.4 430.3,305.5 437.5,305.9 443,307.1 445.7,310.3 461.3,311 466.9,312 466.9,314.8 462.9,324.5 467.6,326.2
            468.1,329.2 473.9,330.7 481.5,331.2 482.6,346.7 479.7,352 		"/>
          <g v-for="point in dinskoyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 407.6881 324.3562)" class="st1 st2 st3 js-MapDistrictNameEn">Dinskoy</text>
          <text transform="matrix(1 0 0 1 412.8881 331.3562)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 406.3619 324.3562)" class="st1 st2 st3 js-MapDistrictName">Динской</text>
          <text transform="matrix(1 0 0 1 413.2619 331.3562)" class="st1 st4 st3 js-MapDistrictName">район</text>


          <text id="dinskaya-en" transform="matrix(1 0 0 1 428.3354 331.2617)" class="st5 st2 st6 js-MapCitiNameEn">Dinskaya</text>
          <text id="dinskaya_1_" transform="matrix(1 0 0 1 428.3354 331.2617)" class="st5 st2 st6 js-MapCitiName">Динская</text>
          <circle id="Dinskaya" class="st7 js-MapDistrictCitiMarker" cx="435.9" cy="336.5" r="2.5"/>
        </g>
        <g data-district="gorod_krasnodar" class="js-MapDistrict">
          <polygon id="Краснодар" class="st9 js-MapDistrictPolygon" points="362.9,366.8 368.4,368.1 372.1,371.2 373.5,377.7 381.2,378.6 383.4,370.8
            390.1,370.3 396.1,374.6 402.3,376.5 404.5,373.7 404.5,371 409.2,370.6 416.3,372.7 424.8,371.7 428.5,369.9 434.9,369.7
            450.3,364.8 463.7,363.8 468.9,361.2 471.7,357.8 468.8,357.8 462.1,355.2 458.8,350.6 452.5,350.2 450.2,353.5 444.9,354.8
            436,353.4 431.4,344.2 424,343.7 420.1,341.1 415.3,340.1 404.8,340.1 404,343.1 407.2,343.5 409.7,344.1 410.6,345.8
            409.2,347.7 407.1,348.8 405.6,348.4 405.7,344.7 401.6,342.8 395.3,342.9 390.1,341.2 389.2,338.4 389.8,335.9 382.9,333.5
            379.9,331.9 376.9,332.4 376.3,335.4 374.8,337.6 372.5,338.4 369.6,339.2 364.7,343.4 362.2,345.3 369.2,348.8 375.2,351.6
            375.7,355 375.7,356.8 373.7,358 370.1,360.9 365.8,358.2 364.4,359.3 365.8,363.5 		"/>
          <g v-for="point in gorodKrasnodar" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 391.2109 359.0562)" class="st10 st2 st3 js-MapDistrictNameEn">Krasnodar</text>
          <text transform="matrix(1 0 0 1 389.8008 359.0562)" class="st10 st2 st3 js-MapDistrictName">Краснодар</text>
          <text id="krasnodar-en" transform="matrix(1 0 0 1 390.6303 360.495)" class="st5 st2 st6 js-MapCitiNameEn">Krasnodar</text>
          <text id="krasnodar_2_" transform="matrix(1 0 0 1 390.6303 360.495)" class="st5 st2 st6 js-MapCitiName">Краснодар</text>
          <circle id="Krasnodar" class="st7 js-MapDistrictCitiMarker" cx="398.7" cy="365.4" r="2.5"/>
        </g>
        <g data-district="severskiy_rayon" class="js-MapDistrict">
          <polygon id="Северский" class="st9 js-MapDistrictPolygon" points="411.1,411.1 409.1,417.8 407.6,423.1 407.6,425.7 413.4,433 413.4,438.4
            413.4,445.6 417,465.8 416.3,469.5 412.3,473.5 405.9,477.2 404.1,479.9 403.6,482.9 399,483.9 397.7,478.8 387.7,477.7
            379.9,476.6 372.7,477.2 365.6,478 359.1,481.7 355.4,483 351,479.1 345.7,476.6 342.4,470.5 335.2,469.8 333.1,464.9
            330.8,464.1 330.8,459.4 329,456.6 326.9,456.4 324,457.4 317.4,451.8 317.3,447.4 314.9,441.1 314,436.5 315.8,433.5
            316.6,428.3 314.9,425.6 316.6,421.1 319.2,417.1 324.4,416.3 324.1,406.7 326.2,405.5 330.1,405.5 330.1,394.5 322.2,392
            323.7,385.6 324.5,377.8 325.7,375.6 332.7,377 333.4,374.5 336,372.6 340.3,368.4 340.3,366.8 338.8,366.3 339.7,364.6
            343.2,364.9 353.5,365 358.7,364.6 360.3,366.8 360.2,368.9 357.5,371.9 357.2,376.4 358.9,384.5 361.5,388.3 378.5,389.4
            378.9,394.2 381.1,396.9 387.3,399.9 389,402.2 393.8,404.2 399.3,404.6 405.3,409.1 408.7,411.3 		"/>
          <g v-for="point in severskiyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 346.3235 425.656)" class="st1 st2 st3 js-MapDistrictNameEn">Seversky</text>
          <text transform="matrix(1 0 0 1 352.9235 432.656)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 343.1672 425.656)" class="st1 st2 st3 js-MapDistrictName">Северский</text>
          <text transform="matrix(1 0 0 1 353.2672 432.656)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="severskaya-en" transform="matrix(1 0 0 1 341.6995 430.4924)" class="st5 st2 st6 js-MapCitiNameEn">Severskaya</text>
          <text id="severskaya_1_" transform="matrix(1 0 0 1 341.6995 430.4924)" class="st5 st2 st6 js-MapCitiName">Северская</text>
          <circle id="Severskaya_1_" class="st7 js-MapDistrictCitiMarker" cx="351.9" cy="423.4" r="2.5"/>
        </g>
        <g data-district="gorod_goryachiy_klyuch" class="js-MapDistrict">
          <polygon id="Горячий_ключ" class="st9 js-MapDistrictPolygon" points="417.7,415.1 422,414 429.6,409.8 434.7,411.4 444.4,412.4 450.5,415.1
            468.4,415.1 476.4,415.1 479.2,417.4 484.2,418.1 485.6,423.1 487.8,424.2 490.8,425.4 492.3,426.8 492.3,429.9 490.9,431.7
            492.3,435 494.2,439.6 494.2,441.1 492.3,443.1 490.5,449.3 489.8,453.3 483.8,453.3 479.6,454.8 478.2,458.1 473.6,462.3
            468,465.7 465.4,466.9 464.8,469 468.7,472.5 470.6,475 470.7,479.2 469.9,481.9 464.1,486.6 462.9,488.5 451,491.4 444.6,490.5
            430,486.3 422,486.2 420.3,488.5 419.9,496.5 417,497.5 414.1,498.3 410.9,500.5 405.6,500.5 405.6,499.1 408.5,498.6 407.6,495
            405.6,494.2 399.7,495.6 392.5,494.4 392.5,491.5 398.6,489.2 400.1,487.7 399,483.9 403.6,482.9 404.1,479.9 405.9,477.2
            412.3,473.5 416.3,469.5 417,465.8 413.4,445.6 413.4,433 407.6,425.7 407.6,423.1 411.1,411.1 414.9,413.2 		"/>
          <g v-for="point in gorodGoryachiyKlyuch" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 422.0109 446.4561)"
                class="st10 st2 st3 js-MapDistrictNameEn">Goryachiy Klyuch</text>
          <text transform="matrix(1 0 0 1 425.3165 446.4561)"
                class="st10 st2 st3 js-MapDistrictName">Горячий ключ</text>
          <text id="goryachiy-klyuch-en" transform="matrix(1 0 0 1 429.9481 453.6488)"
                class="st5 st2 st6 js-MapCitiNameEn">Goryachiy Klyuch</text>
          <text id="goryachiy-klyuch_1_" transform="matrix(1 0 0 1 429.9481 453.6488)"
                class="st5 st2 st6 js-MapCitiName">Горячий ключ</text>
          <circle id="Goryachiy_Klyuch_7_" class="st7 js-MapDistrictCitiMarker" cx="424.9" cy="452.5" r="2.5"/>
        </g>
        <g data-district="takhtamukayskiy_rayon" class="js-MapDistrict js-MapDistrict--adigeya">
          <polygon id="Тахтамукайский_1_" class="st9 js-MapDistrictPolygon" points="411.1,411.1 415.4,404.6 418.3,404.6 419.7,402.7 417.8,402
            417.5,401 416.5,400 416.3,398.6 417.9,398.6 419.4,398.3 418.5,391.1 417,390.1 417.4,388.1 416.2,384.1 415.8,379.3
            416.3,372.7 409.2,370.6 404.5,371 404.5,373.7 402.3,376.5 396.1,374.6 390.1,370.3 383.4,370.8 381.2,378.6 373.5,377.7
            372.1,371.2 368.4,368.1 362.9,366.8 360.3,366.8 360.2,368.9 357.5,371.9 357.2,376.4 358.9,384.5 361.5,388.3 378.5,389.4
            378.9,394.2 381.1,396.9 387.3,399.9 389,402.2 393.8,404.2 399.3,404.6 405.3,409.1 408.7,411.3 		"/>
          <g v-for="point in takhtamukayskiyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 359.5428 385.9323)"
                class="st1 st2 st3 js-MapDistrictNameEn">Takhtamukaysky</text>
          <text transform="matrix(1 0 0 1 377.8428 392.9323)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 359.5487 385.9323)"
                class="st1 st2 st3 js-MapDistrictName">Тахтамукайский</text>
          <text transform="matrix(1 0 0 1 378.2487 392.9323)" class="st1 st4 st3 js-MapDistrictName">район</text>
        </g>
        <g data-district="teuchezhskiy_rayon" class="js-MapDistrict js-MapDistrict--adigeya">
          <polygon id="Теучежский" class="st9 js-MapDistrictPolygon" points="416.3,372.7 415.8,379.3 416.2,384.1 417.4,388.1 416.6,390.8
            418.5,391.5 419.4,398.3 417.9,398.6 416.3,398.6 416.5,400 417.5,401 417.8,402 419.7,402.7 418.3,404.6 415.4,404.6
            411.1,411.1 414.9,413.2 417.7,415.1 422,414 429.6,409.8 434.7,411.4 436.3,411.6 432.6,406.9 428.8,400 438.4,399.5
            441.8,398.3 441.8,403.8 440,409.5 439.9,411.9 444.4,412.4 450.5,415.1 476.4,415.1 479.2,417.4 484.2,418.1 485.6,423.1
            487.8,424.2 488.7,420.5 494.9,420.1 496.3,412.8 496.3,399.3 494.2,395.5 488.6,393.6 483,393.2 480.6,384.9 464.5,380.8
            456.2,380.8 451,378.6 443.3,367 434.9,369.7 428.5,369.9 424.8,371.7 		"/>
          <g v-for="point in teuchezhskiyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 444.1448 400.9559)"
                class="st1 st2 st3 js-MapDistrictNameEn">Teuchezhsky</text>
          <text transform="matrix(1 0 0 1 456.2448 407.9559)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 444.8074 400.9559)" class="st1 st2 st3 js-MapDistrictName">Теучежский</text>
          <text transform="matrix(1 0 0 1 456.6074 407.9559)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="adygeysk-en" transform="matrix(1 0 0 1 440.7328 407.9411)" class="st5 st2 st6 js-MapCitiNameEn">Adygeysk</text>
          <text id="adygeysk_1_" transform="matrix(1 0 0 1 440.7328 407.9411)" class="st5 st2 st6 js-MapCitiName">Адыгейск</text>
          <circle id="Adigeisk" class="st7 js-MapDistrictCitiMarker" cx="435.9" cy="404.6" r="2.5"/>
        </g>
        <path id="ka-borderline" class="st8 js-MapZoneBorder" d="M443.3,367L443.3,367l7-2.2l13.4-1l5.2-2.6l2.8-3.4l6.8-1.4l1.5-1.6l-0.3-2.9l2.9-5.3
      l-1.2-15.5l-7.6-0.5l-5.8-1.5l-0.5-3.1l-4.6-1.7l4-9.7V312l-5.6-1l-15.6-0.7L443,307l-5.5-1.2l-7.2-0.4l-19.1-0.1l-4.2,0.8
      l-1.6,3.5h-1.6l-2.4-2.1l-4.2-0.5l-3.8-0.8l-1.5-3.6l-3.3-0.9H385l-0.9,2.2l1.1,2.7l-0.7,2.4l-6.7,1.5l-3.3,2.7h-1.8l-0.4,3.1
      l-4.4,4.2l-3.5,1.6h-2.2l-1.5-2.5l-2.9-2.2l-3.5,1.3l-0.9,3.7l-3.6,2.5l3,7.1l5.7,2.9l-1,7l4.5,3.4l7,3.4l6,2.9l0.5,3.4v1.8l-2,1.2
      l-3.5,2.9l-4.4-2.7l-1.4,1.1l1.4,4.2l-2.9,3.3h-2.6l-1.6-2.2l-5.2,0.4l-10.2-0.1l-3.5-0.3l-0.9,1.7l1.5,0.6v1.6l-4.3,4.2l-2.6,1.9
      l-0.7,2.5l-7-1.4l-1.2,2.2l-0.8,7.8l-1.5,6.4l7.9,2.5v11.1h-4l-2,1.2l0.3,9.6l-5.2,0.8l-2.6,4l-1.7,4.5l1.7,2.7l-0.8,5.2l-1.8,3
      l0.9,4.6l2.4,6.3l0.1,4.4l6.6,5.6l2.9-1l2.2,0.1l1.8,2.9v4.6l2.3,0.8l2,4.9l7.2,0.7l3.3,6.1l5.3,2.5l4.4,4l3.7-1.4l6.6-3.7l7.1-0.8
      l7.2-0.5l7.8,1.1l10,1.1l1.4,5l1.1,3.8l-1.5,1.5l-6.1,2.3v2.9l7.2,1.2l5.9-1.4l2,0.8l0.8,3.5l-2.9,0.5v1.4h5.3l3.1-2.2l2.9-0.8l3-1
      l0.4-8.1l1.6-2.3l8.1,0.1l14.6,4.2l6.4,0.8l11.9-2.9l1.2-1.9l5.7-4.6l0.8-2.7l-0.1-4.2l-1.9-2.5l-3.8-3.5l0.5-2l2.6-1.2l5.6-3.4
      l4.6-4.2l1.4-3.3l4.2-1.5h6l0.7-4l1.8-6.3l1.9-1.9v-1.5l-1.9-4.6l-1.4-3.3l1.4-1.8v-3.1l-1.5-1.4l-3-1.2l0.9-3.7l6.3-0.4l1.4-7.2
      v-13.6l-2.1-3.8l-5.6-1.9l-5.6-0.5l-2.4-8.3"/>
        <g id="ad-borderline" class="st11">
      <polyline class="st12" points="443.3,367 434.9,369.7 428.5,369.9 424.8,371.7 416.3,372.7 409.2,370.6 404.5,371 404.5,373.7
        402.3,376.5 396.1,374.6 390.1,370.3 383.4,370.8 381.2,378.6 373.5,377.7 372.1,371.2 368.4,368.1 362.9,366.8 360.3,366.8
        360.2,368.9 357.5,371.9 357.2,376.4 358.9,384.5 361.5,388.3 378.5,389.4 378.9,394.2 381.1,396.9 387.3,399.9 389,402.2
        393.8,404.2 399.3,404.6 408.7,411.3 411.1,411.1 417.7,415.1 422,414 429.6,409.8 434.7,411.4 439.9,411.9 444.4,412.4
        450.5,415.1 476.4,415.1 479.2,417.4 484.2,418.1 485.6,423.1 487.8,424.2 		"/>
    </g>
  </g>
      <g data-zone="chernomorskaya" @click="selectZone(2)" @mouseenter="hoverZone" class="js-MapZone">
        <g data-district="temryukskiy_rayon" class="js-MapDistrict">
          <polygon id="Темрюкский_1_" class="st13 js-MapDistrictPolygon" points="38.9,303.4 44.9,298.9 50.9,293.5 55.3,288.2 60.6,284.9 64.7,282
            72.1,280.5 75.7,281.1 79,283 82.4,284.5 85.7,284.6 98.1,292 105.1,296.6 116.4,300.8 125.8,304.3 137.8,305.6 153.7,306.4
            158.7,304.9 162,304.5 163.9,305.6 175.2,300.2 185.2,291.7 190.5,294 193.2,296.9 196.5,302.8 201.7,308.2 207.4,313.1
            205.8,319 203.6,322.8 200.9,325.3 199,326.2 199.5,333.9 199.5,338.4 197.2,342.6 196.3,346.3 190.8,345.9 180.1,344.4
            175.9,340.8 172.3,339.3 167.3,337.1 163.4,336.7 156.3,336.7 152,338.5 149.4,341.5 146.8,344.5 139.8,346.8 136.3,346.8
            133.7,348.4 127.6,353.8 125.1,355 119.1,353.9 117.4,351.9 119.1,345.2 122.1,344.7 124.6,343.7 124.7,341.8 120.4,339.3
            113.1,338.1 109.7,337.4 106.5,336.3 102.5,338.4 99.5,338.5 96.5,341 95,340 95.8,335.4 93.4,333.3 89.9,334 85.6,332.8
            82.2,332.8 76.7,336.3 69.7,337.3 70,339.3 77.8,340 82.3,339.3 87.9,338.6 91.3,339.3 93.6,342.1 93.1,343.6 86.8,343.6
            84.1,345.7 79.4,345.7 79.2,347.5 88.2,351.2 86.9,353.3 74.9,348.4 68.2,346.4 59.9,346 54,347.2 45.6,344.2 36.6,343.7
            28.4,339.3 25.8,334.1 26.9,331.8 22.8,327.9 21,323.9 22.7,322 30.7,326.5 39.9,325 42.7,323.2 49.1,325.4 55.1,325.5
            56.1,321.6 59.4,320.2 63.5,321.6 69.9,321.5 76.7,320.8 83,317.8 89,316.1 94.4,316.4 95.4,314.6 92.7,311.8 96.1,308.5
            95.8,305.9 89.1,305.3 83,305.1 74.7,306 71,308.9 69.6,310.8 64.7,311.4 61.4,306.2 61.4,301.3 60.1,296.9 62.8,296.2 67,299.3
            70.7,297 74.2,296.2 72.5,294.7 69.5,294.2 66.6,292.5 64,292.4 61,289.7 57.6,292.8 56,297.4 53,300.8 49.3,301 47.2,304.1
            44.4,304.1 42.4,305.3 		"/>
          <g v-for="point in temryukskiyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 120.5458 322.0138)" class="st1 st2 st3 js-MapDistrictNameEn">Temryuksky</text>
          <text transform="matrix(1 0 0 1 128.3458 329.0138)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 119.1835 322.0138)" class="st1 st2 st3 js-MapDistrictName">Темрюкский</text>
          <text transform="matrix(1 0 0 1 128.6835 329.0138)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="temryuk-en" transform="matrix(1 0 0 1 128.8546 319.2231)" class="st5 st2 st6 js-MapCitiNameEn">Temryuk</text>
          <text id="temryuk_3_" transform="matrix(1 0 0 1 129.8546 319.2231)"
                class="st5 st2 st6 js-MapCitiName">Темрюк</text>
          <circle id="Temryuk_1_" class="st7 js-MapDistrictCitiMarker" cx="148.6" cy="317.8" r="2.5"/>
        </g>
        <g data-district="gorod-kurort_anapa" class="js-MapDistrict">
          <polygon id="Анапа_1_" class="st13 js-MapDistrictPolygon" points="154.6,427.8 158.7,426.8 160.9,422.6 164.7,424.1 167.9,422.6 167.9,418.9
            166.1,417.3 165.5,414.4 161.9,411.1 161.2,405.5 164.2,402.2 164,399.7 165.3,398.6 166.4,396.5 171.6,396.5 173.2,393
            172.3,388.2 175.5,383.7 185.2,383.7 186.2,378.9 185.2,377.4 185.2,375.4 191.9,377.4 188.5,367.7 183.4,366.8 181.2,364.3
            181.2,361 180.3,351.5 176.1,348.4 173.6,343.2 172.3,339.3 167.3,337.1 163.4,336.7 156.3,336.7 152,338.5 146.8,344.5
            139.8,346.8 136.3,346.8 133.7,348.4 127.6,353.8 125.1,355 119.1,353.9 120.9,356.4 119.1,358.2 115.5,359.1 107.1,356.8
            97.9,355.7 88.2,351.2 86.9,353.3 97.2,358 109.5,363.5 127.3,372.8 132.1,378.5 140.3,385.9 142.5,390.2 138.1,393.4 138.3,395
            141,396.6 141.4,399.5 141.9,404.6 146.8,411.3 148.3,412.2 149,418.3 		"/>
          <g v-for="point in gorodKurortAnapa" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text id="Anapa_2_" transform="matrix(1 0 0 1 147.1467 370.6468)" class="st10 st2 st3 js-MapDistrictNameEn">Anapa</text>
          <text id="Анапа_3_" transform="matrix(1 0 0 1 147.1526 370.6468)" class="st10 st2 st3 js-MapDistrictName">Анапа</text>
          <text id="anapa-en" transform="matrix(1 0 0 1 144.7216 381.7231)"
                class="st5 st2 st6 js-MapCitiNameEn">Anapa</text>
          <text id="anapa_3_" transform="matrix(1 0 0 1 144.7216 381.7231)"
                class="st5 st2 st6 js-MapCitiName">Анапа</text>
          <circle id="Anapa_1_" class="st7 js-MapDistrictCitiMarker" cx="150.2" cy="387.2" r="2.5"/>
        </g>
        <g data-district="gorod-geroy_novorossiysk" class="js-MapDistrict">
          <polygon id="Новоросссийск_1_" class="st13 js-MapDistrictPolygon" points="213.1,442.7 208.1,442 200.7,443.7 191.9,441.4 179.7,439.1
            166,434.2 154.6,427.8 158.7,426.8 160.9,422.6 164.7,424.1 167.9,422.6 167.9,418.9 166.1,417.3 165.5,414.4 161.9,411.1
            161.2,405.5 164.2,402.2 164,399.7 165.3,398.6 166.4,396.5 171.6,396.5 173.2,393 172.3,388.2 175.5,383.7 185.2,383.7
            186.2,378.9 185.2,377.4 185.2,375.4 191.9,377.4 201.6,388.3 209.9,391.6 211,395.4 215.8,397.2 215.8,400.3 214.5,402.3
            220.9,406.1 226.3,409.9 226.3,417 226.3,421.3 225.9,423.8 225.9,428.2 228,431.3 228,439 223.4,436 219.9,430.1 213.9,429.8
            217.2,438.8 217,440.1 214.6,440.2 		"/>
          <g v-for="point in gorodGeroyNovorossiysk" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text id="Novorossiysk_1_" transform="matrix(1 0 0 1 172.1598 417.0468)"
                class="st10 st2 st3 js-MapDistrictNameEn">Novorossiysk</text>
          <text id="Новороссийск_1_" transform="matrix(1 0 0 1 168.3889 417.0468)"
                class="st10 st2 st3 js-MapDistrictName">Новоросссийск</text>
          <text id="novorossiysk-en" transform="matrix(1 0 0 1 178.1998 428.9003)" class="st5 st2 st6 js-MapCitiNameEn">Novorossiysk</text>
          <text id="novorossiysk_4_" transform="matrix(1 0 0 1 176.1999 428.2849)" class="st5 st2 st6 js-MapCitiName">Новороссийск</text>
          <circle id="Novorossiisk_1_" class="st7 js-MapDistrictCitiMarker" cx="207" cy="427.4" r="2.5"/>
        </g>
        <g data-district="gorod-kurort_gelendzhik" class="js-MapDistrict">
          <polygon id="Геленджик_1_" class="st13 js-MapDistrictPolygon" points="355.4,483 355.4,486.9 356.2,489.2 352.8,493.8 352,495.2 352,504.2
            347.3,508.3 345.4,513.1 339.7,509.5 336.1,509.1 330.1,505.3 325.6,505.4 322.6,505.6 320.9,503.9 314.7,503.9 312.1,502.3
            304.1,503 292.5,494.6 281.5,494.6 277,492.2 274.6,486.9 271.2,483.9 269,476.6 263.3,473.5 260.9,470.1 255.6,466.7 260,464.9
            259.2,463.5 255.1,460.4 252.9,461.1 253.6,463.5 252.1,465.6 245.5,462.8 240.5,457.9 237.1,454.4 233.6,450 237.5,447.6
            237.5,445.4 228,439 228,431.3 225.9,428.2 225.9,423.8 226.3,421.3 226.3,416.7 237.8,420.9 240,424.7 239.8,426.4 242.8,429.4
            250.3,429.7 249.7,432.4 247.7,432.8 247.7,434.7 252.2,439.1 254.4,444.7 256,445.1 259.4,441.3 263,441.3 269,445.9 269,447.8
            270.9,446.2 272.7,446.6 274.5,449.9 279.2,450.7 282,452.9 288.6,454.2 301.6,455.7 308.8,456.3 311.8,461.3 322.1,463.5
            322.1,459 324,457.4 326.9,456.4 329,456.6 330.8,459.4 330.8,464.1 333.1,464.9 335.2,469.8 342.4,470.5 345.7,476.6 351,479.1
                "/>
          <g v-for="point in gorodKurortGelendzhik" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text id="Gelendzhik_2_" transform="matrix(1 0 0 1 288.27 480.2466)"
                class="st10 st2 st3 js-MapDistrictNameEn">Gelendzhik</text>
          <text id="Геленджик_3_" transform="matrix(1 0 0 1 287.6763 480.2466)" class="st10 st2 st3 js-MapDistrictName">Геленджик</text>
          <text id="gelendzhik-en" transform="matrix(1 0 0 1 274.4409 465.2004)" class="st5 st2 st6 js-MapCitiNameEn">Gelendzhik</text>
          <text id="gelendzhik_3_" transform="matrix(1 0 0 1 274.4409 465.2004)" class="st5 st2 st6 js-MapCitiName">Геленджик</text>
          <circle id="Gelendzhik_1_" class="st7 js-MapDistrictCitiMarker" cx="270.1" cy="463.5" r="2.5"/>
        </g>
        <g data-district="tuapsinskiy_rayon" class="js-MapDistrict">
          <polygon id="Туапсинский_1_" class="st13 js-MapDistrictPolygon" points="439,566.4 437,562.3 431.7,559.5 428,559.5 418,564 411.2,557.3
            405.9,557.3 402.9,549 399.6,545.3 393,543.6 388.7,540.1 382.7,538.7 375,530 372.4,524.1 369.6,522.8 368.1,520.2 363.2,518.1
            357.8,518.1 352.6,514 345.4,513.1 347.3,508.3 352,504.2 352,495.2 356.2,489.2 355.4,486.9 355.4,483 359.1,481.7 365.5,478.1
            379.9,476.6 397.7,478.8 399,483.9 400.1,487.7 398.6,489.2 392.5,491.5 392.5,494.4 399.7,495.6 405.6,494.2 407.6,495
            408.5,498.6 405.6,499.1 405.6,500.5 410.9,500.5 414.1,498.3 419.9,496.5 420.3,488.5 422,486.2 430,486.3 444.6,490.5
            451,491.4 453.6,494.9 457.5,497 463.7,497.4 466.7,500.5 473.8,502.4 474.9,514.7 477.9,515.2 490.9,514.7 493.5,518.2
            494.2,523.3 488.2,531.8 485.8,534.6 485.2,539.8 490.9,541.4 494.2,544.2 496.6,552.8 497.7,554.7 494.2,557.4 482.8,558.1
            470.4,550 465.2,552.2 463.6,556.2 465.4,561.2 463.5,564.2 458.4,564.6 450.1,564.5 446.7,562.7 443.3,564.2 441.4,566.4 		"/>
          <g v-for="point in tuapsinskiyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 407.0921 525.501)" class="st1 st2 st3 js-MapDistrictNameEn">Tuapsinsky</text>
          <text transform="matrix(1 0 0 1 411.2921 532.501)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 398.8304 525.501)" class="st1 st2 st3 js-MapDistrictName">Туапсинский</text>
          <text transform="matrix(1 0 0 1 411.7304 532.501)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="tuapse-en" transform="matrix(1 0 0 1 416.2216 544.5484)"
                class="st5 st2 st6 js-MapCitiNameEn">Tuapse</text>
          <text id="tuapse_3_" transform="matrix(1 0 0 1 416.2216 544.5484)"
                class="st5 st2 st6 js-MapCitiName">Туапсе</text>
          <circle id="Tuapse_1_" class="st7 js-MapDistrictCitiMarker" cx="422" cy="550" r="2.5"/>
        </g>
        <path id="chz-borderline" class="st8 js-MapZoneBorder" d="M496.6,552.8l-2.5-8.6l-3.3-2.7l-5.7-1.6l0.7-5.2l2.3-2.9l6-8.5l-0.7-5l-2.6-3.5l-13,0.5
          l-3-0.5l-1.1-12.3l-7.1-1.9l-3-3.1l-6.3-0.4l-3.8-2l-2.6-3.5l-6.4-0.8l-14.6-4.2l-8.1-0.1l-1.6,2.3l-0.4,8.1l-5.9,1.8l-3.1,2.2
          h-5.3v-1.4l2.9-0.5l-0.8-3.5l-2-0.8l-5.9,1.4l-7.2-1.2V492l6.1-2.3l1.5-1.5l-1.1-3.8l-1.4-5l-17.7-2.2l-14.4,1.4l-6.4,3.6l-3.7,1.4
          l-4.4-4l-5.3-2.5l-3.3-6.1l-7.2-0.7l-2-4.9l-2.3-0.8V460l-1.8-2.9l-2.2-0.1l-2.9,1l-1.9,1.6v4.5l-10.2-2.2l-3-5l-7.2-0.5l-13-1.5
          l-6.7-1.4l-2.7-2.2l-4.8-0.8l-1.8-3.3l-1.8-0.4l-1.9,1.6v-1.9l-6-4.6h-3.5l-3.4,3.8l-1.6-0.4l-2.2-5.6l-4.5-4.4v-1.9l2-0.4l0.5-2.7
          l-7.5-0.3l-3-3l0.2-1.7l-2.2-3.8l-11.5-4.2v-6.7l-5.5-3.8l-6.4-3.8l1.3-2v-3.1l-4.7-1.8l-1.1-3.8l-8.4-3.3l-9.6-10.9l-3.5-9.6
          l-5.1-0.9l-2.2-2.5V361l-0.9-9.5l-4.2-3.2l-2.5-5.2l-1.3-3.8l3.6,1.5l4.2,3.6l10.7,1.5l5.4,0.4l1-3.7l2.3-4.2v-4.5l-0.5-7.6l1.9-1
          l2.7-2.5l2.2-3.8l1.6-5.9l-5.7-4.9l-5.2-5.5l-3.3-5.9l-2.7-2.9l-5.3-2.3l-10,8.5l-11.3,5.5l-1.9-1.1l-3.3,0.4l-5,1.5l-15.8-0.8
          l-12-1.4l-9.4-3.4l-11.3-4.2l-7-4.6l-12.4-7.4l-3.3-0.1L79,283l-3.3-1.9l-3.7-0.5l-7.4,1.5l-4.1,2.9l-5.3,3.3l-4.4,5.3l-6,5.5
          l-6,4.5l3.5,1.9l1.9-1.2h2.9l2-3.1l3.7-0.1l3-3.4l1.6-4.6l3.4-3.1l3,2.7l2.6,0.1l2.9,1.6l3,0.5l1.8,1.5l-3.5,0.8l-3.7,2.3l-4.2-3.1
          l-2.7,0.7l1.4,4.4v4.9l3.3,5.2l4.9-0.5l1.4-1.9l3.7-2.9l8.3-1l6.1,0.3l6.7,0.5l0.3,2.6l-3.4,3.3l2.7,2.9l-1,1.8l-5.5-0.3l-6,1.6
          l-6.3,3l-6.8,0.7l-6.4,0.1l-4.1-1.4l-3.3,1.4l-1,4l-6-0.1l-6.4-2.2l-2.9,1.8l-9.1,1.5l-8.1-4.5l-1.6,1.9l1.8,4l4.1,4l-1.1,2.3
          l2.6,5.2l8.2,4.4l9,0.5l8.5,3l5.9-1.2l8.3,0.4l6.7,1.9l12,4.9l10.2,4.8l12.3,5.5l17.9,9.3l4.8,5.7l8.2,7.4l2.2,4.4l-4.4,3.1
          l0.1,1.6l2.7,1.6l0.4,2.9l0.5,5.1l4.9,6.6l1.5,1l0.7,6.1l5.6,9.4l11.4,6.5l13.7,4.9l12.2,2.3l8.7,2.3l7.5-1.6l5,0.6l1.5-2.5
          l2.5-0.1l0.2-1.4l-3.4-9l6,0.4l3.5,5.9l4.6,2.9l9.4,6.4v2.2l-3.8,2.5l3.4,4.4l3.4,3.5l5,4.9l6.6,2.7l1.5-2l-0.7-2.5l2.2-0.7
          l4.1,3.1l0.8,1.4l-4.4,1.8l5.3,3.4l2.3,3.4l5.7,3.1l2.2,7.2l3.4,3l2.5,5.3l4.5,2.5h10.9l11.6,8.3l8.1-0.7l2.6,1.6h6.1l1.8,1.6
          l3-0.1l4.5-0.1l6,3.8l3.5,0.4l5.7,3.5l7.2,1l5.2,4.1h5.3l4.9,2l1.5,2.6l2.9,1.4l2.6,5.9l7.6,8.7l6,1.4l4.4,3.5l6.6,1.6l3.3,3.7
          l3,8.3h5.3l6.8,6.7l10-4.5h3.7l5.3,2.9l2,4.1h2.3l2-2.2l3.3-1.5l3.4,1.8l8.3,0.1l5-0.4l1.9-3l-1.8-5l1.6-4l5.2-2.2l12.4,8.1
          l11.3-0.7l3.5-2.7L496.6,552.8z M119.1,358.2l-3.7,1l-8.3-2.3l-9.3-1.1l-9.7-4.5l-9-3.7l0.3-1.8H84l2.7-2.2H93l0.5-1.5l-2.3-2.7
          l-3.4-0.7l-5.6,0.7l-4.5,0.7l-7.8-0.7l-0.3-2l7-1l5.5-3.5h3.4l4.4,1.2l3.4-0.7l2.5,2L95,340l1.5,1l3-2.5l3-0.1l4-2l3.3,1.1l3.4,0.7
          l7.2,1.2l4.4,2.5l-0.1,1.9l-2.5,1l-3,0.5l-1.8,6.7l1.8,2l1.8,2.5L119.1,358.2z"/>
      </g>
      <g data-zone="sochinskaya" @click="selectZone(3)" @mouseenter="hoverZone" class="js-MapZone">
        <g data-district="gorod-kurort_sochi" class="js-MapDistrict">
          <polygon id="Сочинская" class="st14 js-MapDistrictPolygon" points="431.2,577.3 437.2,582.6 444.7,587.2 448.1,593.2 454.3,598.3 462.5,604.1
            466.5,610.8 473.2,614.1 476.4,618.8 486.3,626.5 491.1,632.7 494.9,640.5 495.8,646.5 500.9,653.2 506,666.1 508.2,666.1
            508.6,664.9 511.4,666.1 516.4,677 521.9,688.6 524.7,696.3 532.9,702.3 534.6,706.2 534.6,713.3 538,717.4 542.3,720 547.8,720
            552.7,726.8 551.9,734.3 556.3,741 562,744.2 566.2,748.3 568.4,748.3 567.6,735.4 570.7,730.3 577,725.5 580.6,721.6
            582.2,714.4 588.5,704.8 593.8,697.4 594.3,689.1 596.4,685 598.4,686 608.1,681.1 616.5,680 622,679.8 628.2,679.4 633.5,680.2
            639.1,682 646.1,685.8 652.5,687.2 657.1,687.2 665.3,682.3 667.4,682.3 666.3,679.1 658.8,677.6 656.8,673.6 652.5,668.5
            649.6,665.8 645,651.2 637.3,648.9 632.3,638.9 632.1,632.6 630.8,629.1 623.4,630.3 623.1,625.9 623.7,622.9 621.2,620.6
            612.6,620.9 609.5,622.2 602.1,622 595.8,620.1 593.1,618.1 592,611.6 588.7,606.3 587.5,604.4 584.7,604.4 582.7,606.3
            571.4,606.4 568.3,603.8 562.8,601.9 560.9,600.3 558.4,598.4 554.4,595.2 551.1,593.4 549.3,591.4 544.3,585.5 541.7,582.8
            539.5,583.8 541.5,588.9 540.8,590.7 530.6,591.1 528.2,589.5 517.9,588.5 514,583.9 510.4,579 508.5,575.6 503.3,573.4
            496.4,568.2 489.9,565.3 484.9,561.4 482.8,558.1 470.4,550 465.2,552.2 463.6,556.2 465.4,561.2 463.5,564.2 458.4,564.6
            450.1,564.5 446.7,562.7 443.3,564.2 441.5,566.4 439,566.4 439.6,569.3 438.1,572.8 434.7,576.1 		"/>
          <g v-for="point in gorodKurortSochi" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text id="sochi" transform="matrix(1 0 0 1 552.6342 669.5596)"
                class="st10 st2 st3 js-MapDistrictNameEn">Sochi</text>
          <text id="Сочи" transform="matrix(1 0 0 1 552.9375 669.5596)"
                class="st10 st2 st3 js-MapDistrictName">Сочи</text>
          <text id="sochi-en" transform="matrix(1 0 0 1 531.6997 685.9539)"
                class="st5 st2 st6 js-MapCitiNameEn">Sochi</text>
          <text id="sochi_4_" transform="matrix(1 0 0 1 531.6997 685.9539)"
                class="st5 st2 st6 js-MapCitiName">Сочи</text>
          <circle id="Sochi_1_" class="st7 js-MapDistrictCitiMarker" cx="536.3" cy="691.5" r="2.5"/>
        </g>
        <polygon id="sa-borderline" class="st8 js-MapZoneBorder" points="431.2,577.3 437.2,582.6 444.7,587.2 448.1,593.2 454.3,598.3 462.5,604.1
          466.5,610.8 473.2,614.1 476.4,618.8 486.3,626.5 491.1,632.7 494.9,640.5 495.8,646.5 500.9,653.2 506,666.1 508.2,666.1
          508.6,664.9 511.4,666.1 516.4,677 521.9,688.6 524.7,696.3 532.9,702.3 534.6,706.2 534.6,713.3 538,717.4 542.3,720 547.8,720
          552.7,726.8 551.9,734.3 556.3,741 562,744.2 566.2,748.3 568.4,748.3 567.6,735.4 570.7,730.3 577,725.5 580.6,721.6 582.2,714.4
          588.5,704.8 593.8,697.4 594.3,689.1 596.4,685 598.4,686 608.1,681.1 616.5,680 622,679.8 628.2,679.4 633.5,680.2 639.1,682
          646.1,685.8 652.5,687.2 657.1,687.2 665.3,682.3 667.4,682.3 666.3,679.1 658.8,677.6 656.8,673.6 652.5,668.5 649.6,665.8
          645,651.2 637.3,648.9 632.3,638.9 632.1,632.6 630.8,629.1 623.4,630.3 623.1,625.9 623.7,622.9 621.2,620.6 612.6,620.9
          609.5,622.2 602.1,622 595.8,620.1 593.1,618.1 592,611.6 588.7,606.3 587.5,604.4 584.7,604.4 582.7,606.3 571.4,606.4
          568.3,603.8 562.8,601.9 560.9,600.3 558.4,598.4 554.4,595.2 551.1,593.4 549.3,591.4 544.3,585.5 541.7,582.8 539.5,583.8
          541.5,588.9 540.8,590.7 530.6,591.1 528.2,589.5 517.9,588.5 514,583.9 510.4,579 508.5,575.6 503.3,573.4 496.4,568.2
          489.9,565.3 484.9,561.4 482.8,558.1 470.4,550 465.2,552.2 463.6,556.2 465.4,561.2 463.5,564.2 458.4,564.6 450.1,564.5
          446.7,562.7 443.3,564.2 441.5,566.4 439,566.4 439.6,569.3 438.1,572.8 434.7,576.1 	"/>
      </g>
      <g data-zone="vostochnaya" @click="selectZone(4)" @mouseenter="hoverZone" class="js-MapZone">
        <g data-district="novopokrovsky_rayon" class="js-MapDistrict">
          <polygon id="Новопокровский_1_" class="st15 js-MapDistrictPolygon"
                   points="667.8,126.8 658.4,126.4 650.3,127.4 645.5,128.5     643.5,129.6 644.3,131.2 631.9,129.6 629.3,131.7 626.3,131.7 621,130.5 619.4,134.1 618.8,136 613.8,141.2 613.5,147.5     615.5,151.2 616.2,155.5 617.5,158.8 618.7,160.5 618.5,163.8 615.8,164.3 613.2,166.6 600.7,167.2 591.8,169.3 597.3,170.7     600,175.7 600.7,183.5 600.9,189.3 614.6,189.3 624.3,190.1 636.2,190.5 639.1,197.1 639.9,203.4 642.9,207 642.4,221.1     642.1,229.2 641.3,232.2 643.3,236.7 644.6,240.4 649.9,244.5 654.5,246.8 656.6,253.6 661.2,252.1 669.4,249.5 674,249.5     677.6,246 683.3,245.3 692.7,246.1 692.7,243 707.2,243 706.5,238.6 705.7,235.2 700,233.8 698.6,230.8 701.5,227.8 702.3,225.5     708.8,225.1 713.6,222.8 713.6,213.1 711,209.7 710.5,207 709.5,205.6 710.8,203.7 710.2,200.1 704.2,197.5 701.1,195.1 697,190     694.8,189.1 686.1,179.8 684.3,173.9 682.5,171.2 679.2,161.6 675.4,156.6 675.5,154.5 677.2,152.6 678.1,148.1 672.8,146.8     667.8,148.3 663,149.2 661.6,152 661.6,153.9 653.7,154.3 648.4,154 643.6,151 643.6,143.1 650.7,141.4 655.5,142.7 661.4,142.5     667.9,141.4 667.9,138.6   "></polygon>
          <g v-for="point in novopokrovskyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 642.5719 198.1177)"
                class="st1 st2 st3 js-MapDistrictNameEn">Novopokrovsky</text>
          <text transform="matrix(1 0 0 1 659.0719 205.1177)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 639.461 198.1177)"
                class="st1 st2 st3 js-MapDistrictName">Новопокровский</text>
          <text transform="matrix(1 0 0 1 659.361 205.1177)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="novopokrovskaya-en" transform="matrix(1 0 0 1 646.5999 203.3847)"
                class="st5 st2 st6 js-MapCitiNameEn">Novopokrovskaya</text>
          <text id="novopokrovskaya_1_" transform="matrix(1 0 0 1 646.5999 203.3847)"
                class="st5 st2 st6 js-MapCitiName">Новопокровская</text>
          <circle id="Novopokrovskaya" class="st7 js-MapDistrictCitiMarker" cx="659.9" cy="196.3" r="2.5"></circle>
        </g>
        <g data-district="tikhoretskiy_rayon" class="js-MapDistrict">
          <polygon id="Тихорецкий_1_" class="st15 js-MapDistrictPolygon"
                   points="600.3,260.7 611.2,260.7 619.4,264.2 631.4,264.2 636.3,259.6     644.1,258.2 649.4,256.4 652.7,256.4 656.6,253.6 654.5,246.8 649.9,244.5 644.6,240.4 641.3,232.2 642.1,229.2 642.9,207     639.9,203.4 639.1,197.1 636.2,190.5 624.3,190.1 614.6,189.3 600.9,189.3 600.7,183.5 600,175.7 597.3,170.7 591.8,169.3     583.8,171 577.6,171.4 573.5,174.1 569,175 568.9,180.2 570.3,181.7 571.8,187.8 569.4,189.9 565.6,191.1 561.1,193.5 557,193.7     555.1,192.5 552.7,193.6 537.5,194 534.7,196.9 531.7,205.2 541.6,204.3 543.4,208.3 540.9,210.8 537.8,214.7 538.7,217.4     543.3,221.1 553.1,224.5 555.9,227.2 559,226 565.2,227.2 565.6,232 569.2,235.1 571.3,235.8 576.1,236.1 582,236.8 582.5,239.7     583.6,242.5 583.6,245.6 582.3,245.7 576.8,248.4 571.1,249.4 566.7,251.4 566.6,259.7 567.5,260.8 571,261.7 572.2,268.3     575,269.8 579.7,269.8 580.7,265.7 597.5,265.8   "></polygon>
          <g v-for="point in tikhoretskiyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 581.9111 219.4511)"
                class="st1 st2 st3 js-MapDistrictNameEn">Tikhoretsky</text>
          <text transform="matrix(1 0 0 1 591.1111 226.4511)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 580.8368 219.4511)" class="st1 st2 st3 js-MapDistrictName">Тихорецкий</text>
          <text transform="matrix(1 0 0 1 591.4368 226.4511)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="tikhoretsk-en" transform="matrix(1 0 0 1 573.6996 213.9232)" class="st5 st2 st6 js-MapCitiNameEn">Tikhoretsk</text>
          <text id="tikhoretsk_1_" transform="matrix(1 0 0 1 573.6996 213.9232)" class="st5 st2 st6 js-MapCitiName">Тихорецк</text>
          <circle id="Tihoretsk" class="st7 js-MapDistrictCitiMarker" cx="570.2" cy="212.4" r="2.5"></circle>
        </g>
        <g data-district="beloglinskiy_rayon" class="js-MapDistrict">
          <polygon id="Белоглинский_1_" class="st15 js-MapDistrictPolygon"
                   points="674.3,126.4 675.7,137.5 676.6,139.1 703.1,139.1 704.5,135.6     702.8,130.4 703,124.7 711.4,124.7 713.6,131.9 713.6,142.4 710.5,142.9 707.9,145.5 707.9,147.7 709.9,147.7 711.4,159.6     716.6,158.8 722.2,158.1 724.1,160.4 724.1,167.1 729.7,167.1 730.7,169.3 733.5,171.1 733.5,183.6 737.1,185.9 738.6,190     738.6,204.4 742.4,205.6 748.8,206.3 750.3,209.7 751.8,207.5 755,207.6 754.3,210.8 751.4,212 751.4,214.6 754.2,219.6     754.2,221.1 756.3,223.5 756.3,227.6 742.8,228.1 740.6,229.9 740.8,244.9 724.8,244.9 722.4,241.9 718.1,240.1 717.2,238.2     712.8,238.5 710.3,243 707.2,243 705.7,235.2 700,233.8 698.6,230.8 701.5,227.8 702.3,225.5 708.8,225.1 713.6,222.8     713.6,213.1 711,209.7 710.5,207 709.5,205.6 710.8,203.7 710.2,200.1 704.2,197.5 701.1,195.1 697,190 694.8,189.1 686.1,179.8     684.3,173.9 682.5,171.2 679.2,161.6 675.4,156.6 675.5,154.5 677.2,152.6 678.1,148.1 672.8,146.8 667.8,148.3 663,149.2     661.6,152 661.6,153.9 653.7,154.3 648.4,154 643.6,151 643.6,143.1 650.7,141.4 655.5,142.7 661.4,142.5 667.9,141.4     667.8,126.8   "></polygon>
          <g v-for="point in beloglinskiyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 688.8966 177.5678)"
                class="st1 st2 st3 js-MapDistrictNameEn">Beloglinsky</text>
          <text transform="matrix(1 0 0 1 698.8966 184.5678)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 684.4537 177.5678)" class="st1 st2 st3 js-MapDistrictName">Белоглинский</text>
          <text transform="matrix(1 0 0 1 699.2537 184.5678)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="belaya-glina-en" transform="matrix(1 0 0 1 687.3208 172.1999)" class="st5 st2 st6 js-MapCitiNameEn">Belaya Glina</text>
          <text id="belaya-glina_1_" transform="matrix(1 0 0 1 687.3208 172.1999)" class="st5 st2 st6 js-MapCitiName">Белая Глина</text>
          <circle id="Belaya_Glina" class="st7 js-MapDistrictCitiMarker" cx="683.2" cy="170.8" r="2.5"></circle>
        </g>
        <g data-district="tbilisskiy_rayon" class="js-MapDistrict">
          <polygon id="Тбилисский_1_" class="st15 js-MapDistrictPolygon"
                   points="598.4,271.6 605,271.6 608.3,272.7 614.8,272.7 618,274.3     617.3,276.8 613.6,281.5 609.5,282.4 609.4,289.5 608.9,291.8 609.6,295 609.6,301.9 610,305.5 608.2,308.6 604.6,310.1     606.9,313.4 609.5,311.6 611.7,313.4 612.1,318.3 616.6,319.1 621.9,320.1 623.7,324.5 625.6,326 629,326.5 631.9,328.7     630.2,332 630.1,334.4 624.9,334.4 624.5,340.8 619.2,341.7 616.9,342.5 617.3,345.6 610.2,345.6 606.8,343.6 604.9,341.2     599.5,339.1 597.5,340.6 596.8,342.9 596.8,345.6 577.2,346.6 575.9,345.9 574.7,343.5 575.5,340.3 578,338.1 580.2,333.5     580.2,328.6 576.3,327.9 569.5,327.2 568.9,313.5 569.5,311.6 570,308.6 569.2,306.6 568.2,301.1 566.6,298 566.6,293.1     563.2,292.8 560.5,291.8 558,289.7 550.9,288.3 550.9,284.5 547.7,280.3 544.5,279.4 544.4,274 552.2,274 562,274.6 562,273.6     560.9,271.9 562,268.5 566.9,268.3 568.6,267.3 572.2,268.3 575,269.8 579.7,269.8 580.7,265.7 597.5,265.8 599,268   "></polygon>
          <g v-for="point in tbilisskiyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 572.9652 300.9508)" class="st1 st2 st3 js-MapDistrictNameEn">Tbilissky</text>
          <text transform="matrix(1 0 0 1 579.7652 307.9508)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 568.7352 300.9508)" class="st1 st2 st3 js-MapDistrictName">Тбилисский</text>
          <text transform="matrix(1 0 0 1 580.2352 307.9508)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="tbilisskaya-en" transform="matrix(1 0 0 1 570.1999 318.1693)" class="st5 st2 st6 js-MapCitiNameEn">Tbilisskaya</text>
          <text id="tbilisskaya_1_" transform="matrix(1 0 0 1 570.1999 318.1693)" class="st5 st2 st6 js-MapCitiName">Тбилисская</text>
          <circle id="Tbilisskaya" class="st7 js-MapDistrictCitiMarker" cx="580.5" cy="311" r="2.5"></circle>
        </g>
        <g data-district="kavkazskiy_rayon" class="js-MapDistrict">
          <polygon id="Кавказский" class="st15 js-MapDistrictPolygon"
                   points="693.1,278.1 702.4,278.1 703.1,284.2 703.1,289.1 710.8,295 703.5,295     698.7,295 690.7,295 679.6,296.2 669.5,296.8 665.3,298.1 664.5,300.4 658.5,300.4 651.9,301.7 629.8,301.7 625.6,305.3     618.8,305.3 616.7,308.2 613.7,309.3 611.9,311.4 609.5,311.6 606.9,313.4 604.6,310.1 608.2,308.6 610,305.5 609.6,301.9     609.6,295 608.9,291.8 609.4,289.5 609.5,282.4 613.6,281.5 617.3,276.8 618,274.3 614.8,272.7 608.3,272.7 605,271.6     598.4,271.6 599,268 597.5,265.8 600.3,260.7 611.2,260.7 619.4,264.2 631.4,264.2 636.3,259.6 644.1,258.2 649.4,256.4     652.7,256.4 656.6,253.6 669.4,249.5 674,249.5 677.6,246 683.3,245.3 692.7,246.1 692.8,260.1   "></polygon>
          <g v-for="point in kavkazskiyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 636.6389 277.305)" class="st1 st2 st3 js-MapDistrictNameEn">Kavkazsky</text>
          <text transform="matrix(1 0 0 1 645.2389 284.305)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 634.3381 277.305)" class="st1 st2 st3 js-MapDistrictName">Кавказский</text>
          <text transform="matrix(1 0 0 1 645.6381 284.305)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="kropotkin-en" transform="matrix(1 0 0 1 615.1698 296.2)" class="st5 st2 st6 js-MapCitiNameEn">Kropotkin</text>
          <text id="kropotkin_1_" transform="matrix(1 0 0 1 613.1698 296.2)" class="st5 st2 st6 js-MapCitiName">Кропоткин</text>
          <circle id="Kropotkin" class="st7 js-MapDistrictCitiMarker" cx="637.7" cy="295" r="2.5"></circle>
        </g>
        <g data-district="gulkevichskiy_rayon" class="js-MapDistrict">
          <polygon id="Гульукевичский_1_" class="st15 js-MapDistrictPolygon"
                   points="723.9,330.1 720.9,332.9 713.5,333.4 706.4,333.4     705.1,336.5 702.3,336.5 696.9,335.2 695,335.9 695.8,341.7 694.4,344.8 692.2,347.8 689.5,349.2 688.1,351.3 688.9,354.3     687.2,355.8 686.9,362 686,364.2 683,365.8 681.3,366.5 680,364.1 677,362 674.5,359 668.3,358.8 665.7,356 660.7,354.3     656.9,352 655.2,352 654.8,354.9 652.3,354.9 652.3,349.1 648.4,347.6 645.3,347.4 641.7,349.1 634.9,349.2 634,352.3     625.5,351.8 624.3,350.3 623.5,346.6 617.3,345.6 616.9,342.5 619.2,341.7 624.5,340.8 624.9,334.4 630.1,334.4 630.2,332     631.9,328.7 629,326.5 625.6,326 623.7,324.5 621.9,320.1 612.1,318.3 611.7,313.4 609.5,311.6 611.9,311.4 613.7,309.3     616.7,308.2 618.8,305.3 625.6,305.3 629.8,301.7 651.9,301.7 658.5,300.4 664.5,300.4 665.3,298.1 669.5,296.8 679.6,296.2     690.7,295 710.8,295 712.8,297.1 717,299.1 718,301.4 718,309.3 717.3,314.2 719.8,322.7 720,325.9 723.3,327.5   "></polygon>
          <g v-for="point in gulkevichskiyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 647.7041 324.9007)"
                class="st1 st2 st3 js-MapDistrictNameEn">Gulkevichsky</text>
          <text transform="matrix(1 0 0 1 660.0041 331.9007)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 642.5083 324.9007)"
                class="st1 st2 st3 js-MapDistrictName">ГулькевичскийF</text>
          <text transform="matrix(1 0 0 1 660.4083 331.9007)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="gulkuvichi-en" transform="matrix(1 0 0 1 663.3419 313.3998)" class="st5 st2 st6 js-MapCitiNameEn">Gulkuvichi</text>
          <text id="gulkuvichi_1_" transform="matrix(1 0 0 1 663.3419 313.3998)" class="st5 st2 st6 js-MapCitiName">Гулькевичи</text>
          <circle id="Gulkevichi" class="st7 js-MapDistrictCitiMarker" cx="658.8" cy="311.8" r="2.5"></circle>
        </g>
        <g data-district="kurganinskiy_rayon" class="js-MapDistrict">
          <polygon id="Курганинский_1_" class="st15 js-MapDistrictPolygon"
                   points="685.8,371 684.4,375.4 680.7,377.1 678.5,380.5 680.6,386.1     690.6,387.5 692.3,386.4 693.1,383.7 704.2,384.6 704.9,387.5 703.4,393.2 706.8,401.6 709.9,405.1 717.2,407.6 718.9,412.9     721.5,416.2 728.2,417.4 728.2,420.4 723,421.8 719.8,424.4 720,432 719.8,434.2 713.6,434.2 710.8,438 706.7,436.6 704.9,433.4     703.4,431.6 700.5,431.3 700.2,426.5 696.2,423.4 692.1,423.5 691.2,426.1 688.2,427.6 684.6,431.5 679.2,432 675.8,431.3     672.3,433.1 669.5,433 666.7,435.8 663.3,430.1 659.7,423.3 654.1,419.4 651.4,417.9 650.4,412.8 645.8,407.2 642.8,394.9     630.2,386.1 624.4,379.7 617.7,376 606.5,372.6 601.3,372.2 582.5,364.3 576.4,358.8 577.4,357.6 577.1,355 577.2,346.6     596.8,345.6 596.8,342.9 597.5,340.6 599.5,339.1 604.9,341.2 606.8,343.6 610.2,345.6 617.3,345.6 623.5,346.6 624.3,350.3     625.5,351.8 634,352.3 634.9,349.2 641.7,349.1 645.3,347.4 648.4,347.6 652.3,349.1 652.3,354.9 654.8,354.9 655.2,352     656.9,352 660.7,354.3 665.7,356 668.3,358.8 674.5,359 677,362 680,364.1 681.3,366.5 684.1,368.5   "></polygon>
          <g v-for="point in kurganinskiyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 632.5943 378.501)" class="st1 st2 st3 js-MapDistrictNameEn">Kurganinsky</text>
          <text transform="matrix(1 0 0 1 643.7943 385.501)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 629.5221 378.501)" class="st1 st2 st3 js-MapDistrictName">Курганинский</text>
          <text transform="matrix(1 0 0 1 644.2221 385.501)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="kurganinsk-en" transform="matrix(1 0 0 1 649.9219 403.1229)" class="st5 st2 st6 js-MapCitiNameEn">Kurganinsk</text>
          <text id="kurganinsk_1_" transform="matrix(1 0 0 1 649.9219 403.1229)" class="st5 st2 st6 js-MapCitiName">Курганинск</text>
          <circle id="Kurganinsk" class="st7 js-MapDistrictCitiMarker" cx="646.5" cy="402" r="2.5"></circle>
        </g>
        <g data-district="novokubanskiy_rayon" class="js-MapDistrict">
          <polygon id="Новокубанский_1_" class="st15 js-MapDistrictPolygon"
                   points="758.7,328.4 755.9,333.9 747.5,335 731,335.9 723.9,335.9     721.8,334.7 720.9,332.9 713.5,333.4 706.4,333.4 705.1,336.5 702.3,336.5 696.9,335.2 695,335.9 695.8,341.7 694.4,344.8     692.2,347.8 689.5,349.2 688.1,351.3 688.9,354.3 687.2,355.8 686.9,362 686,364.2 681.3,366.5 684.1,368.5 685.8,371     684.4,375.4 680.7,377.1 678.5,380.5 680.6,386.1 690.6,387.5 692.3,386.4 693.1,383.7 704.2,384.6 704.9,387.5 703.4,393.2     706.8,401.6 709.9,405.1 717.2,407.6 718.9,412.9 721.5,416.2 728.2,417.4 728.2,420.4 723,421.8 719.8,424.4 720,432     719.8,434.2 722.2,436 722.5,438 726.4,439.6 728.2,441.6 734.4,441.6 736.7,445.6 743.8,450 746.7,452 745.1,454.9 740,457.1     740,461.2 746.9,466.3 748.9,470.2 753.1,468.5 756.2,469.3 758.7,472.5 762.9,473.4 765.3,471.4 772,471.1 772,468 775.3,466.5     780.6,458.2 778.6,452 777.1,448.5 770.8,444 769.7,441.6 760.7,435.8 755.8,429.6 748.9,425 746.2,420.1 741.5,416.5     740.5,405.2 742,402.5 741.8,397 738.2,396.1 736.2,396.8 732.7,396.3 732.5,389.6 725.4,388.5 717.8,387.7 713.6,384.3     712,381.9 708.3,380.8 708.5,377.5 710.8,376.3 716.9,377.4 721.1,377 723.9,373.5 727.4,373.5 726.7,368.4 728.2,366.5     734,367.4 735.3,369.2 737.8,375.2 741.8,379 744.6,375.4 747.6,373.4 753.7,373.7 758.7,379 763.5,379.5 766.8,378.6     768.8,375.2 774.6,377.5 780.6,379.7 784.8,379.2 787.3,377.7 784.6,374.8 782.4,369.7 778,363.7 772.2,359.2 771.3,339.1     770,335.3 762,335.3 760.6,328.4   "></polygon>
          <g v-for="point in novokubanskiyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 707.3002 351.5167)"
                class="st1 st2 st3 js-MapDistrictNameEn">Novokubansky</text>
          <text transform="matrix(1 0 0 1 721.8002 358.5167)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 705.29 351.5167)" class="st1 st2 st3 js-MapDistrictName">Новокубанский</text>
          <text transform="matrix(1 0 0 1 722.1901 358.5167)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="novokubansk-en" transform="matrix(1 0 0 1 713.9005 359.8309)" class="st5 st2 st6 js-MapCitiNameEn">Novokubansk</text>
          <text id="novokubansk_1_" transform="matrix(1 0 0 1 713.9005 359.8309)" class="st5 st2 st6 js-MapCitiName">Новокубанск</text>
          <circle id="Novokubansk" class="st7 js-MapDistrictCitiMarker" cx="710.3" cy="358.2" r="2.5"></circle>
        </g>
        <g data-district="gorod_armavir" class="js-MapDistrict">
          <polygon id="Армавир_1_" class="st15 js-MapDistrictPolygon"
                   points="753.7,409.6 755.5,415.4 755.5,417.8 750.6,418.7 746.2,420.1 741.5,416.5     740.5,405.2 742,402.5 741.8,397 738.2,396.1 736.2,396.8 732.7,396.3 732.5,389.6 717.8,387.7 713.6,384.3 712,381.9     708.3,380.8 708.5,377.5 710.8,376.3 716.9,377.4 721.1,377 723.9,373.5 727.4,373.5 726.7,368.4 728.2,366.5 734,367.4     735.3,369.2 737.8,375.2 741.8,379 741.2,389.3 741.9,394 749,393.9 751.4,396.9 752.9,397.2 753.6,401.5 755.9,405.9   "></polygon>
          <g v-for="point in gorodArmavir" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 714.8157 384.1506)" class="st10 st2 st3 js-MapDistrictNameEn">Armavir</text>
          <text transform="matrix(1 0 0 1 712.7424 384.1506)" class="st10 st2 st3 js-MapDistrictName">Армавир</text>
          <text id="armavir-en" transform="matrix(1 0 0 1 708.6499 382.2079)" class="st5 st2 st16 js-MapCitiNameEn">Armavir</text>
          <text id="armavir_1_" transform="matrix(1 0 0 1 706.6499 382.2079)" class="st5 st2 st16 js-MapCitiName">Армавир</text>
          <circle id="Armavir" class="st7 js-MapDistrictCitiMarker" cx="722.8" cy="381.5" r="2.5"></circle>
        </g>
        <g data-district="uspenskiy_rayon" class="js-MapDistrict">
          <polygon id="Успенский_1_" class="st15 js-MapDistrictPolygon"
                   points="817.9,378.5 817.9,404.8 814.2,409 814.2,417.2 810.6,419.2     811.5,423.9 808.6,428.7 802.1,430.1 792.6,433.8 786.6,435.8 786.6,439 791.3,445.8 795.1,451.2 795,454.5 784.6,454.5     780.6,458.2 777.1,448.5 770.8,444 769.7,441.6 760.7,435.8 755.8,429.6 748.9,425 746.2,420.1 750.6,418.7 755.5,417.8     755.5,415.4 753.7,409.6 755.9,405.9 753.6,401.5 752.9,397.2 751.4,396.9 749,393.9 741.9,394 741.2,389.3 741.8,379     744.6,375.4 747.6,373.4 753.7,373.7 758.7,379 763.5,379.5 766.8,378.6 768.8,375.2 780.6,379.7 784.8,379.2 787.3,377.7     789.7,379.2 804.6,379.2   "></polygon>
          <g v-for="point in uspenskiyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 768.8109 400.8013)" class="st1 st2 st3 js-MapDistrictNameEn">Uspensky</text>
          <text transform="matrix(1 0 0 1 776.4109 407.8013)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 767.0077 400.8013)" class="st1 st2 st3 js-MapDistrictName">Успенский</text>
          <text transform="matrix(1 0 0 1 776.8077 407.8013)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="uspenskoye-en" transform="matrix(1 0 0 1 765.814 406.2235)" class="st5 st2 st6 js-MapCitiNameEn">Uspenskoye</text>
          <text id="uspenskoye_1_" transform="matrix(1 0 0 1 765.814 406.2235)" class="st5 st2 st6 js-MapCitiName">Успенское</text>
          <circle id="Uspenskoe" class="st7 js-MapDistrictCitiMarker" cx="761.9" cy="404.4" r="2.5"></circle>
        </g>
        <path id="vz-borderline" class="st8 js-MapZoneBorder"
              d="M531.7,205.2l9.9-0.9l1.8,4l-2.5,2.5l-3.2,3.9l0.9,2.7l4.6,3.7l9.8,3.4l2.7,2.6l3.2-1.2   l6.2,1.2l0.4,4.8l3.6,3.1l2.1,0.7l4.7,0.3l5.9,0.7l0.5,2.9l1.1,2.8v3.1l-1.3,0.1l-5.5,2.7l-5.6,1l-4.5,2l-0.1,8.3l0.9,1.1l3.5,0.9   l1.3,6.6l-3.6-1l-1.7,1l-4.9,0.2l-1.1,3.5l1.1,1.6v1l-9.7-0.5h-7.8l0.1,5.4l3.2,0.9l3.2,4.2v3.8l7.2,1.5l2.5,2.1l2.7,1l3.4,0.3v4.9   l1.6,3.1l1,5.5l0.7,2l-0.5,3l-0.6,1.9l0.6,13.7l6.8,0.6l3.9,0.7v4.9l-2.3,4.6l-2.5,2.2l-0.8,3.2l1.2,2.4l1.4,0.7L577,355l0.3,2.6   l-1,1.2l6.1,5.5l18.8,7.9l5.2,0.4l11.2,3.4l6.7,3.7l5.9,6.4l12.6,8.7l3,12.3l4.6,5.6l1,5.2l2.8,1.5l5.6,3.8l3.5,6.8l3.4,5.7   l2.8-2.9l2.7,0.1l3.5-1.8l3.4,0.7l5.3-0.5l3.7-3.8l3-1.5l0.8-2.6l4.1-0.1l4.1,3.1l0.3,4.8l2.9,0.3l1.5,1.8l1.8,3.3l4.1,1.4l2.9-3.8   h6.1l2.4,1.8l0.4,2l3.8,1.6l1.9,2h6.1l2.4,4l7.1,4.4l2.9,2l-1.6,2.9l-5.1,2.2v4.2l6.9,5.1l2,3.8l4.2-1.6l3.1,0.7l2.5,3.3l4.3,0.9   l2.4-2l6.7-0.4V468l3.3-1.5l5.3-8.4l4-3.6H795l0.2-3.3l-3.8-5.5l-4.7-6.7v-3.3l6-2l9.5-3.6l6.6-1.5l2.9-4.7l-0.9-4.7l3.6-2V409   l3.6-4.2v-26.4l-13.3,0.7h-14.9l-2.4-1.5l-2.7-2.9l-2.2-5.1l-4.4-6l-5.8-4.5l-0.9-20.1l-1.3-3.7h-8l-1.5-6.9h-1.9l-2.7,5.5   l-8.5,1.1l-16.5,1h-7.1l-2.1-1.2l-0.9-1.8l3-2.7l-0.6-2.6l-3.3-1.6l-0.2-3.2l-2.5-8.5l0.7-4.9v-7.9l-1-2.3l-4.2-2l-2-2.1l-7.6-5.9   v-4.9l-0.7-6.1h-9.3l-0.3-17.9l-0.1-14V243h14.5h3.1l2.5-4.5l4.4-0.3l1,1.9l4.2,1.8l2.5,3h16l-0.1-15l2.2-1.8l13.5-0.5v-4.1   l-2.2-2.3v-1.6l-2.7-5V212l2.9-1.2l0.7-3.1l-3.1-0.1l-1.5,2.2l-1.5-3.4l-6.4-0.7l-3.8-1.2V190l-1.5-4.1l-3.5-2.3V171l-2.9-1.8   l-1-2.2h-5.6v-6.7l-1.9-2.3l-5.6,0.7l-5.2,0.8l-1.5-11.9h-2v-2.2l2.6-2.6l3.1-0.5v-10.5l-2.2-7.2H703l-0.1,5.7l1.6,5.2l-1.4,3.5   h-26.5l-1-1.6l-1.4-11.1l-6.6,0.4l-9.4-0.4l-8.1,1l-4.8,1.1l-2,1.1l0.8,1.5l-12.4-1.5l-2.6,2.1h-3l-5.3-1.2l-1.6,3.5l-0.5,1.9   l-5,5.2l-0.3,6.3l2,3.7l0.6,4.3l1.3,3.4l1.3,1.6l-0.3,3.4l-2.6,0.5l-2.6,2.3l-12.5,0.6l-8.9,2.1l-8,1.7l-6.2,0.4l-4.1,2.7l-4.4,1   l-0.1,5.2l1.5,1.5l1.5,6.1l-2.4,2.2l-3.8,1.2l-4.5,2.4l-4.1,0.2l-1.9-1.2l-2.5,1.1l-15.2,0.5l-2.8,2.8L531.7,205.2"></path>
      </g>
      <g data-zone="predgornaya" @click="selectZone(5)" @mouseenter="hoverZone" class="js-MapZone">
        <g data-district="belorechenskiy_rayon" class="js-MapDistrict">
          <polygon id="Белореченский" class="st17 js-MapDistrictPolygon" points="487.8,424.2 488.7,420.5 491.7,420.3 494.9,420.1 496.3,412.8
            496.3,399.3 494.2,395.5 488.6,393.6 483,393.2 480.6,384.9 477.1,377.2 485.3,374.2 487.3,370.3 497.4,372 505.3,372.6
            506.7,385 508.5,386.1 513.7,388.5 516,389.7 520.1,387.6 523.6,385.4 525.5,385.4 530.6,379 543.7,379.1 549.8,383.5
            550.1,396.1 552.7,397.8 552.9,401.1 551.6,402.8 554.6,415.6 559.1,425.9 567.9,428 567.9,434.2 561.2,436.5 556,436.5
            551.8,440.3 548.4,440.3 546.6,442.9 545.2,446.7 547.9,450 551.2,454.1 547.8,458.5 546,458.5 545.9,456.4 542.8,455.1
            540.4,451.9 535.8,453.3 534.3,456 531.6,458.5 530.5,462 528.2,465.2 526.4,465.2 525.3,460.1 523.5,457.8 522.5,454.1
            512.6,453.6 510.4,448.8 508.4,446.6 505.8,446.6 505.5,452.9 501,454.4 498.8,453.3 494.9,453.3 489.8,453.3 490.5,449.3
            492.2,443.2 494.2,441.1 494.2,439.6 490.9,431.7 492.3,429.9 492.3,426.8 490.8,425.4 		"/>
          <g v-for="point in belorechenskiyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 499.8553 418.2048)"
                class="st1 st2 st3 js-MapDistrictNameEn">Belorechensky</text>
          <text transform="matrix(1 0 0 1 515.2552 425.2048)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 497.8573 418.2048)"
                class="st1 st2 st3 js-MapDistrictName">Белореченский</text>
          <text transform="matrix(1 0 0 1 515.5573 425.2048)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="belorechensk-en" transform="matrix(1 0 0 1 509.4049 429.1304)" class="st5 st2 st6 js-MapCitiNameEn">Belorechensk</text>
          <text id="belorechensk_1_" transform="matrix(1 0 0 1 509.4049 429.1304)" class="st5 st2 st6 js-MapCitiName">Белореченск</text>
          <circle id="Belorechensk" class="st7 js-MapDistrictCitiMarker" cx="538" cy="429.3" r="2.5"/>
        </g>
        <g data-district="apsheronskiy_rayon" class="js-MapDistrict">
          <polygon id="Апшеронский" class="st17 js-MapDistrictPolygon" points="528.2,589.5 526.1,585 527.1,580.1 528.8,576.5 528.8,570.4
            534.8,566.4 534.8,559.9 531.8,553.2 526.9,552 526.1,546.5 524.5,543.4 526.9,539.9 530.9,539 537.3,539 542.5,542.9 543.7,550
            549.3,556 553.7,557 558.2,561.5 564.5,559.5 567.2,551.3 569.6,551.1 575.9,545.5 573.3,536.9 573.5,533.3 574.6,528.1
            569.2,525.9 560.1,526.6 556.5,525.9 554.2,520 554.5,512 555.6,509.4 553.1,508.1 544.7,509.1 541.5,509.1 537.3,503.8
            540.4,500.5 539.8,496.1 541.1,491.6 541.5,482.2 540.8,465.2 546,458.5 545.9,456.4 542.8,455.1 540.4,451.9 535.8,453.3
            534.3,456 531.6,458.5 530.5,462 528.2,465.2 526.4,465.2 525.3,460.1 523.5,457.8 522.5,454.1 512.6,453.6 510.4,448.8
            508.4,446.6 505.8,446.6 505.5,452.9 501,454.4 498.8,453.3 489.8,453.3 483.8,453.3 479.6,454.8 478.2,458.1 473.6,462.3
            468,465.7 465.4,466.9 464.8,469 468.7,472.5 470.6,475 470.7,479.2 469.9,481.9 464.1,486.6 462.9,488.5 451,491.4 453.6,494.9
            457.5,497 463.7,497.4 466.7,500.5 473.8,502.4 474.9,514.7 477.9,515.2 490.9,514.7 493.5,518.2 494.2,523.3 488.2,531.8
            485.9,534.6 485.2,539.8 490.9,541.4 494.2,544.2 496.6,552.8 497.7,554.7 494.2,557.4 482.8,558.1 484.9,561.4 489.9,565.3
            496.4,568.2 503.3,573.4 508.5,575.6 510.4,579 514,583.9 517.9,588.5 		"/>
          <g v-for="point in apsheronskiyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 484.7761 500.9548)"
                class="st1 st2 st3 js-MapDistrictNameEn">Apsheronsky</text>
          <text transform="matrix(1 0 0 1 497.7761 507.9548)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 482.8078 500.9548)" class="st1 st2 st3 js-MapDistrictName">Апшеронский</text>
          <text transform="matrix(1 0 0 1 498.2078 507.9548)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="apsheronsk-en" transform="matrix(1 0 0 1 490.7181 488.2001)" class="st5 st2 st6 js-MapCitiNameEn">Apsheronsk</text>
          <text id="apsheronsk_1_" transform="matrix(1 0 0 1 490.7181 488.2001)" class="st5 st2 st6 js-MapCitiName">Апшеронск</text>
          <circle id="Apsheronsk_3_" class="st7 js-MapDistrictCitiMarker" cx="516.1" cy="487.2" r="2.5"/>
        </g>
        <g data-district="mostovskoy_rayon" class="js-MapDistrict">
          <polygon id="Мостовской" class="st17 js-MapDistrictPolygon" points="631.9,427.2 643.7,428.1 645.9,433.6 649.2,434.7 650.3,441.2
            649.4,443.6 650.3,446.6 655,450.5 661,455.1 661,460 663.6,464.2 663.6,469.8 664.9,472 664.9,475.4 664.9,481.4 664.9,484.7
            662.3,487.3 663.9,490 665.8,493.8 667.4,492.7 670.9,490.2 680.3,490.4 685.4,490.7 688.9,497.5 689.7,502 691.9,505.3
            693.3,506.5 696.3,518 697.8,525.5 698.6,531.1 698.6,539.5 702.6,541.3 706.2,544.3 707.1,548.7 702.8,548.7 702.7,555.6
            707.1,557 707.1,560.1 705.7,561.5 708,564.1 708,565.6 707.2,567 707.1,573.8 704.1,578.4 704.5,582.9 701.9,585.1 695.9,587.8
            694.1,591.7 688.8,593.4 686.3,593.4 685.2,594.9 684.4,597.4 681,603.3 677.2,613 677.2,617.7 678.3,620.1 681,621.3
            680.5,625.9 674,625.9 669.9,629.9 671.2,633 673.5,635.6 672.7,649.9 675.8,654.2 678.3,660.2 678.7,670.3 676.2,676.7
            676.9,680.9 679.8,684.7 679.1,687.5 674.2,686.7 671,683.9 667.4,682.3 666.3,679.1 658.8,677.6 656.8,673.6 652.5,668.5
            649.6,665.8 645,651.2 637.3,648.9 632.3,638.9 632.1,632.6 630.8,629.1 623.4,630.3 623.1,625.9 623.7,622.9 621.2,620.6
            622.9,617.9 624.9,613.9 624.8,609.8 626.1,601.1 626.7,597.9 625.2,594.5 626.1,588.3 631.5,588 633.6,585.5 633.9,576.5
            632.1,572.4 632.1,568.9 629,566.4 629.6,557.3 627.8,551.8 627.6,547 624.9,544.3 624.8,536.5 622.5,533.7 621.2,529.4
            623.1,528.5 624.6,524.4 630.4,524.3 633.4,521.9 633.5,519.3 636.4,515.5 636.2,509.2 635.3,504.1 633.2,497.9 631.5,495.5
            630.2,491.8 626.4,489.5 625.3,482.8 625.2,479.9 626.1,476.9 625.7,471.3 622,466.4 618,460 618,446.2 620.1,444.3 620.7,438
            626.8,438 630.2,436.6 631.3,434.5 		"/>
          <g v-for="point in mostovskoyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 646.7531 550.4547)" class="st1 st2 st3 js-MapDistrictNameEn">Mostovsky</text>
          <text transform="matrix(1 0 0 1 655.6531 557.4547)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 644.1974 550.4547)" class="st1 st2 st3 js-MapDistrictName">Мостовской</text>
          <text transform="matrix(1 0 0 1 655.9973 557.4547)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="mostovskiy-en" transform="matrix(1 0 0 1 660.3408 504.1006)" class="st5 st2 st6 js-MapCitiNameEn">Mostovskoy</text>
          <text id="mostovskiy_1_" transform="matrix(1 0 0 1 659.3408 504.1006)" class="st5 st2 st6 js-MapCitiName">Мостовской</text>
          <circle id="Mostovskoi_1_" class="st7 js-MapDistrictCitiMarker" cx="685.7" cy="502.5" r="2.5"/>
        </g>
        <g data-district="labinskiy_rayon" class="js-MapDistrict">
          <polygon id="Лабинский" class="st17 js-MapDistrictPolygon" points="666.7,435.8 669.1,442.5 670.8,448.8 676.5,455.1 676.5,462.7 678.5,470.1
            680.6,480.4 683.9,487.8 685.4,490.7 688.9,497.5 689.7,502 691.9,505.3 693.3,506.5 697.8,525.5 698.6,531.1 698.6,539.5
            702.6,541.3 706.2,544.3 707.1,548.7 711.4,548.7 719.4,553.6 725.4,557.3 732.1,557.3 739.5,554.7 739.5,551.7 742.6,551.7
            745.6,556 748.3,555.8 750.2,548.7 758.4,547.6 760.6,546.2 758.6,544 742.7,544 738.5,539.1 735.5,536.4 732.1,535.9
            732.1,529.8 732.9,523.5 732.1,519.7 728.2,517.5 728.2,512.2 732.9,510.9 735.6,507.3 738,510 741.2,507.8 744.2,504.9
            745.3,501.1 745.8,498 744.6,489.6 742.4,483.3 739.8,473.4 746.2,472.3 748.9,470.2 746.9,466.3 740,461.2 740,457.1
            745.1,454.9 746.7,452 736.7,445.6 734.4,441.6 728.2,441.6 726.4,439.6 722.5,438 722.2,436 719.8,434.2 713.6,434.2 710.8,438
            706.7,436.6 704.9,433.4 703.4,431.6 700.5,431.3 700.2,426.5 696.2,423.4 692.1,423.5 691.2,426.1 688.2,427.6 684.6,431.5
            679.2,432 675.8,431.3 672.3,433.1 669.5,433 		"/>
          <g v-for="point in labinskiyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 697.1935 482.9008)" class="st1 st2 st3 js-MapDistrictNameEn">Labinsky</text>
          <text transform="matrix(1 0 0 1 701.4934 489.9008)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 692.6056 482.9008)" class="st1 st2 st3 js-MapDistrictName">Лабинский</text>
          <text transform="matrix(1 0 0 1 701.9055 489.9008)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="labinsk-en" transform="matrix(1 0 0 1 684.8057 448.6074)" class="st5 st2 st6 js-MapCitiNameEn">Labinsk</text>
          <text id="labinsk_1_" transform="matrix(1 0 0 1 684.8057 448.6074)"
                class="st5 st2 st6 js-MapCitiName">Лабинск</text>
          <circle id="Labinsk_1_" class="st7 js-MapDistrictCitiMarker" cx="681" cy="446.6" r="2.5"/>
        </g>
        <g data-district="otradnenskiy_rayon" class="js-MapDistrict">
          <polygon id="Отрадненский" class="st17 js-MapDistrictPolygon" points="802.5,453.5 802.5,456.7 796.6,456.7 795,454.5 784.6,454.5
            780.6,458.2 775.3,466.5 772,468 772,471.1 765.3,471.4 762.9,473.4 758.7,472.5 756.2,469.3 753.1,468.5 748.9,470.2
            746.2,472.3 739.8,473.4 742.4,483.3 744.6,489.6 745.8,498 745.3,501.1 744.2,504.9 741.2,507.8 738,510 735.6,507.3
            732.9,510.9 728.2,512.2 728.2,517.5 732.1,519.7 732.9,523.5 732.1,529.8 732.1,535.9 735.5,536.4 738.5,539.1 742.7,544
            758.6,544 760.6,546.2 758.4,547.6 750.2,548.7 748.3,555.8 749.2,558.6 753.7,558.6 756.9,555.9 764.4,555.7 767.6,558.6
            767.6,562.1 764.9,562.1 764.8,566.3 760.6,568.1 759.9,569.5 761.9,572.8 763,572.8 763.2,570.2 769,570 770.3,571.2
            766.9,574.3 771.2,579.3 773.6,579.3 776.5,576.7 778.3,576.7 778.6,572.6 779.8,572.3 783.7,578.1 785.6,578.1 789.5,574.9
            799.1,574.5 796,568.2 795.9,562 807.1,563.3 807.1,553 810.6,549.5 821,548.2 828.5,539.9 830.8,536.2 831.5,528.4 832.8,526.6
            832.8,522.8 834.9,519 832.6,514.7 830.3,512 825.3,506.6 816.8,503.8 813.2,502.8 812.9,500.6 817.9,499.1 816.7,493.8
            818.5,492.5 826.2,493.1 828.3,491.9 828.5,485.4 831.4,484.2 834.9,483.9 837.2,479.5 829.4,478.4 829.3,476.3 825.7,476.3
            821.5,477.3 818.7,472.1 815.9,471.7 811.8,473.4 808.7,465.7 808.7,460.2 806.9,453.5 		"/>
          <g v-for="point in otradnenskiyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 757.8369 513.5547)"
                class="st1 st2 st3 js-MapDistrictNameEn">Otradnensky</text>
          <text transform="matrix(1 0 0 1 769.637 520.5547)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 754.8702 513.5547)" class="st1 st2 st3 js-MapDistrictName">Отрадненский</text>
          <text transform="matrix(1 0 0 1 769.9702 520.5547)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="otradnaya-en" transform="matrix(1 0 0 1 776.2587 505.1002)" class="st5 st2 st6 js-MapCitiNameEn">Otradnaya</text>
          <text id="otradnaya_1_" transform="matrix(1 0 0 1 777.2587 505.1002)" class="st5 st2 st6 js-MapCitiName">Отрадная</text>
          <circle id="Otradnaya_53_" class="st7 js-MapDistrictCitiMarker" cx="799.1" cy="503.6" r="2.5"/>
        </g>
        <g id="pz-borderline">
          <polygon class="st8 js-MapZoneBorder" points="573.5,533.3 574.6,528.1 569.2,525.9 560.1,526.6 556.5,525.9 554.2,520 554.5,512 555.6,509.4
            553.1,508.1 544.7,509.1 541.5,509.1 537.3,503.8 540.4,500.5 539.8,496.1 541.1,491.6 541.5,482.2 540.8,465.2 546,458.5
            547.8,458.5 551.2,454.1 547.9,450 545.2,446.7 546.6,442.9 548.4,440.3 551.8,440.3 556,436.5 561.2,436.5 567.9,434.2
            567.9,428 559.1,425.9 554.6,415.6 551.6,402.8 552.9,401.1 552.7,397.8 550.1,396.1 549.8,383.5 543.7,379.1 530.6,379
            525.5,385.4 523.6,385.4 520.1,387.6 516,389.7 513.7,388.5 508.5,386.1 506.7,385 505.3,372.6 497.4,372 487.3,370.3
            485.3,374.2 477.1,377.2 480.6,384.9 483,393.2 488.6,393.6 494.2,395.5 496.3,399.3 496.3,412.8 494.9,420.1 491.7,420.3
            488.7,420.5 487.8,424.2 490.8,425.4 492.3,426.8 492.3,429.9 490.9,431.7 494.2,439.6 494.2,441.1 492.2,443.2 490.5,449.3
            489.8,453.3 483.8,453.3 479.6,454.8 478.2,458.1 473.6,462.3 468,465.7 465.4,466.9 464.8,469 468.7,472.5 470.6,475
            470.7,479.2 469.9,481.9 464.1,486.6 462.9,488.5 451,491.4 453.6,494.9 457.5,497 463.7,497.4 466.7,500.5 473.8,502.4
            474.9,514.7 477.9,515.2 490.9,514.7 493.5,518.2 494.2,523.3 488.2,531.8 485.9,534.6 485.2,539.8 490.9,541.4 494.2,544.2
            496.6,552.8 497.7,554.7 494.2,557.4 482.8,558.1 484.9,561.4 489.9,565.3 496.4,568.2 503.3,573.4 508.5,575.6 510.4,579
            514,583.9 517.9,588.5 528.2,589.5 526.1,585 527.1,580.1 528.8,576.5 528.8,570.4 534.8,566.4 534.8,559.9 531.8,553.2
            526.9,552 526.1,546.5 524.5,543.4 526.9,539.9 530.9,539 537.3,539 542.5,542.9 543.7,550 549.3,556 553.7,557 558.2,561.5
            564.5,559.5 567.2,551.3 569.6,551.1 575.9,545.5 573.3,536.9 		"/>
          <polygon class="st8 js-MapZoneBorder" points="826.2,493.1 828.3,491.9 828.5,485.4 831.4,484.2 834.9,483.9 837.2,479.5 829.4,478.4 829.3,476.3
            825.7,476.3 821.5,477.3 818.7,472.1 815.9,471.7 811.8,473.4 808.7,465.7 808.7,460.2 806.9,453.5 802.5,453.5 802.5,456.7
            796.6,456.7 795,454.5 784.6,454.5 780.6,458.2 775.3,466.5 772,468 772,471.1 765.3,471.4 762.9,473.4 758.7,472.5 756.2,469.3
            753.1,468.5 748.9,470.2 746.9,466.3 740,461.2 740,457.1 745.1,454.9 746.7,452 736.7,445.6 734.4,441.6 728.2,441.6
            726.4,439.6 722.5,438 722.2,436 719.8,434.2 713.6,434.2 710.8,438 706.7,436.6 704.9,433.4 703.4,431.6 700.5,431.3
            700.2,426.5 696.2,423.4 692.1,423.5 691.2,426.1 688.2,427.6 684.6,431.5 679.2,432 675.8,431.3 672.3,433.1 669.5,433
            666.7,435.8 669.1,442.5 670.8,448.8 676.5,455.1 676.5,462.7 678.5,470.1 680.6,480.4 683.9,487.8 685.4,490.7 680.3,490.4
            670.9,490.2 667.4,492.7 665.8,493.8 663.9,490 662.3,487.3 664.9,484.7 664.9,481.4 664.9,475.4 664.9,472 663.6,469.8
            663.6,464.2 661,460 661,455.1 655,450.5 650.3,446.6 649.4,443.6 650.3,441.2 649.2,434.7 645.9,433.6 643.7,428.1 631.9,427.2
            631.3,434.5 630.2,436.6 626.8,438 620.7,438 620.1,444.3 618,446.2 618,460 622,466.4 625.7,471.3 626.1,476.9 625.2,479.9
            625.3,482.8 626.4,489.5 630.2,491.8 631.5,495.5 633.2,497.9 635.3,504.1 636.2,509.2 636.4,515.5 633.5,519.3 633.4,521.9
            630.4,524.3 624.6,524.4 623.1,528.5 621.2,529.4 622.5,533.7 624.8,536.5 624.9,544.3 627.6,547 627.8,551.8 629.6,557.3
            629,566.4 632.1,568.9 632.1,572.4 633.9,576.5 633.6,585.5 631.5,588 626.1,588.3 625.2,594.5 626.7,597.9 626.1,601.1
            624.8,609.8 624.9,613.9 622.9,617.9 621.2,620.6 623.7,622.9 623.1,625.9 623.4,630.3 630.8,629.1 632.1,632.6 632.3,638.9
            637.3,648.9 645,651.2 649.6,665.8 652.5,668.5 656.8,673.6 658.8,677.6 666.3,679.1 667.4,682.3 671,683.9 674.2,686.7
            679.1,687.5 679.8,684.7 676.9,680.9 676.2,676.7 678.7,670.3 678.3,660.2 675.8,654.2 672.7,649.9 673.5,635.6 671.2,633
            669.9,629.9 674,625.9 680.5,625.9 681,621.3 678.3,620.1 677.2,617.7 677.2,613 681,603.3 684.4,597.4 685.2,594.9 686.3,593.4
            688.8,593.4 694.1,591.7 695.9,587.8 701.9,585.1 704.5,582.9 704.1,578.4 707.1,573.8 707.2,567 708,565.6 708,564.1
            705.7,561.5 707.1,560.1 707.1,557 702.7,555.6 702.8,548.7 707.1,548.7 711.4,548.7 719.4,553.6 725.4,557.3 732.1,557.3
            739.5,554.7 739.5,551.7 742.6,551.7 745.6,556 748.3,555.8 749.2,558.6 753.7,558.6 756.9,555.9 764.4,555.7 767.6,558.6
            767.6,562.1 764.9,562.1 764.8,566.3 760.6,568.1 759.9,569.5 761.9,572.8 763,572.8 763.2,570.2 769,570 770.3,571.2
            766.9,574.3 771.2,579.3 773.6,579.3 776.5,576.7 778.3,576.7 778.6,572.6 779.8,572.3 783.7,578.1 785.6,578.1 789.5,574.9
            799.1,574.5 796,568.2 795.9,562 807.1,563.3 807.1,553 810.6,549.5 821,548.2 828.5,539.9 830.8,536.2 831.5,528.4 832.8,526.6
            832.8,522.8 834.9,519 832.6,514.7 830.3,512 825.3,506.6 816.8,503.8 813.2,502.8 812.9,500.6 817.9,499.1 816.7,493.8
            818.5,492.5 		"/>
        </g>
      </g>
      <g data-zone="tsentralnaya" @click="selectZone(6)" @mouseenter="hoverZone" class="js-MapZone">
        <g data-district="primorsko_akhtarskiy_rayon" class="js-MapDistrict">
          <path id="Приморско-Ахтарский_1_" class="st18 js-MapDistrictPolygon" d="M293.4,148l-1.5,3.4l-8,4.3l-0.5,8.9l-4.1,5l-0.6,6.7
            l-0.8,2.1l-2.2,2.6l-2.4,6l-2.5,0.6l-0.9-2.1L267,185l1.2-2.5l-0.1-1.9l-2.3-1l2.5-2.4l1.2-5.2l2.5-1.4l1.5,1.7l3.2-2l-2.6-3
            c0,0-1.7-0.9-2-1s-5.9-0.1-5.9-0.1l-11.4,3.8l-5.1,3l-5.6,8.4l-3,7.6v4.5l-5.6,15.9l-3.9,9.6l-3.1,4.7l-2.7,3h-4.2l-0.9,6.4
            l-0.5,3.3l1.7,2.4l17.9,1.7l2.3-2.3l5.3-4.3l1.9-1.2l2.3-8.7l3.7,0.8l4.2,0.5l5.6,2.2l-0.2,4l1.3,1.4l8.9,1.5l0.9,2.1l4.5,0.5
            l5.5-0.8l7.5-1.8l4.3-1.7l0.6-3l4.1,0.8l6.4,4l3.5,2.9l2.3,1.7l6.9-0.3l7.4-1l6.6-2.5l2.5-1.6h5.1l1.2-1.3l2.7-3v-2.9l4.3-3.9
            l-1.3-7.1l0.2-8l3.3-4.5l4.1-2.8l6-3.8l2.1-0.4l-0.6-5.5l-1.9-5.5h-6l-1.8-5.2l-2.7-4.9l-5.6-3.5l-2.3,1.6l-3.5-1.8l-1.5-1.6h-2.5
            l-4.8,1.6l-2.9-0.3l-6.4-3.3l-7.2-5.8l-5.3-4.3l-4.9-4.9l-2.8-2.2l-2.3-0.5l-1.7-1.2H297l-0.1-1.4h1.5v-1.8l-0.8-0.6l1.5-1.1v-1.5
            l-1.5-0.5l-1.4-2.3l1.4-1.1l0.1-2.8l3.5-2.1l1.6-2l-2-4.2l-2.8,2.4l-2.4,5.5l-0.6,4.6l-0.6,6.9L293.4,148z"/>
          <g v-for="point in primorskoAkhtarskiyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 262.8643 202.9601)" class="st1 st2 st3 js-MapDistrictNameEn">Primorsko-Akhtarsky</text>
          <text transform="matrix(1 0 0 1 290.5643 209.9601)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 260.9084 202.6012)"
                class="st1 st2 st3 js-MapDistrictName">Приморско-Ахтарский</text>
          <text transform="matrix(1 0 0 1 292.4084 209.6012)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="primorsko-akhtarsk-en" transform="matrix(1 0 0 1 287.3999 176.5383)"
                class="st5 st2 st6 js-MapCitiNameEn">Primorsko-Akhtarsk</text>
          <text id="primorsko-akhtarsk_3_" transform="matrix(1 0 0 1 287.3999 176.5383)"
                class="st5 st2 st6 js-MapCitiName">Приморско-Ахтарский</text>
          <circle id="Primorsko-Akhtarsk" class="st7 js-MapDistrictCitiMarker" cx="285.9" cy="170.2" r="2.5"/>
        </g>
        <g data-district="slavyanskiy_rayon" class="js-MapDistrict">
          <polygon id="Славянский_1_" class="st18 js-MapDistrictPolygon" points="213.9,233.3 207.9,234.8 204,234.8 195,240 190.8,249.3 189.3,261.3
            190.1,273.3 191.5,277.1 190.1,285 185.2,291.7 190.5,294 193.2,296.9 196.5,302.8 201.7,308.2 207.4,313.1 205.8,319
            203.6,322.8 200.9,325.3 199,326.2 199.5,333.9 199.5,338.4 197.2,342.6 196.3,346.3 200.3,343.7 206.2,342.6 214.9,344
            214.9,349.4 221.2,349.4 228.3,349.4 230.5,349.4 234.7,346.8 246.5,347.4 254.1,346.3 258.8,344.9 267.4,345.3 269.7,347.2
            276.5,347.2 282.6,342.2 282.6,340.3 280.2,335.6 272.5,331.4 269.5,321 267.2,317.6 266.1,313.5 258.2,311.8 257.5,300.8
            259.8,297.6 260.4,293.6 260.4,287.2 259.3,284.9 260.9,282.3 265,278.5 265,267 271.9,261.3 276.8,259.6 276.8,247.9
            271.6,247.9 264.8,243.1 264.2,240.6 261.3,240.6 258.1,240.6 255.2,244.1 250.8,244.1 243.6,240.6 239.8,240.6 221.9,238.9
            220.1,236.5 221.5,226.9 		"/>
          <g v-for="point in slavyanskiyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 205.7463 290.3752)" class="st1 st2 st3 js-MapDistrictNameEn">Slavyansky</text>
          <text transform="matrix(1 0 0 1 216.2463 297.3752)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 205.1341 290.0162)" class="st1 st2 st3 js-MapDistrictName">Славянский</text>
          <text transform="matrix(1 0 0 1 218.0341 297.0162)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="slavyansk-na-kubani-en" transform="matrix(1 0 0 1 222.7443 328.0002)"
                class="st5 st2 st6 js-MapCitiNameEn">Slavyansk-on-Kuban</text>
          <text id="slavyansk-na-kubani_3_" transform="matrix(1 0 0 1 220.7443 328.0002)"
                class="st5 st2 st6 js-MapCitiName">Славянск-на-Кубани</text>
          <circle id="Slavyansk-na-Kubani" class="st7 js-MapDistrictCitiMarker" cx="263.4" cy="327.8" r="2.5"/>
        </g>
        <g data-district="kalininskiy_rayon" class="js-MapDistrict">
          <polygon id="Калининский_1_" class="st18 js-MapDistrictPolygon" points="350.6,235.8 352.1,240.6 357.2,245.6 358.8,248.7 357.9,253.1
            351.9,257.4 348.8,259.4 348.8,265.6 353.8,267.8 358.8,272.9 370.1,277.6 372.6,276.2 378.3,276.4 383.2,280.6 390.1,285.1
            390.1,288.2 387.4,293.1 387.7,296.4 388.6,301.8 385,301.8 384.1,304 385.2,306.8 384.4,309.1 377.7,310.6 374.4,313.3
            372.6,313.3 372.3,316.4 367.9,320.6 364.4,322.2 362.2,322.2 360.8,319.7 357.9,317.5 354.4,318.8 353.9,322.2 349.9,325
            347.1,325 343.7,319.5 347.1,316.2 349.3,312.8 343.7,312 336,309.3 336,300 340.2,297.8 340.2,291.8 334.8,291.8 330.2,289.7
            325.3,285.8 322.9,279.5 324.9,276.7 325.3,273.5 320,271.8 318.4,274.6 313.8,278.4 302.7,279.1 299.7,275.3 295.1,275.3
            292.9,265.1 284.9,262.9 276.8,259.6 276.8,247.9 271.6,247.9 264.8,243.1 264.2,240.6 258.1,240.6 255.2,244.1 250.8,244.1
            243.6,240.6 239.8,240.6 242.1,238.3 245.7,235.4 247.3,234.1 249.2,232.9 251.5,224.1 255.3,225 259.4,225.5 265.1,227.7
            264.9,231.7 266.2,233.1 275.1,234.6 276,236.7 280.5,237.2 285.9,236.3 293.4,234.5 297.7,232.8 298.3,229.8 302.4,230.6
            308.8,234.6 312.3,237.5 314.6,239.3 321.5,239 328.9,238 335.5,235.5 338,233.9 343.1,233.9 344.3,232.5 		"/>
          <g v-for="point in kalininskiyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 298.3901 255.1139)" class="st1 st2 st3 js-MapDistrictNameEn">Kalininsky</text>
          <text transform="matrix(1 0 0 1 306.5901 262.1139)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 294.8443 254.7549)" class="st1 st2 st3 js-MapDistrictName">Калининский</text>
          <text transform="matrix(1 0 0 1 308.3443 261.7549)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="kalininskaya-en" transform="matrix(1 0 0 1 354.4754 287.6998)" class="st5 st2 st6 js-MapCitiNameEn">Kalininskaya</text>
          <text id="kalininskaya_3_" transform="matrix(1 0 0 1 354.4754 287.6998)" class="st5 st2 st6 js-MapCitiName">Калининская</text>
          <circle id="Kalininskaya" class="st7 js-MapDistrictCitiMarker" cx="350.1" cy="284.7" r="2.5"/>
        </g>
        <g data-district="krasnoarmeyskiy_rayon" class="js-MapDistrict">
          <polygon id="Красноармейский_1_" class="st18 js-MapDistrictPolygon" points="351.8,329.2 352.9,332.1 358.7,335 357.7,341.9
            362.2,345.3 369.2,348.8 375.2,351.6 375.7,355 375.7,356.8 373.7,358 370.1,360.9 365.8,358.2 364.4,359.3 365.8,363.5
            362.9,366.8 360.3,366.8 358.7,364.6 353.5,365 343.2,364.9 339.7,364.6 339.7,360.6 332,359.4 327.5,356.7 323.4,356.7
            322.5,359.9 320.6,359.9 318.4,356.8 314.9,354.3 309.4,353.1 304.5,352.6 301.5,348.4 285.8,345.7 282.6,342.2 282.6,340.3
            280.2,335.6 272.5,331.4 269.5,321 267.2,317.6 266.1,313.5 258.2,311.8 257.5,300.8 259.8,297.6 260.4,293.6 260.4,287.2
            259.3,284.9 260.9,282.3 265,278.5 265,267 271.9,261.3 276.8,259.6 284.9,262.9 292.9,265.1 295.1,275.3 299.7,275.3
            302.7,279.1 313.8,278.4 318.4,274.6 320,271.8 325.3,273.5 324.9,276.7 322.9,279.5 325.3,285.8 330.2,289.7 334.8,291.8
            340.2,291.8 340.2,297.8 336,300 336,309.3 343.7,312 349.3,312.8 347.1,316.2 343.7,319.5 347.1,325 349.9,325 		"/>
          <g v-for="point in krasnoarmeyskiyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 272.6123 313.5601)"
                class="st1 st2 st3 js-MapDistrictNameEn">Krasnoarmeysky</text>
          <text transform="matrix(1 0 0 1 286.4123 320.5601)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 270.8722 313.2011)"
                class="st1 st2 st3 js-MapDistrictName">Красноармейский</text>
          <text transform="matrix(1 0 0 1 294.2722 320.2011)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="poltavskaya-en" transform="matrix(1 0 0 1 285.3008 305.4102)" class="st5 st2 st6 js-MapCitiNameEn">Poltavskaya</text>
          <text id="poltavskaya_3_" transform="matrix(1 0 0 1 285.3008 305.4102)" class="st5 st2 st6 js-MapCitiName">Полтавская</text>
          <circle id="Poltavskaya" class="st7 js-MapDistrictCitiMarker" cx="280.7" cy="302.9" r="2.5"/>
        </g>
        <g data-district="bryukhovetskiy_rayon" class="js-MapDistrict">
          <polygon id="Брюховецкий_1_" class="st18 js-MapDistrictPolygon" points="489.3,224.1 487.2,226.1 476.9,226.1 475.6,228.4 472.7,228.4
            468.2,229.6 457.1,229.1 455.7,232.1 455.7,233.8 451.2,234.8 443.5,234.8 431,233 431,237.7 427.7,237.7 424.3,239.7 416,239.7
            414.5,234.7 399.2,235.2 397.4,229.3 395,226.7 381.2,227.3 367.3,227.3 366.4,230.2 364,231.4 351.1,230.6 347.1,229.5
            347.1,226.6 351.3,222.7 350.1,215.6 350.2,207.6 353.5,203.1 357.6,200.3 363.6,196.5 365.7,196.1 367,197.9 373.5,197.9
            377.1,198.9 378,195.4 373.8,192.4 373.5,186.1 367.8,181.7 367.4,179.2 364.1,176.4 365.2,173.9 371.3,174.1 373.8,174.7
            382.9,174.1 385.8,173.5 389.2,172.7 391.4,173.1 390.6,181.2 392.5,184.2 398.9,184.2 401.6,182.5 407.4,184 408.5,190.4
            411.7,194.3 416.4,194.8 420.1,196 424.8,195.9 434.4,198.1 437.3,198.9 448.2,198.9 452.7,202.2 466.6,202.7 469.6,201.1
            478.3,200.7 483.8,196 487.2,195.9 486.7,203.9 488,209.8 488.6,220.9 		"/>
          <g v-for="point in bryukhovetskiyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 396.5044 214.6832)"
                class="st1 st2 st3 js-MapDistrictNameEn">Bryukhovetsky</text>
          <text transform="matrix(1 0 0 1 410.0044 221.6832)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 398.5685 214.3242)" class="st1 st2 st3 js-MapDistrictName">Брюховецкий</text>
          <text transform="matrix(1 0 0 1 411.7685 221.3242)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="bryukhovetskaya-en" transform="matrix(1 0 0 1 409.1496 220.8)" class="st5 st2 st6 js-MapCitiNameEn">Bryukhovetskaya</text>
          <text id="bryukhovetskaya_3_" transform="matrix(1 0 0 1 409.1496 220.8)" class="st5 st2 st6 js-MapCitiName">Брюховецкая</text>
          <circle id="Bryukhovetskaya" class="st7 js-MapDistrictCitiMarker" cx="404.5" cy="220.8" r="2.5"/>
        </g>
        <g data-district="krymskiy_rayon" class="js-MapDistrict">
          <polygon id="Крымский_1_" class="st18 js-MapDistrictPolygon" points="172.3,339.3 173.6,343.2 176.1,348.4 180.3,351.5 181.2,361 181.2,364.3
            183.4,366.8 188.5,367.7 191.9,377.4 195.2,381.2 201.6,388.3 209.9,391.6 211,395.4 215.8,397.2 215.8,400.3 214.5,402.3
            220.9,406.1 226.3,409.9 226.3,416.7 230.9,418.3 237.8,420.9 240,424.7 242.3,422.3 244,420.3 249.4,420.3 261.4,423
            261.4,416.1 263.6,413.4 263.6,409.9 264.7,406.1 264,402.1 260.5,398.3 264.7,393 267.8,390.8 269.1,387.6 272.4,383.9
            273.6,378.8 272,376.3 274.7,374.5 279.8,372.3 282.6,363.5 282.6,356.1 285.8,353 285.8,345.7 282.6,342.2 276.5,347.2
            269.7,347.2 267.4,345.3 258.8,344.9 254.1,346.3 246.5,347.4 234.7,346.8 230.5,349.4 214.9,349.4 214.9,344 206.2,342.6
            200.3,343.7 196.3,346.3 190.8,345.9 180.1,344.4 175.9,340.8 		"/>
          <g v-for="point in krymskiyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 216.1953 375.36)" class="st1 st2 st3 js-MapDistrictNameEn">Krymsky</text>
          <text transform="matrix(1 0 0 1 223.0953 382.36)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 214.6056 375.0011)" class="st1 st2 st3 js-MapDistrictName">Крымский</text>
          <text transform="matrix(1 0 0 1 224.9056 382.0011)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="krymsk-en" transform="matrix(1 0 0 1 224.9998 389.6144)"
                class="st5 st2 st6 js-MapCitiNameEn">Krymsk</text>
          <text id="krymsk_3_" transform="matrix(1 0 0 1 223.9998 389.6144)"
                class="st5 st2 st6 js-MapCitiName">Крымск</text>
          <circle id="Krimsk_1_" class="st7 js-MapDistrictCitiMarker" cx="242.9" cy="389.1" r="2.5"/>
        </g>
        <g data-district="abinskiy_rayon" class="js-MapDistrict">
          <polygon id="Абинский_1_" class="st18 js-MapDistrictPolygon" points="322.2,392 330.1,394.5 330.1,405.5 326.2,405.5 324.1,406.7 324.4,416.3
            319.2,417.1 316.6,421.1 314.9,425.6 316.6,428.3 315.8,433.5 314,436.5 314.9,441.1 317.3,447.4 317.4,451.8 324,457.4
            322.1,459 322.1,463.5 311.8,461.3 308.8,456.3 301.6,455.7 288.6,454.2 282,452.9 279.2,450.7 274.5,449.9 272.7,446.6
            270.9,446.2 269,447.8 269,445.9 263,441.3 259.4,441.3 256,445.1 254.4,444.7 252.2,439.1 247.7,434.7 247.7,432.8 249.7,432.4
            250.3,429.7 242.8,429.4 239.8,426.4 240,424.7 242.3,422.3 244,420.3 249.4,420.3 261.4,423 261.4,416.1 263.6,413.4
            263.6,409.9 264.7,406.1 264,402.1 260.5,398.3 264.7,393 267.8,390.8 269.1,387.6 272.4,383.9 273.6,378.8 272,376.3
            274.7,374.5 279.8,372.3 282.6,363.5 282.6,356.1 285.8,353 285.8,345.7 301.5,348.4 304.5,352.6 309.4,353.1 314.9,354.3
            318.4,356.8 320.6,359.9 322.5,359.9 323.4,356.7 327.5,356.7 332,359.4 339.7,360.6 339.7,364.6 338.8,366.3 340.3,366.8
            340.3,368.4 336,372.6 333.4,374.5 332.7,377 325.7,375.6 324.5,377.8 323.7,385.6 		"/>
          <g v-for="point in abinskiyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 277.0722 398.9985)" class="st1 st2 st3 js-MapDistrictNameEn">Abinsky</text>
          <text transform="matrix(1 0 0 1 282.2722 405.9985)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 275.5186 398.6396)" class="st1 st2 st3 js-MapDistrictName">Абинский</text>
          <text transform="matrix(1 0 0 1 284.0186 405.6396)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="abinsk-en" transform="matrix(1 0 0 1 276.3064 401.3256)"
                class="st5 st2 st6 js-MapCitiNameEn">Abinsk</text>
          <text id="abinsk_3_" transform="matrix(1 0 0 1 276.3064 401.3256)"
                class="st5 st2 st6 js-MapCitiName">Абинск</text>
          <circle id="Abinsk_1_" class="st7 js-MapDistrictCitiMarker" cx="272.9" cy="398.6" r="2.5"/>
        </g>
        <g data-district="vyselkovskiy_rayon" class="js-MapDistrict">
          <polygon id="Выселковский_1_" class="st18 js-MapDistrictPolygon" points="492.5,230.4 490.7,233.1 490.1,239.7 488.9,243.8 487,254.3
            491.5,255.5 492.9,254.5 495.4,254.9 497.3,259.2 496.3,263 493.6,266 493.6,269.9 495.3,274 497.7,276.3 507.5,278.8 508,287.1
            508.9,290 508.9,293.4 520.9,292.9 523.4,294.2 530,294.8 533.2,296.2 547.5,295.4 557,295.1 560.5,291.8 558,289.7 550.9,288.3
            550.9,284.5 547.7,280.3 544.5,279.4 544.4,274 552.2,274 562,274.6 562,273.6 560.9,271.9 562,268.5 566.9,268.3 568.6,267.3
            572.2,268.3 571,261.7 567.5,260.8 566.6,259.7 566.7,251.4 571.1,249.4 576.8,248.4 582.3,245.7 583.6,245.6 583.6,242.5
            582.5,239.7 582,236.8 576.1,236.1 571.3,235.8 569.2,235.1 565.6,232 565.2,227.2 559,226 555.9,227.2 553.1,224.5 543.3,221.1
            538.7,217.4 537.8,214.7 540.9,210.8 543.4,208.3 541.6,204.3 531.7,205.2 528.5,205.6 526.7,203.6 497.9,201.4 496.5,204.6
            486.7,203.9 488,209.8 488.6,220.9 489.3,224.1 491.8,227.2 		"/>
          <g v-for="point in vyselkovskiyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 506.6605 247.3139)"
                class="st1 st2 st3 js-MapDistrictNameEn">Vyselkovsky</text>
          <text transform="matrix(1 0 0 1 517.8605 254.3139)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 504.0718 246.9549)" class="st1 st2 st3 js-MapDistrictName">Выселковский</text>
          <text transform="matrix(1 0 0 1 519.6718 253.9549)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="vyselki-en" transform="matrix(1 0 0 1 510.5408 265.9001)" class="st5 st2 st6 js-MapCitiNameEn">Vyselki</text>
          <text id="vyselki_3_" transform="matrix(1 0 0 1 510.5408 265.9001)"
                class="st5 st2 st6 js-MapCitiName">Выселки</text>
          <circle id="Viselki" class="st7 js-MapDistrictCitiMarker" cx="506.4" cy="265.9" r="2.5"/>
        </g>
        <g data-district="korenovskiy_rayon" class="js-MapDistrict">
          <polygon id="Кореновский_1_" class="st18 js-MapDistrictPolygon" points="430.3,305.5 430.3,287.7 430.3,282 426.1,281.1 426.1,271.5
            424.8,268 424.8,263.5 429.4,263.5 433.8,260.5 436.7,258.2 441.2,258.2 443.1,257 444.3,253.1 448.2,251.6 449.3,248.8
            464.6,248.4 466.9,245.8 467.3,243.3 469.5,241.9 466.7,236.6 457.8,236.6 455.7,233.8 455.7,232.1 457.1,229.1 468.2,229.6
            472.7,228.4 475.6,228.4 476.9,226.1 487.2,226.1 489.3,224.1 491.8,227.2 492.5,230.4 490.7,233.1 490.1,239.7 488.9,243.8
            487,254.3 491.5,255.5 492.9,254.5 495.4,254.9 497.3,259.2 496.3,263 493.6,266 493.6,269.9 495.3,274 497.7,276.3 507.5,278.8
            508,287.1 508.9,290 508.9,293.4 502,293.5 501.1,295.1 501.5,300 500.4,302.6 494.7,304 494.6,308.5 492.7,312.1 484.5,312.7
            482.8,316.7 472.3,316.7 468,317.6 467.1,321.3 465.3,323.3 462.9,324.5 464.6,320.2 466.9,314.8 466.9,312 461.3,311
            445.7,310.3 443,307.1 437.5,305.9 		"/>
          <g v-for="point in korenovskiyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 443.5994 280.9601)" class="st1 st2 st3 js-MapDistrictNameEn">Korenovsky</text>
          <text transform="matrix(1 0 0 1 453.7994 287.9601)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 442.1118 280.6011)" class="st1 st2 st3 js-MapDistrictName">Кореновский</text>
          <text transform="matrix(1 0 0 1 455.6118 287.6011)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="korenovsk-en" transform="matrix(1 0 0 1 452.0003 289.7)" class="st5 st2 st6 js-MapCitiNameEn">Korenovsk</text>
          <text id="korenovsk_3_" transform="matrix(1 0 0 1 451.0003 289.7)" class="st5 st2 st6 js-MapCitiName">Кореновск</text>
          <circle id="Korenovsk" class="st7 js-MapDistrictCitiMarker" cx="475.2" cy="289.7" r="2.5"/>
        </g>
        <g data-district="timashevskiy_rayon" class="js-MapDistrict">
          <polygon id="Тимашевский_1_" class="st18 js-MapDistrictPolygon" points="405.3,309.7 407,306.2 410.6,305.3 430.3,305.5 430.3,282
            426.1,281.1 426.1,271.5 424.8,268 424.8,263.5 429.4,263.5 433.8,260.5 436.7,258.2 441.2,258.2 443.1,257 444.3,253.1
            448.2,251.6 449.3,248.8 464.6,248.4 466.9,245.8 467.3,243.3 469.5,241.9 466.7,236.6 457.8,236.6 455.7,233.8 451.2,234.8
            443.5,234.8 431,233 431,237.7 427.7,237.7 424.3,239.7 416,239.7 414.5,234.7 399.2,235.2 397.4,229.3 395,226.7 381.2,227.3
            367.3,227.3 366.4,230.2 364,231.4 351.1,230.6 347.1,229.5 344.3,232.5 350.6,235.8 352.1,240.6 357.2,245.6 358.8,248.7
            357.9,253.1 351.9,257.4 348.8,259.4 348.8,265.6 353.8,267.8 358.8,272.9 370.1,277.6 372.6,276.2 378.3,276.4 383.2,280.6
            390.1,285.1 390.1,288.2 387.4,293.1 388.6,301.8 391.9,302.8 393.4,306.4 397.1,307.1 401.4,307.6 403.8,309.7 		"/>
          <g v-for="point in timashevskiyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 372.7806 256.9138)"
                class="st1 st2 st3 js-MapDistrictNameEn">Timashevsky</text>
          <text transform="matrix(1 0 0 1 384.6806 263.9138)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 372.1509 256.5548)" class="st1 st2 st3 js-MapDistrictName">Тимашевский</text>
          <text transform="matrix(1 0 0 1 386.4509 263.5548)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="timashevsk-en" transform="matrix(1 0 0 1 396.4999 259.7002)" class="st5 st2 st6 js-MapCitiNameEn">Timashevsk</text>
          <text id="timashevsk_3_" transform="matrix(1 0 0 1 396.4999 259.7002)" class="st5 st2 st6 js-MapCitiName">Тимашевск</text>
          <circle id="Timashevsk" class="st7 js-MapDistrictCitiMarker" cx="392.7" cy="259.2" r="2.5"/>
        </g>
        <g data-district="ust_labinskiy_rayon" class="js-MapDistrict">
          <polygon id="Усть-Лабинский_1_" class="st18 js-MapDistrictPolygon" points="566.6,293.1 566.6,298 568.2,301.1 569.2,306.6 570,308.6
            569.5,311.6 568.9,313.5 569.1,320 569.5,327.2 576.3,327.9 580.2,328.6 580.2,333.5 578,338.1 575.5,340.3 574.7,343.5
            575.9,345.9 577.2,346.6 577.1,355 577.4,357.6 576.4,358.8 574.8,359.7 573.9,361.2 562.1,360.8 548.1,360.8 543.2,358.9
            535.2,358.6 531.5,355.9 521.3,352.2 512,352.1 512,345 510.5,342.9 508.5,341.8 507.8,340 502.8,339.2 498.7,340.4 494.9,341.2
            490.8,341.2 489.1,345.4 486.7,349.8 481.8,355 480,354.8 479.7,352 482.6,346.7 481.5,331.2 473.9,330.7 468.1,329.2
            467.6,326.2 462.9,324.5 465.3,323.3 467.1,321.3 468,317.6 472.3,316.7 482.8,316.7 484.5,312.7 492.7,312.1 494.6,308.5
            494.7,304 500.4,302.6 501.5,300 501.1,295.1 502,293.5 508.9,293.4 520.9,292.9 523.4,294.2 530,294.8 533.2,296.2 547.5,295.4
            557,295.1 560.5,291.8 563.2,292.8 		"/>
          <g v-for="point in ustLabinskiyRayon" v-bind:key="point.x" class="js-projectPoint">
            <image
                v-if="level > 1"
                :href="point.icon"
                :width="point.iconWidth"
                :height="point.iconHeight"
                :x="point.iconX"
                :y="point.iconY"
                @click="$emit('project-selected', point.project)"/>
            <circle v-if="level < 3" r="4" :cx="point.x" :cy="point.y" :fill="point.color"/>
          </g>
          <text transform="matrix(1 0 0 1 509.0819 324.86)" class="st1 st2 st3 js-MapDistrictNameEn">Ust-Labinsky</text>
          <text transform="matrix(1 0 0 1 520.9819 331.86)" class="st1 st4 st3 js-MapDistrictNameEn">district</text>
          <text transform="matrix(1 0 0 1 505.1617 324.501)"
                class="st1 st2 st3 js-MapDistrictName">Усть-Лабинский</text>
          <text transform="matrix(1 0 0 1 522.7617 331.501)" class="st1 st4 st3 js-MapDistrictName">район</text>
          <text id="ust-labinsk-en" transform="matrix(1 0 0 1 509.0715 334.0001)" class="st5 st2 st6 js-MapCitiNameEn">Ust-Labinsk</text>
          <text id="ust-labinsk_1_" transform="matrix(1 0 0 1 509.0715 334.0001)" class="st5 st2 st6 js-MapCitiName">Усть-Лабинск</text>
          <circle id="Ust-Labinsk" class="st7 js-MapDistrictCitiMarker" cx="504.8" cy="331.7" r="2.5"/>
        </g>
        <path id="tsz-borderline" class="st8 js-MapZoneBorder" d="M582.5,239.7l-0.5-2.9l-5.9-0.7l-4.7-0.3l-2.1-0.7l-3.6-3.1l-0.4-4.8L559,226l-3.2,1.2
          l-2.7-2.6l-9.8-3.4l-4.6-3.7l-0.9-2.7l3.2-3.9l2.5-2.5l-1.8-4l-9.9,0.9l-3.2,0.4l-1.8-2l-28.8-2.2l-1.4,3.2l-9.8-0.7l0.5-8
          l-3.4,0.1l-5.5,4.6l-8.7,0.4l-3,1.6l-13.9-0.5l-4.5-3.3h-10.9l-2.9-0.8l-9.6-2.2l-4.8,0.1l-3.7-1.2l-4.6-0.5l-3.3-3.8l-1.1-6.4
          l-5.7-1.5l-2.7,1.6h-6.4l-1.9-3l0.8-8.1l-2.2-0.4l-3.4,0.8L383,174l-9.1,0.7l-2.5-0.7l-6.1-0.1l-1.1,2.5l3.3,2.9l0.4,2.5l5.7,4.4
          l0.3,6.3l4.2,3l-1,3.5l-3.5-1H367l-1.3-1.8l-0.6-5.5l-1.9-5.5h-6l-1.8-5.2l-2.7-4.9l-5.6-3.5l-2.3,1.6l-3.5-1.8l-1.5-1.6h-2.5
          l-4.8,1.6l-2.9-0.3l-6.4-3.3L316,162l-5.3-4.3l-4.9-4.9l-2.8-2.2l-2.3-0.5l-1.7-1.2h-2.1l-0.1-1.4h1.5v-1.8l-0.8-0.6l1.5-1.1v-1.5
          l-1.5-0.5l-1.4-2.3l1.4-1.1l0.1-2.8l3.5-2.1l1.6-2l-2-4.2l-2.8,2.4l-2.4,5.5l-0.6,4.6l-0.6,6.9l-0.9,1.2l-1.5,3.4l-8,4.3l-0.5,8.9
          l-4.1,5l-0.6,6.7l-0.8,2.1l-2.2,2.6l-2.4,6l-2.5,0.6l-0.9-2.1L267,185l1.2-2.5l-0.1-1.9l-2.3-1l2.5-2.4l1.2-5.2l2.5-1.4l1.5,1.7
          l3.2-2l-2.6-3c0,0-1.7-0.9-2-1s-5.9-0.1-5.9-0.1l-11.4,3.8l-5.1,3l-5.6,8.4l-3,7.6v4.5l-5.6,15.9l-3.9,9.6l-3.1,4.7l-2.7,3h-4.2
          l-7.6,6.4l-6,1.5h-4l-9,5.2l-4.2,9.3l-1.5,12l0.8,12l1.4,3.8l-1.4,7.9l-4.9,6.7l5.3,2.3l2.7,2.9l3.3,5.9l5.2,5.5l5.7,4.9l-1.6,5.9
          l-2.2,3.8l-2.7,2.5l-1.9,1l0.5,7.6v4.5l-2.3,4.2l-1,3.7l-5.4-0.4l-10.7-1.5l-4.2-3.6l-3.6-1.5l1.3,3.8l2.5,5.2l4.2,3.2l0.9,9.5v3.3
          l2.2,2.5l5.1,0.9l3.5,9.6l3.3,3.8l6.4,7.1l8.4,3.3l1.1,3.8l4.7,1.8v3.1l-1.3,2l6.4,3.8l5.5,3.8v6.7l4.5,1.6l6.9,2.5l2.2,3.8
          l-0.2,1.7l3,3l7.5,0.3l-0.5,2.7l-2,0.4v1.9l4.5,4.4l2.2,5.6l1.6,0.4l3.4-3.8h3.5l6,4.6v1.9l1.9-1.6l1.8,0.4l1.8,3.3l4.8,0.8
          l2.7,2.2l6.7,1.4l13,1.5l7.2,0.5l3,5l10.2,2.2V459l1.9-1.6l-6.6-5.6l-0.1-4.4l-2.4-6.3l-0.9-4.6l1.8-3l0.8-5.2l-1.7-2.7l1.7-4.5
          l2.6-4l5.2-0.8l-0.3-9.6l2-1.2h4v-11.1l-7.9-2.5l1.5-6.4l0.8-7.8l1.2-2.2l7,1.4l0.7-2.5l2.6-1.9l4.3-4.2v-1.6l-1.5-0.6l0.9-1.7
          l3.5,0.3l10.2,0.1l5.2-0.4l1.6,2.2h2.6l2.9-3.3l-1.4-4.2l1.4-1.1l4.4,2.7l3.5-2.9l2-1.2V355l-0.5-3.4l-6-2.9l-7-3.4l-4.5-3.4l1-7
          L353,332l-1.1-2.9l-2-4.3l4-2.7l0.5-3.5l3.5-1.3l2.9,2.2l1.5,2.5h2.2l3.5-1.6l4.4-4.2l0.4-3.1h1.8l3.3-2.7l6.7-1.5l0.7-2.4
          l-1.1-2.7l0.9-2.2h3.3h0.1h0.2h0.1l3.3,0.9l1.5,3.6l3.8,0.8l4.2,0.5l2.4,2.1h1.6l1.6-3.5l3.7-0.8l19.7,0.1l7.2,0.4l5.5,1.2l2.7,3.3
          l15.6,0.7l5.6,1v2.8l-2.3,5.5l-1.7,4.2l4.6,1.7l0.5,3.1l5.8,1.5l7.6,0.5l1.2,15.5L480,352l0.3,2.9l1.8,0.2l4.9-5.2l2.3-4.4l1.7-4.2
          h4.2l3.8-0.8l4.1-1.2l5,0.8l0.7,1.8l2,1.1l1.4,2.1v7.1l9.3,0.1l10.2,3.7l3.7,2.7l8.1,0.3l4.8,1.8l14.1,0.1l11.8,0.4l0.9-1.5l1.6-1
          l1-1.2l-0.3-2.6l0.2-8.4l-1.4-0.7l-1.2-2.4l0.8-3.2l2.5-2.2l2.3-4.6v-4.9l-3.9-0.7l-6.8-0.6l-0.4-7.2l-0.3-6.6l0.6-1.9l0.5-3
          l-0.7-2l-1-5.5L567,298v-4.9l-3.4-0.3l-2.7-1l-2.5-2.1l-7.2-1.5v-3.8l-3.2-4.2l-3.2-0.9l-0.1-5.4h7.8l9.7,0.5v-1l-1.1-1.6l1.1-3.5
          l4.9-0.2l1.7-1l3.6,1l-1.3-6.6l-3.5-0.9l-0.9-1.1l0.1-8.3l4.5-2l5.6-1l5.5-2.7l1.3-0.1v-3.1L582.5,239.7z"/>
      </g>
    </g>
    <g id="neighbours">
      <g id="neighbours-en" class="st11">
        <g class="st20">
      <path class="st21" d="M258.6,599v-8h3.9c1.5,0,2.3,0.9,2.3,2c0,1-0.6,1.7-1.4,1.8c0.9,0.1,1.5,1,1.5,2c0,1.2-0.8,2.2-2.3,2.2
        H258.6z M263.4,593.2c0-0.6-0.4-1-1.1-1H260v2.1h2.2C263,594.3,263.4,593.8,263.4,593.2z M263.5,596.6c0-0.6-0.4-1.1-1.2-1.1H260
        v2.2h2.3C263.1,597.7,263.5,597.3,263.5,596.6z"/>
      <path class="st21" d="M267.1,599v-8h1.4v6.8h3.5v1.2H267.1z"/>
      <path class="st21"
            d="M279.8,599l-0.6-1.5h-3.7l-0.6,1.5h-1.6l3.1-8h1.8l3.1,8H279.8z M277.3,592.4l-1.4,3.8h2.9L277.3,592.4z"/>
      <path class="st21" d="M282.2,595c0-2.5,1.8-4.1,4.2-4.1c1.6,0,2.6,0.8,3.2,1.8l-1.2,0.6c-0.4-0.7-1.2-1.2-2-1.2
        c-1.6,0-2.8,1.2-2.8,2.9c0,1.7,1.2,2.9,2.8,2.9c0.9,0,1.6-0.5,2-1.2l1.2,0.6c-0.6,1-1.6,1.8-3.2,1.8
        C284,599.1,282.2,597.4,282.2,595z"/>
      <path class="st21" d="M296.2,599l-2.7-3.4l-0.7,0.8v2.6h-1.4v-8h1.4v3.8l3.1-3.8h1.7l-3.3,3.8l3.5,4.2H296.2z"/>
      <path class="st21" d="M266.4,617.8l0.8-1.1c0.6,0.6,1.4,1.1,2.5,1.1c1.2,0,1.6-0.6,1.6-1.1c0-1.7-4.7-0.6-4.7-3.6
        c0-1.3,1.2-2.3,2.9-2.3c1.2,0,2.3,0.4,3,1.1l-0.8,1c-0.6-0.6-1.5-0.9-2.3-0.9c-0.8,0-1.4,0.4-1.4,1c0,1.5,4.7,0.6,4.7,3.5
        c0,1.3-0.9,2.5-3.1,2.5C268.2,619.1,267.1,618.6,266.4,617.8z"/>
      <path class="st21" d="M274.7,619v-8h5.5v1.2h-4.1v2.1h4v1.2h-4v2.2h4.1v1.2H274.7z"/>
      <path class="st21"
            d="M288,619l-0.6-1.5h-3.7l-0.6,1.5h-1.6l3.1-8h1.8l3.1,8H288z M285.6,612.4l-1.4,3.8h2.9L285.6,612.4z"/>
    </g>
        <g class="st20">
      <path class="st21" d="M718.5,637.4l-2.7-3.4l-0.7,0.8v2.6h-1.4v-8h1.4v3.8l3.1-3.8h1.7l-3.3,3.8l3.5,4.2H718.5z"/>
          <path class="st21"
                d="M727.8,637.4l-0.6-1.5h-3.7l-0.6,1.5h-1.6l3.1-8h1.8l3.1,8H727.8z M725.4,630.8l-1.4,3.8h2.9L725.4,630.8z"
          />
          <path class="st21" d="M735.5,637.4l-1.8-3h-1.4v3h-1.4v-8h3.5c1.6,0,2.6,1,2.6,2.5c0,1.4-0.9,2.2-1.9,2.4l2,3.1H735.5z
         M735.6,631.9c0-0.8-0.6-1.3-1.4-1.3h-1.9v2.5h1.9C735,633.2,735.6,632.7,735.6,631.9z"/>
          <path class="st21"
                d="M744.9,637.4l-0.6-1.5h-3.7l-0.6,1.5h-1.6l3.1-8h1.8l3.1,8H744.9z M742.5,630.8l-1.4,3.8h2.9L742.5,630.8z"
          />
          <path class="st21" d="M747.3,633.4c0-2.5,1.8-4.1,4.2-4.1c1.6,0,2.6,0.8,3.2,1.8l-1.2,0.6c-0.4-0.7-1.2-1.2-2-1.2
        c-1.6,0-2.8,1.2-2.8,2.9c0,1.7,1.2,2.9,2.8,2.9c0.9,0,1.6-0.5,2-1.2l1.2,0.6c-0.6,1-1.6,1.8-3.2,1.8
        C749.2,637.5,747.3,635.9,747.3,633.4z"/>
          <path class="st21" d="M762.1,637.4v-3.5h-4.2v3.5h-1.4v-8h1.4v3.3h4.2v-3.3h1.4v8H762.1z"/>
          <path class="st21"
                d="M771.5,637.4l-0.6-1.5h-3.7l-0.6,1.5H765l3.1-8h1.8l3.1,8H771.5z M769,630.8l-1.4,3.8h2.9L769,630.8z"/>
          <path class="st21" d="M774.6,637.4v-8h1.4v8H774.6z"/>
          <path class="st21" d="M778.1,635V634h2.9v1.1H778.1z"/>
          <path class="st21" d="M782.9,633.4c0-2.5,1.8-4.1,4.2-4.1c1.6,0,2.6,0.8,3.2,1.8l-1.2,0.6c-0.4-0.7-1.2-1.2-2-1.2
        c-1.6,0-2.8,1.2-2.8,2.9c0,1.7,1.2,2.9,2.8,2.9c0.9,0,1.6-0.5,2-1.2l1.2,0.6c-0.6,1-1.6,1.8-3.2,1.8
        C784.7,637.5,782.9,635.9,782.9,633.4z"/>
          <path class="st21" d="M797.6,637.4v-3.5h-4.2v3.5h-1.4v-8h1.4v3.3h4.2v-3.3h1.4v8H797.6z"/>
          <path class="st21" d="M801.4,637.4v-8h5.5v1.2h-4.1v2.1h4v1.2h-4v2.2h4.1v1.2H801.4z"/>
          <path class="st21" d="M813.7,637.4l-1.8-3h-1.4v3h-1.4v-8h3.5c1.6,0,2.6,1,2.6,2.5c0,1.4-0.9,2.2-1.9,2.4l2,3.1H813.7z
         M813.8,631.9c0-0.8-0.6-1.3-1.4-1.3h-1.9v2.5h1.9C813.2,633.2,813.8,632.7,813.8,631.9z"/>
          <path class="st21"
                d="M822.1,637.4l-2.7-3.4l-0.7,0.8v2.6h-1.4v-8h1.4v3.8l3.1-3.8h1.7l-3.3,3.8l3.5,4.2H822.1z"/>
          <path class="st21" d="M825.4,637.4v-8h5.5v1.2h-4.1v2.1h4v1.2h-4v2.2h4.1v1.2H825.4z"/>
          <path class="st21" d="M832.4,636.3l0.8-1.1c0.6,0.6,1.4,1.1,2.5,1.1c1.2,0,1.6-0.6,1.6-1.1c0-1.7-4.7-0.6-4.7-3.6
        c0-1.3,1.2-2.3,2.9-2.3c1.2,0,2.3,0.4,3,1.1l-0.8,1c-0.6-0.6-1.5-0.9-2.3-0.9c-0.8,0-1.4,0.4-1.4,1c0,1.5,4.7,0.6,4.7,3.5
        c0,1.3-0.9,2.5-3.1,2.5C834.2,637.5,833.1,637,832.4,636.3z"/>
          <path class="st21" d="M840.1,636.3l0.8-1.1c0.6,0.6,1.4,1.1,2.5,1.1c1.2,0,1.6-0.6,1.6-1.1c0-1.7-4.7-0.6-4.7-3.6
        c0-1.3,1.2-2.3,2.9-2.3c1.2,0,2.3,0.4,3,1.1l-0.8,1c-0.6-0.6-1.5-0.9-2.3-0.9c-0.8,0-1.4,0.4-1.4,1c0,1.5,4.7,0.6,4.7,3.5
        c0,1.3-0.9,2.5-3.1,2.5C841.9,637.5,840.8,637,840.1,636.3z"/>
          <path class="st21" d="M848.5,637.4v-8h1.4v8H848.5z"/>
          <path class="st21"
                d="M857.8,637.4l-0.6-1.5h-3.7l-0.6,1.5h-1.6l3.1-8h1.8l3.1,8H857.8z M855.4,630.8l-1.4,3.8h2.9L855.4,630.8z"
          />
          <path class="st21" d="M761.5,657.4l-1.8-3h-1.4v3H757v-8h3.5c1.6,0,2.6,1,2.6,2.5c0,1.4-0.9,2.2-1.9,2.4l2,3.1H761.5z
         M761.7,651.9c0-0.8-0.6-1.3-1.4-1.3h-1.9v2.5h1.9C761.1,653.2,761.7,652.7,761.7,651.9z"/>
          <path class="st21" d="M765.2,657.4v-8h5.5v1.2h-4.1v2.1h4v1.2h-4v2.2h4.1v1.2H765.2z"/>
          <path class="st21" d="M772.8,657.4v-8h3.5c1.7,0,2.6,1.2,2.6,2.5s-0.9,2.5-2.6,2.5h-2.1v3H772.8z M777.5,651.9
        c0-0.8-0.6-1.3-1.4-1.3h-1.9v2.5h1.9C776.9,653.2,777.5,652.7,777.5,651.9z"/>
          <path class="st21" d="M780.6,654.3v-4.8h1.4v4.8c0,1.3,0.7,2.1,2.1,2.1c1.3,0,2-0.8,2-2.1v-4.8h1.4v4.8c0,2-1.1,3.3-3.5,3.3
        C781.8,657.5,780.6,656.2,780.6,654.3z"/>
          <path class="st21" d="M790,657.4v-8h3.9c1.5,0,2.3,0.9,2.3,2c0,1-0.6,1.7-1.4,1.8c0.9,0.1,1.5,1,1.5,2c0,1.2-0.8,2.2-2.3,2.2H790
        z M794.7,651.7c0-0.6-0.4-1-1.1-1h-2.2v2.1h2.2C794.3,652.7,794.7,652.3,794.7,651.7z M794.9,655.1c0-0.6-0.4-1.1-1.2-1.1h-2.3
        v2.2h2.3C794.4,656.2,794.9,655.7,794.9,655.1z"/>
          <path class="st21" d="M798.4,657.4v-8h1.4v6.8h3.5v1.2H798.4z"/>
          <path class="st21" d="M805.1,657.4v-8h1.4v8H805.1z"/>
          <path class="st21" d="M808.5,653.4c0-2.5,1.8-4.1,4.2-4.1c1.6,0,2.6,0.8,3.2,1.8l-1.2,0.6c-0.4-0.7-1.2-1.2-2-1.2
        c-1.6,0-2.8,1.2-2.8,2.9c0,1.7,1.2,2.9,2.8,2.9c0.9,0,1.6-0.5,2-1.2l1.2,0.6c-0.6,1-1.6,1.8-3.2,1.8
        C810.3,657.5,808.5,655.9,808.5,653.4z"/>
    </g>
        <g class="st20">
      <path class="st21" d="M780.7,276.2l0.8-1.1c0.6,0.6,1.4,1.1,2.5,1.1c1.2,0,1.6-0.6,1.6-1.1c0-1.7-4.7-0.6-4.7-3.6
        c0-1.3,1.2-2.3,2.9-2.3c1.2,0,2.3,0.4,3,1.1l-0.8,1c-0.6-0.6-1.5-0.9-2.3-0.9c-0.8,0-1.4,0.4-1.4,1c0,1.5,4.7,0.6,4.7,3.5
        c0,1.3-0.9,2.5-3.1,2.5C782.5,277.4,781.4,276.9,780.7,276.2z"/>
          <path class="st21" d="M790.9,277.3v-6.8h-2.4v-1.2h6.2v1.2h-2.4v6.8H790.9z"/>
          <path class="st21"
                d="M801.2,277.3l-0.6-1.5h-3.7l-0.6,1.5h-1.6l3.1-8h1.8l3.1,8H801.2z M798.7,270.7l-1.4,3.8h2.9L798.7,270.7z"
          />
          <path class="st21" d="M806,277.3l-3.1-8h1.6l2.4,6.5l2.4-6.5h1.6l-3.1,8H806z"/>
          <path class="st21" d="M817,277.3l-1.8-3h-1.4v3h-1.4v-8h3.5c1.6,0,2.6,1,2.6,2.5c0,1.4-0.9,2.2-1.9,2.4l2,3.1H817z M817.1,271.8
        c0-0.8-0.6-1.3-1.4-1.3h-1.9v2.5h1.9C816.5,273.1,817.1,272.6,817.1,271.8z"/>
          <path class="st21" d="M820.1,273.3c0-2.4,1.7-4.1,4.1-4.1s4.1,1.8,4.1,4.1s-1.7,4.1-4.1,4.1S820.1,275.7,820.1,273.3z
         M826.9,273.3c0-1.7-1-2.9-2.7-2.9c-1.6,0-2.7,1.2-2.7,2.9c0,1.6,1,2.9,2.7,2.9C825.8,276.2,826.9,274.9,826.9,273.3z"/>
          <path class="st21" d="M830.3,277.3v-8h3.5c1.7,0,2.6,1.2,2.6,2.5s-0.9,2.5-2.6,2.5h-2.1v3H830.3z M835,271.8
        c0-0.8-0.6-1.3-1.4-1.3h-1.9v2.5h1.9C834.4,273.1,835,272.6,835,271.8z"/>
          <path class="st21" d="M837.9,273.3c0-2.4,1.7-4.1,4.1-4.1s4.1,1.8,4.1,4.1s-1.7,4.1-4.1,4.1S837.9,275.7,837.9,273.3z
         M844.6,273.3c0-1.7-1-2.9-2.7-2.9c-1.6,0-2.7,1.2-2.7,2.9c0,1.6,1,2.9,2.7,2.9C843.6,276.2,844.6,274.9,844.6,273.3z"/>
          <path class="st21" d="M848.1,277.3v-8h1.4v6.8h3.5v1.2H848.1z"/>
          <path class="st21" d="M798.7,297.3l-1.8-3h-1.4v3h-1.4v-8h3.5c1.6,0,2.6,1,2.6,2.5c0,1.4-0.9,2.2-1.9,2.4l2,3.1H798.7z
         M798.8,291.8c0-0.8-0.6-1.3-1.4-1.3h-1.9v2.5h1.9C798.2,293.1,798.8,292.6,798.8,291.8z"/>
          <path class="st21" d="M802.3,297.3v-8h5.5v1.2h-4.1v2.1h4v1.2h-4v2.2h4.1v1.2H802.3z"/>
          <path class="st21" d="M809.4,293.3c0-2.5,1.9-4.1,4.2-4.1c1.6,0,2.6,0.7,3.2,1.7l-1.2,0.6c-0.4-0.6-1.2-1.1-2.1-1.1
        c-1.6,0-2.8,1.2-2.8,2.9c0,1.7,1.2,2.9,2.8,2.9c0.8,0,1.5-0.4,1.9-0.7v-1.2h-2.4V293h3.8v3c-0.8,0.9-1.9,1.5-3.3,1.5
        C811.3,297.5,809.4,295.8,809.4,293.3z"/>
          <path class="st21" d="M819.2,297.3v-8h1.4v8H819.2z"/>
          <path class="st21" d="M822.6,293.3c0-2.4,1.7-4.1,4.1-4.1s4.1,1.8,4.1,4.1s-1.7,4.1-4.1,4.1S822.6,295.7,822.6,293.3z
         M829.3,293.3c0-1.7-1-2.9-2.7-2.9c-1.6,0-2.7,1.2-2.7,2.9c0,1.6,1,2.9,2.7,2.9C828.3,296.2,829.3,294.9,829.3,293.3z"/>
          <path class="st21" d="M838.3,297.3l-4.2-5.7v5.7h-1.4v-8h1.4l4.1,5.6v-5.6h1.4v8H838.3z"/>
    </g>
        <g class="st20">
      <path class="st21" d="M697.1,60.4l-1.8-3h-1.4v3h-1.4v-8h3.5c1.6,0,2.6,1,2.6,2.5c0,1.4-0.9,2.2-1.9,2.4l2,3.1H697.1z
         M697.2,54.9c0-0.8-0.6-1.3-1.4-1.3h-1.9v2.5h1.9C696.6,56.2,697.2,55.7,697.2,54.9z"/>
          <path class="st21" d="M700.2,56.4c0-2.4,1.7-4.1,4.1-4.1s4.1,1.8,4.1,4.1s-1.7,4.1-4.1,4.1S700.2,58.8,700.2,56.4z M706.9,56.4
        c0-1.7-1-2.9-2.7-2.9c-1.6,0-2.7,1.2-2.7,2.9c0,1.6,1,2.9,2.7,2.9C705.9,59.3,706.9,58.1,706.9,56.4z"/>
          <path class="st21" d="M709.7,59.3l0.8-1.1c0.6,0.6,1.4,1.1,2.5,1.1c1.2,0,1.6-0.6,1.6-1.1c0-1.7-4.7-0.6-4.7-3.6
        c0-1.3,1.2-2.3,2.9-2.3c1.2,0,2.3,0.4,3,1.1l-0.8,1c-0.6-0.6-1.5-0.9-2.3-0.9c-0.8,0-1.4,0.4-1.4,1c0,1.5,4.7,0.6,4.7,3.5
        c0,1.3-0.9,2.5-3.1,2.5C711.5,60.5,710.5,60,709.7,59.3z"/>
          <path class="st21" d="M719.9,60.4v-6.8h-2.4v-1.2h6.2v1.2h-2.4v6.8H719.9z"/>
          <path class="st21" d="M724.8,56.4c0-2.4,1.7-4.1,4.1-4.1s4.1,1.8,4.1,4.1s-1.7,4.1-4.1,4.1S724.8,58.8,724.8,56.4z M731.6,56.4
        c0-1.7-1-2.9-2.7-2.9c-1.6,0-2.7,1.2-2.7,2.9c0,1.6,1,2.9,2.7,2.9C730.5,59.3,731.6,58.1,731.6,56.4z"/>
          <path class="st21" d="M736.9,60.4l-3.1-8h1.6l2.4,6.5l2.4-6.5h1.6l-3.1,8H736.9z"/>
          <path class="st21" d="M698.5,80.4l-1.8-3h-1.4v3H694v-8h3.5c1.6,0,2.6,1,2.6,2.5c0,1.4-0.9,2.2-1.9,2.4l2,3.1H698.5z M698.7,74.9
        c0-0.8-0.6-1.3-1.4-1.3h-1.9v2.5h1.9C698.1,76.2,698.7,75.7,698.7,74.9z"/>
          <path class="st21" d="M702.1,80.4v-8h5.5v1.2h-4.1v2.1h4V77h-4v2.2h4.1v1.2H702.1z"/>
          <path class="st21" d="M709.3,76.4c0-2.5,1.9-4.1,4.2-4.1c1.6,0,2.6,0.7,3.2,1.7l-1.2,0.6c-0.4-0.6-1.2-1.1-2.1-1.1
        c-1.6,0-2.8,1.2-2.8,2.9c0,1.7,1.2,2.9,2.8,2.9c0.8,0,1.5-0.4,1.9-0.7v-1.2H713v-1.2h3.8v3c-0.8,0.9-1.9,1.5-3.3,1.5
        C711.2,80.6,709.3,78.9,709.3,76.4z"/>
          <path class="st21" d="M719,80.4v-8h1.4v8H719z"/>
          <path class="st21" d="M722.4,76.4c0-2.4,1.7-4.1,4.1-4.1c2.4,0,4.1,1.8,4.1,4.1s-1.7,4.1-4.1,4.1
        C724.1,80.5,722.4,78.8,722.4,76.4z M729.2,76.4c0-1.7-1-2.9-2.7-2.9c-1.6,0-2.7,1.2-2.7,2.9c0,1.6,1,2.9,2.7,2.9
        C728.1,79.3,729.2,78.1,729.2,76.4z"/>
          <path class="st21" d="M738.2,80.4l-4.2-5.7v5.7h-1.4v-8h1.4l4.1,5.6v-5.6h1.4v8H738.2z"/>
    </g>
        <g class="st20">
      <path class="st21"
            d="M135.8,169.3l-0.6-1.5h-3.7l-0.6,1.5h-1.6l3.1-8h1.8l3.1,8H135.8z M133.4,162.7l-1.4,3.8h2.9L133.4,162.7z"
      />
          <path class="st21" d="M138.8,169.3v-1.1l4.1-5.6h-4.1v-1.2h5.9v1.1l-4.1,5.6h4.2v1.2H138.8z"/>
          <path class="st21" d="M146.2,165.3c0-2.4,1.7-4.1,4.1-4.1c2.4,0,4.1,1.8,4.1,4.1s-1.7,4.1-4.1,4.1
        C147.9,169.4,146.2,167.7,146.2,165.3z M152.9,165.3c0-1.7-1-2.9-2.7-2.9c-1.6,0-2.7,1.2-2.7,2.9c0,1.6,1,2.9,2.7,2.9
        C151.9,168.2,152.9,166.9,152.9,165.3z"/>
          <path class="st21" d="M158.3,169.3l-3.1-8h1.6l2.4,6.5l2.4-6.5h1.6l-3.1,8H158.3z"/>
          <path class="st21" d="M134.8,188.2l0.8-1.1c0.6,0.6,1.4,1.1,2.5,1.1c1.2,0,1.6-0.6,1.6-1.1c0-1.7-4.7-0.6-4.7-3.6
        c0-1.3,1.2-2.3,2.9-2.3c1.2,0,2.3,0.4,3,1.1l-0.8,1c-0.6-0.6-1.5-0.9-2.3-0.9c-0.8,0-1.4,0.4-1.4,1c0,1.5,4.7,0.6,4.7,3.5
        c0,1.3-0.9,2.5-3.1,2.5C136.6,189.4,135.5,188.9,134.8,188.2z"/>
          <path class="st21" d="M143.2,189.3v-8h5.5v1.2h-4.1v2.1h4v1.2h-4v2.2h4.1v1.2H143.2z"/>
          <path class="st21"
                d="M156.5,189.3l-0.6-1.5h-3.7l-0.6,1.5H150l3.1-8h1.8l3.1,8H156.5z M154.1,182.7l-1.4,3.8h2.9L154.1,182.7z"
          />
    </g>
      </g>
      <g id="neighbours-ru">
        <g class="st22">
      <path class="st21" d="M255.3,593.1c0,1.2,0.6,1.7,1.6,1.7c0.6,0,1.2-0.1,1.7-0.3V591h1.4v8h-1.4v-3.3c-0.5,0.2-1.2,0.3-1.8,0.3
        c-2,0-2.9-1.2-2.9-2.9V591h1.4V593.1z"/>
      <path class="st21" d="M262.2,599v-8h5.5v1.2h-4.1v2.1h4v1.2h-4v2.2h4.1v1.2H262.2z"/>
      <path class="st21" d="M269.8,599v-8h3.5c1.7,0,2.6,1.2,2.6,2.5s-0.9,2.5-2.6,2.5h-2.1v3H269.8z M274.4,593.5
        c0-0.8-0.6-1.3-1.4-1.3h-1.9v2.5h1.9C273.9,594.7,274.4,594.2,274.4,593.5z"/>
      <path class="st21" d="M283.1,599v-3.5H279v3.5h-1.4v-8h1.4v3.3h4.2V591h1.4v8H283.1z"/>
      <path class="st21" d="M286.4,595c0-2.4,1.7-4.1,4.1-4.1c2.4,0,4.1,1.8,4.1,4.1s-1.7,4.1-4.1,4.1
        C288.1,599.1,286.4,597.3,286.4,595z M293.1,595c0-1.7-1-2.9-2.7-2.9c-1.6,0-2.7,1.2-2.7,2.9c0,1.6,1,2.9,2.7,2.9
        C292.1,597.9,293.1,596.6,293.1,595z"/>
      <path class="st21" d="M296.4,599v-8h5.5v1.2h-4.1v2.1h4v1.2h-4v2.2h4.1v1.2H296.4z"/>
      <path class="st21" d="M268.2,619V613l-2.5,5.9h-0.6l-2.4-5.9v5.9h-1.4v-8h2l2.2,5.3l2.2-5.3h2v8H268.2z"/>
      <path class="st21" d="M271.4,615c0-2.4,1.7-4.1,4.1-4.1c2.4,0,4.1,1.8,4.1,4.1s-1.7,4.1-4.1,4.1
        C273.1,619.1,271.4,617.3,271.4,615z M278.2,615c0-1.7-1-2.9-2.7-2.9c-1.6,0-2.7,1.2-2.7,2.9c0,1.6,1,2.9,2.7,2.9
        C277.1,617.9,278.2,616.6,278.2,615z"/>
      <path class="st21" d="M281.5,619v-8h3.5c1.7,0,2.6,1.2,2.6,2.5s-0.9,2.5-2.6,2.5h-2.1v3H281.5z M286.1,613.5
        c0-0.8-0.6-1.3-1.4-1.3h-1.9v2.5h1.9C285.6,614.7,286.1,614.2,286.1,613.5z"/>
      <path class="st21" d="M289.3,619v-8h5.5v1.2h-4.1v2.1h4v1.2h-4v2.2h4.1v1.2H289.3z"/>
    </g>
        <g class="st22">
      <path class="st21" d="M711.6,637.4l-2.7-3.4l-0.7,0.8v2.6h-1.4v-8h1.4v3.8l3.1-3.8h1.7l-3.3,3.8l3.5,4.2H711.6z"/>
          <path class="st21"
                d="M720.9,637.4l-0.6-1.5h-3.7l-0.6,1.5h-1.6l3.1-8h1.8l3.1,8H720.9z M718.5,630.8l-1.4,3.8h2.9L718.5,630.8z"
          />
          <path class="st21" d="M724.1,637.4v-8h3.5c1.7,0,2.6,1.2,2.6,2.5s-0.9,2.5-2.6,2.5h-2.1v3H724.1z M728.8,631.9
        c0-0.8-0.6-1.3-1.4-1.3h-1.9v2.5h1.9C728.2,633.2,728.8,632.7,728.8,631.9z"/>
          <path class="st21"
                d="M737,637.4l-0.6-1.5h-3.7l-0.6,1.5h-1.6l3.1-8h1.8l3.1,8H737z M734.5,630.8l-1.4,3.8h2.9L734.5,630.8z"/>
          <path class="st21" d="M740.4,631.5c0,1.2,0.6,1.7,1.6,1.7c0.6,0,1.2-0.1,1.7-0.3v-3.5h1.4v8h-1.4v-3.3c-0.5,0.2-1.2,0.3-1.8,0.3
        c-2,0-2.9-1.2-2.9-2.9v-2.1h1.4V631.5z"/>
          <path class="st21"
                d="M753.1,637.4l-0.6-1.5h-3.7l-0.6,1.5h-1.6l3.1-8h1.8l3.1,8H753.1z M750.6,630.8l-1.4,3.8h2.9L750.6,630.8z"
          />
          <path class="st21" d="M756.2,637.4v-8h5.5v1.2h-4.1v2.1h4v1.2h-4v2.2h4.1v1.2H756.2z"/>
          <path class="st21" d="M763.7,637.4v-8h3.9c1.5,0,2.3,0.9,2.3,2c0,1-0.6,1.7-1.4,1.8c0.9,0.1,1.5,1,1.5,2c0,1.2-0.8,2.2-2.3,2.2
        H763.7z M768.5,631.7c0-0.6-0.4-1-1.1-1h-2.2v2.1h2.2C768.1,632.7,768.5,632.3,768.5,631.7z M768.7,635.1c0-0.6-0.4-1.1-1.2-1.1
        h-2.3v2.2h2.3C768.2,636.2,768.7,635.7,768.7,635.1z"/>
          <path class="st21" d="M771.6,633.4c0-2.4,1.7-4.1,4.1-4.1s4.1,1.8,4.1,4.1s-1.7,4.1-4.1,4.1S771.6,635.8,771.6,633.4z
         M778.4,633.4c0-1.7-1-2.9-2.7-2.9c-1.6,0-2.7,1.2-2.7,2.9c0,1.6,1,2.9,2.7,2.9C777.3,636.3,778.4,635.1,778.4,633.4z"/>
          <path class="st21" d="M781.5,635V634h2.9v1.1H781.5z"/>
          <path class="st21" d="M787.1,631.5c0,1.2,0.6,1.7,1.6,1.7c0.6,0,1.2-0.1,1.7-0.3v-3.5h1.4v8h-1.4v-3.3c-0.5,0.2-1.2,0.3-1.8,0.3
        c-2,0-2.9-1.2-2.9-2.9v-2.1h1.4V631.5z"/>
          <path class="st21" d="M794,637.4v-8h5.5v1.2h-4.1v2.1h4v1.2h-4v2.2h4.1v1.2H794z"/>
          <path class="st21" d="M801.6,637.4v-8h3.5c1.7,0,2.6,1.2,2.6,2.5s-0.9,2.5-2.6,2.5H803v3H801.6z M806.3,631.9
        c0-0.8-0.6-1.3-1.4-1.3H803v2.5h1.9C805.7,633.2,806.3,632.7,806.3,631.9z"/>
          <path class="st21"
                d="M814.2,637.4l-2.7-3.4l-0.7,0.8v2.6h-1.4v-8h1.4v3.8l3.1-3.8h1.7l-3.3,3.8l3.5,4.2H814.2z"/>
          <path class="st21" d="M817.5,637.4v-8h5.5v1.2h-4.1v2.1h4v1.2h-4v2.2h4.1v1.2H817.5z"/>
          <path class="st21" d="M824.5,633.4c0-2.5,1.8-4.1,4.2-4.1c1.6,0,2.6,0.8,3.2,1.8l-1.2,0.6c-0.4-0.7-1.2-1.2-2-1.2
        c-1.6,0-2.8,1.2-2.8,2.9c0,1.7,1.2,2.9,2.8,2.9c0.9,0,1.6-0.5,2-1.2l1.2,0.6c-0.6,1-1.6,1.8-3.2,1.8
        C826.4,637.5,824.5,635.9,824.5,633.4z"/>
          <path class="st21" d="M833.1,633.4c0-2.5,1.8-4.1,4.2-4.1c1.6,0,2.6,0.8,3.2,1.8l-1.2,0.6c-0.4-0.7-1.2-1.2-2-1.2
        c-1.6,0-2.8,1.2-2.8,2.9c0,1.7,1.2,2.9,2.8,2.9c0.9,0,1.6-0.5,2-1.2l1.2,0.6c-0.6,1-1.6,1.8-3.2,1.8
        C835,637.5,833.1,635.9,833.1,633.4z"/>
          <path class="st21" d="M847,637.4l-2.7-3.4l-0.7,0.8v2.6h-1.4v-8h1.4v3.8l3.1-3.8h1.7l-3.3,3.8l3.5,4.2H847z"/>
          <path class="st21"
                d="M856.3,637.4l-0.6-1.5h-3.7l-0.6,1.5h-1.6l3.1-8h1.8l3.1,8H856.3z M853.9,630.8l-1.4,3.8h2.9L853.9,630.8z"
          />
          <path class="st21" d="M859.3,637.4l2-3.1c-1-0.2-1.9-0.9-1.9-2.4c0-1.5,1-2.5,2.6-2.5h3.5v8h-1.4v-3h-1.4l-1.8,3H859.3z
         M864.1,633.2v-2.5h-1.9c-0.8,0-1.4,0.5-1.4,1.3s0.6,1.3,1.4,1.3H864.1z"/>
          <path class="st21" d="M744.7,657.4v-8h3.5c1.7,0,2.6,1.2,2.6,2.5s-0.9,2.5-2.6,2.5h-2.1v3H744.7z M749.4,651.9
        c0-0.8-0.6-1.3-1.4-1.3h-1.9v2.5h1.9C748.9,653.2,749.4,652.7,749.4,651.9z"/>
          <path class="st21" d="M752.6,657.4v-8h5.5v1.2H754v2.1h4v1.2h-4v2.2h4.1v1.2H752.6z"/>
          <path class="st21" d="M759.6,653.4c0-2.5,1.8-4.1,4.2-4.1c1.6,0,2.6,0.8,3.2,1.8l-1.2,0.6c-0.4-0.7-1.2-1.2-2-1.2
        c-1.6,0-2.8,1.2-2.8,2.9c0,1.7,1.2,2.9,2.8,2.9c0.9,0,1.6-0.5,2-1.2l1.2,0.6c-0.6,1-1.6,1.8-3.2,1.8
        C761.5,657.5,759.6,655.9,759.6,653.4z"/>
          <path class="st21" d="M774.3,657.4v-6.8h-4.2v6.8h-1.4v-8h7v8H774.3z"/>
          <path class="st21" d="M777.3,657l0.5-1.2c0.3,0.3,0.7,0.5,1,0.5c0.6,0,0.9-0.3,1.3-1l-3-5.9h1.6l2.2,4.7l2.2-4.7h1.6l-3.3,6.5
        c-0.5,1-1.1,1.6-2.5,1.6C778.3,657.5,777.6,657.3,777.3,657z"/>
          <path class="st21" d="M791.8,649.4v1.2h-4.1v1.8h2.1c1.7,0,2.6,1.2,2.6,2.5s-0.9,2.5-2.6,2.5h-3.5v-8H791.8z M787.7,653.6v2.5
        h1.9c0.8,0,1.4-0.5,1.4-1.3s-0.6-1.3-1.4-1.3H787.7z"/>
          <path class="st21"
                d="M793.7,656.3c0.9,0,1.3-0.4,1.6-2.8l0.5-4.1h5.4v8h-1.4v-6.8H797l-0.3,3c-0.4,3.4-1.4,3.9-3,3.9V656.3z"/>
          <path class="st21" d="M803.5,657.4v-8h1.4v5.6l4.1-5.6h1.4v8H809v-5.7l-4.2,5.7H803.5z"/>
          <path class="st21"
                d="M817.5,657.4l-2.7-3.4l-0.7,0.8v2.6h-1.4v-8h1.4v3.8l3.1-3.8h1.7l-3.3,3.8l3.5,4.2H817.5z"/>
          <path class="st21"
                d="M826.8,657.4l-0.6-1.5h-3.7l-0.6,1.5h-1.6l3.1-8h1.8l3.1,8H826.8z M824.4,650.8l-1.4,3.8h2.9L824.4,650.8z"
          />
    </g>
        <g class="st22">
      <path class="st21" d="M757.1,273.3c0-2.5,1.8-4.1,4.2-4.1c1.6,0,2.6,0.8,3.2,1.8l-1.2,0.6c-0.4-0.7-1.2-1.2-2-1.2
        c-1.6,0-2.8,1.2-2.8,2.9c0,1.7,1.2,2.9,2.8,2.9c0.9,0,1.6-0.5,2-1.2l1.2,0.6c-0.6,1-1.6,1.8-3.2,1.8
        C758.9,277.4,757.1,275.8,757.1,273.3z"/>
          <path class="st21" d="M768.3,277.3v-6.8h-2.4v-1.2h6.2v1.2h-2.4v6.8H768.3z"/>
          <path class="st21"
                d="M778.6,277.3l-0.6-1.5h-3.7l-0.6,1.5h-1.6l3.1-8h1.8l3.1,8H778.6z M776.1,270.7l-1.4,3.8h2.9L776.1,270.7z"
          />
          <path class="st21" d="M781.7,277.3v-8h3.9c1.5,0,2.3,0.9,2.3,2c0,1-0.6,1.7-1.4,1.8c0.9,0.1,1.5,1,1.5,2c0,1.2-0.8,2.2-2.3,2.2
        H781.7z M786.5,271.6c0-0.6-0.4-1-1.1-1h-2.2v2.1h2.2C786.1,272.6,786.5,272.2,786.5,271.6z M786.6,274.9c0-0.6-0.4-1.1-1.2-1.1
        h-2.3v2.2h2.3C786.2,276.1,786.6,275.6,786.6,274.9z"/>
          <path class="st21" d="M790,277.3v-8h3.5c1.7,0,2.6,1.2,2.6,2.5s-0.9,2.5-2.6,2.5h-2.1v3H790z M794.7,271.8c0-0.8-0.6-1.3-1.4-1.3
        h-1.9v2.5h1.9C794.1,273.1,794.7,272.6,794.7,271.8z"/>
          <path class="st21" d="M797.6,273.3c0-2.4,1.7-4.1,4.1-4.1s4.1,1.8,4.1,4.1s-1.7,4.1-4.1,4.1S797.6,275.7,797.6,273.3z
         M804.3,273.3c0-1.7-1-2.9-2.7-2.9c-1.6,0-2.7,1.2-2.7,2.9c0,1.6,1,2.9,2.7,2.9C803.3,276.2,804.3,274.9,804.3,273.3z"/>
          <path class="st21" d="M813.2,277.3v-6.8h-4.2v6.8h-1.4v-8h7v8H813.2z"/>
          <path class="st21" d="M816.5,273.3c0-2.4,1.7-4.1,4.1-4.1c2.4,0,4.1,1.8,4.1,4.1s-1.7,4.1-4.1,4.1
        C818.2,277.4,816.5,275.7,816.5,273.3z M823.2,273.3c0-1.7-1-2.9-2.7-2.9c-1.6,0-2.7,1.2-2.7,2.9c0,1.6,1,2.9,2.7,2.9
        C822.2,276.2,823.2,274.9,823.2,273.3z"/>
          <path class="st21"
                d="M825.7,276.2c0.9,0,1.3-0.4,1.6-2.8l0.5-4.1h5.4v8h-1.4v-6.8h-2.8l-0.3,3c-0.4,3.4-1.4,3.9-3,3.9V276.2z"/>
          <path class="st21" d="M836.9,269.3v3h2.1c1.7,0,2.6,1.2,2.6,2.5s-0.9,2.5-2.6,2.5h-3.5v-8H836.9z M836.9,273.5v2.5h1.9
        c0.8,0,1.4-0.5,1.4-1.3s-0.6-1.3-1.4-1.3H836.9z"/>
          <path class="st21" d="M843.1,273.3c0-2.5,1.8-4.1,4.2-4.1c1.6,0,2.6,0.8,3.2,1.8l-1.2,0.6c-0.4-0.7-1.2-1.2-2-1.2
        c-1.6,0-2.8,1.2-2.8,2.9c0,1.7,1.2,2.9,2.8,2.9c0.9,0,1.6-0.5,2-1.2l1.2,0.6c-0.6,1-1.6,1.8-3.2,1.8
        C844.9,277.4,843.1,275.8,843.1,273.3z"/>
          <path class="st21"
                d="M856.9,277.3l-2.7-3.4l-0.7,0.8v2.6h-1.4v-8h1.4v3.8l3.1-3.8h1.7l-3.3,3.8l3.5,4.2H856.9z"/>
          <path class="st21" d="M860.2,277.3v-8h1.4v5.6l4.1-5.6h1.4v8h-1.4v-5.7l-4.2,5.7H860.2z"/>
          <path class="st21" d="M869.4,277.3v-8h1.4v5.6l4.1-5.6h1.4v8h-1.4v-5.7l-4.2,5.7H869.4z M872.9,268.9c-0.9,0-1.6-0.4-2.1-1.1
        l0.6-0.5c0.3,0.5,0.9,0.8,1.5,0.8c0.6,0,1.2-0.3,1.5-0.8l0.6,0.5C874.5,268.5,873.7,268.9,872.9,268.9z"/>
          <path class="st21"
                d="M806.2,297.3l-2.7-3.4l-0.7,0.8v2.6h-1.4v-8h1.4v3.8l3.1-3.8h1.7l-3.3,3.8l3.5,4.2H806.2z"/>
          <path class="st21" d="M809.5,297.3v-8h3.5c1.7,0,2.6,1.2,2.6,2.5s-0.9,2.5-2.6,2.5h-2.1v3H809.5z M814.1,291.8
        c0-0.8-0.6-1.3-1.4-1.3h-1.9v2.5h1.9C813.6,293.1,814.1,292.6,814.1,291.8z"/>
          <path class="st21"
                d="M822.4,297.3l-0.6-1.5h-3.7l-0.6,1.5h-1.6l3.1-8h1.8l3.1,8H822.4z M819.9,290.7l-1.4,3.8h2.9L819.9,290.7z"
          />
          <path class="st21" d="M825.5,297.3v-8h1.4v5.6l4.1-5.6h1.4v8H831v-5.7l-4.2,5.7H825.5z M828.9,288.9c-0.9,0-1.6-0.4-2.1-1.1
        l0.6-0.5c0.3,0.5,0.9,0.8,1.5,0.8c0.6,0,1.2-0.3,1.5-0.8l0.6,0.5C830.6,288.5,829.8,288.9,828.9,288.9z"/>
    </g>
        <g class="st22">
      <path class="st21" d="M674.9,60.4v-8h3.5c1.7,0,2.6,1.2,2.6,2.5s-0.9,2.5-2.6,2.5h-2.1v3H674.9z M679.6,54.9
        c0-0.8-0.6-1.3-1.4-1.3h-1.9v2.5h1.9C679,56.2,679.6,55.7,679.6,54.9z"/>
          <path class="st21" d="M682.5,56.4c0-2.4,1.7-4.1,4.1-4.1s4.1,1.8,4.1,4.1s-1.7,4.1-4.1,4.1S682.5,58.8,682.5,56.4z M689.2,56.4
        c0-1.7-1-2.9-2.7-2.9c-1.6,0-2.7,1.2-2.7,2.9c0,1.6,1,2.9,2.7,2.9C688.2,59.3,689.2,58.1,689.2,56.4z"/>
          <path class="st21" d="M692.3,56.4c0-2.5,1.8-4.1,4.2-4.1c1.6,0,2.6,0.8,3.2,1.8l-1.2,0.6c-0.4-0.7-1.2-1.2-2-1.2
        c-1.6,0-2.8,1.2-2.8,2.9c0,1.7,1.2,2.9,2.8,2.9c0.9,0,1.6-0.5,2-1.2l1.2,0.6c-0.6,1-1.6,1.8-3.2,1.8
        C694.1,60.5,692.3,58.9,692.3,56.4z"/>
          <path class="st21" d="M703.4,60.4v-6.8H701v-1.2h6.2v1.2h-2.4v6.8H703.4z"/>
          <path class="st21" d="M708.4,56.4c0-2.4,1.7-4.1,4.1-4.1s4.1,1.8,4.1,4.1s-1.7,4.1-4.1,4.1S708.4,58.8,708.4,56.4z M715.1,56.4
        c0-1.7-1-2.9-2.7-2.9c-1.6,0-2.7,1.2-2.7,2.9c0,1.6,1,2.9,2.7,2.9C714,59.3,715.1,58.1,715.1,56.4z"/>
          <path class="st21" d="M718.4,60.4v-8h3.9c1.5,0,2.3,0.9,2.3,2c0,1-0.6,1.7-1.4,1.8c0.9,0.1,1.5,1,1.5,2c0,1.2-0.8,2.2-2.3,2.2
        H718.4z M723.2,54.7c0-0.6-0.4-1-1.1-1h-2.2v2.1h2.2C722.8,55.7,723.2,55.3,723.2,54.7z M723.3,58.1c0-0.6-0.4-1.1-1.2-1.1h-2.3
        v2.2h2.3C722.9,59.2,723.3,58.7,723.3,58.1z"/>
          <path class="st21" d="M726.3,56.4c0-2.5,1.8-4.1,4.2-4.1c1.6,0,2.6,0.8,3.2,1.8l-1.2,0.6c-0.4-0.7-1.2-1.2-2-1.2
        c-1.6,0-2.8,1.2-2.8,2.9c0,1.7,1.2,2.9,2.8,2.9c0.9,0,1.6-0.5,2-1.2l1.2,0.6c-0.6,1-1.6,1.8-3.2,1.8
        C728.1,60.5,726.3,58.9,726.3,56.4z"/>
          <path class="st21" d="M740.1,60.4l-2.7-3.4l-0.7,0.8v2.6h-1.4v-8h1.4v3.8l3.1-3.8h1.7l-3.3,3.8l3.5,4.2H740.1z"/>
          <path class="st21"
                d="M749.5,60.4l-0.6-1.5h-3.7l-0.6,1.5H743l3.1-8h1.8l3.1,8H749.5z M747,53.8l-1.4,3.8h2.9L747,53.8z"/>
          <path class="st21" d="M752.4,60.4l2-3.1c-1-0.2-1.9-0.9-1.9-2.4c0-1.5,1-2.5,2.6-2.5h3.5v8h-1.4v-3h-1.4l-1.8,3H752.4z
         M757.2,56.2v-2.5h-1.9c-0.8,0-1.4,0.5-1.4,1.3s0.6,1.3,1.4,1.3H757.2z"/>
          <path class="st21" d="M687.8,76.4c0-2.4,1.7-4.1,4.1-4.1s4.1,1.8,4.1,4.1s-1.7,4.1-4.1,4.1S687.8,78.8,687.8,76.4z M694.5,76.4
        c0-1.7-1-2.9-2.7-2.9c-1.6,0-2.7,1.2-2.7,2.9c0,1.6,1,2.9,2.7,2.9C693.5,79.3,694.5,78.1,694.5,76.4z"/>
          <path class="st21" d="M703.3,72.4v1.2h-4.1v1.8h2.1c1.7,0,2.6,1.2,2.6,2.5s-0.9,2.5-2.6,2.5h-3.5v-8H703.3z M699.2,76.6v2.5h1.9
        c0.8,0,1.4-0.5,1.4-1.3s-0.6-1.3-1.4-1.3H699.2z"/>
          <path class="st21"
                d="M705.2,79.3c0.9,0,1.3-0.4,1.6-2.8l0.5-4.1h5.4v8h-1.4v-6.8h-2.8l-0.3,3c-0.4,3.4-1.4,3.9-3,3.9V79.3z"/>
          <path class="st21"
                d="M720.7,80.4l-0.6-1.5h-3.7l-0.6,1.5h-1.6l3.1-8h1.8l3.1,8H720.7z M718.3,73.8l-1.4,3.8h2.9L718.3,73.8z"/>
          <path class="st21" d="M723.1,76.4c0-2.5,1.8-4.1,4.2-4.1c1.6,0,2.6,0.8,3.2,1.8l-1.2,0.6c-0.4-0.7-1.2-1.2-2-1.2
        c-1.6,0-2.8,1.2-2.8,2.9c0,1.7,1.2,2.9,2.8,2.9c0.9,0,1.6-0.5,2-1.2l1.2,0.6c-0.6,1-1.6,1.8-3.2,1.8
        C724.9,80.5,723.1,78.9,723.1,76.4z"/>
          <path class="st21" d="M734.2,80.4v-6.8h-2.4v-1.2h6.2v1.2h-2.4v6.8H734.2z"/>
          <path class="st21" d="M741.2,72.4v3h2.1c1.7,0,2.6,1.2,2.6,2.5s-0.9,2.5-2.6,2.5h-3.5v-8H741.2z M741.2,76.6v2.5h1.9
        c0.8,0,1.4-0.5,1.4-1.3s-0.6-1.3-1.4-1.3H741.2z"/>
    </g>
        <g class="st22">
      <path class="st21"
            d="M119.2,169.3l-0.6-1.5h-3.7l-0.6,1.5h-1.6l3.1-8h1.8l3.1,8H119.2z M116.8,162.7l-1.4,3.8h2.9L116.8,162.7z"
      />
          <path class="st21" d="M121.8,168.1l0.8-0.9c0.5,0.6,1.5,1,2.4,1c1.1,0,1.8-0.5,1.8-1.2c0-0.8-0.7-1.1-1.9-1.1h-1.6v-1.2h1.6
        c1,0,1.8-0.3,1.8-1.1c0-0.7-0.8-1.1-1.8-1.1c-0.8,0-1.6,0.3-2.2,0.9l-0.7-0.9c0.6-0.7,1.7-1.3,3.1-1.3c1.7,0,3,0.8,3,2.2
        c0,1.1-0.9,1.7-1.7,1.8c0.8,0.1,1.9,0.7,1.9,1.9c0,1.4-1.3,2.3-3.2,2.3C123.6,169.4,122.4,168.9,121.8,168.1z"/>
          <path class="st21" d="M129.7,165.3c0-2.4,1.7-4.1,4.1-4.1c2.4,0,4.1,1.8,4.1,4.1s-1.7,4.1-4.1,4.1
        C131.4,169.4,129.7,167.7,129.7,165.3z M136.4,165.3c0-1.7-1-2.9-2.7-2.9c-1.6,0-2.7,1.2-2.7,2.9c0,1.6,1,2.9,2.7,2.9
        C135.4,168.2,136.4,166.9,136.4,165.3z"/>
          <path class="st21" d="M139.7,169.3v-8h3.9c1.5,0,2.3,0.9,2.3,2c0,1-0.6,1.7-1.4,1.8c0.9,0.1,1.5,1,1.5,2c0,1.2-0.8,2.2-2.3,2.2
        H139.7z M144.5,163.6c0-0.6-0.4-1-1.1-1h-2.2v2.1h2.2C144.1,164.6,144.5,164.2,144.5,163.6z M144.7,166.9c0-0.6-0.4-1.1-1.2-1.1
        h-2.3v2.2h2.3C144.2,168.1,144.7,167.6,144.7,166.9z"/>
          <path class="st21" d="M147.6,165.3c0-2.5,1.8-4.1,4.2-4.1c1.6,0,2.6,0.8,3.2,1.8l-1.2,0.6c-0.4-0.7-1.2-1.2-2-1.2
        c-1.6,0-2.8,1.2-2.8,2.9c0,1.7,1.2,2.9,2.8,2.9c0.9,0,1.6-0.5,2-1.2l1.2,0.6c-0.6,1-1.6,1.8-3.2,1.8
        C149.5,169.4,147.6,167.8,147.6,165.3z"/>
          <path class="st21"
                d="M161.5,169.3l-2.7-3.4l-0.7,0.8v2.6h-1.4v-8h1.4v3.8l3.1-3.8h1.7l-3.3,3.8l3.5,4.2H161.5z"/>
          <path class="st21" d="M163.8,165.3c0-2.4,1.7-4.1,4.1-4.1c2.4,0,4.1,1.8,4.1,4.1s-1.7,4.1-4.1,4.1
        C165.5,169.4,163.8,167.7,163.8,165.3z M170.5,165.3c0-1.7-1-2.9-2.7-2.9c-1.6,0-2.7,1.2-2.7,2.9c0,1.6,1,2.9,2.7,2.9
        C169.5,168.2,170.5,166.9,170.5,165.3z"/>
          <path class="st21" d="M173.8,169.3v-8h5.5v1.2h-4.1v2.1h4v1.2h-4v2.2h4.1v1.2H173.8z"/>
          <path class="st21" d="M136.6,189.3v-5.9l-2.5,5.9h-0.6l-2.4-5.9v5.9h-1.4v-8h2l2.2,5.3l2.2-5.3h2v8H136.6z"/>
          <path class="st21" d="M139.8,185.3c0-2.4,1.7-4.1,4.1-4.1c2.4,0,4.1,1.8,4.1,4.1s-1.7,4.1-4.1,4.1
        C141.5,189.4,139.8,187.7,139.8,185.3z M146.6,185.3c0-1.7-1-2.9-2.7-2.9c-1.6,0-2.7,1.2-2.7,2.9c0,1.6,1,2.9,2.7,2.9
        C145.5,188.2,146.6,186.9,146.6,185.3z"/>
          <path class="st21" d="M149.9,189.3v-8h3.5c1.7,0,2.6,1.2,2.6,2.5s-0.9,2.5-2.6,2.5h-2.1v3H149.9z M154.6,183.8
        c0-0.8-0.6-1.3-1.4-1.3h-1.9v2.5h1.9C154,185.1,154.6,184.6,154.6,183.8z"/>
          <path class="st21" d="M157.7,189.3v-8h5.5v1.2h-4.1v2.1h4v1.2h-4v2.2h4.1v1.2H157.7z"/>
    </g>
      </g>
      <g id="cities-en" class="st23">
    <g id="krasnodar-en_1_" class="st24">
      <path class="st21" d="M386.3,377.6l-2.2-2.9l-0.6,0.7v2.3h-1.6v-7.3h1.6v3.3l2.6-3.3h1.9l-2.9,3.4l3.1,3.9H386.3z"/>
      <path class="st21" d="M388.8,377.6v-5.3h1.4v0.7c0.4-0.5,1-0.9,1.7-0.9v1.4c-0.1,0-0.2,0-0.4,0c-0.5,0-1.1,0.3-1.3,0.6v3.5H388.8
        z"/>
      <path class="st21" d="M395.8,377.6v-0.6c-0.4,0.4-1,0.7-1.7,0.7c-0.8,0-1.8-0.6-1.8-1.8c0-1.2,1-1.7,1.8-1.7
        c0.7,0,1.3,0.2,1.7,0.6v-0.7c0-0.5-0.5-0.9-1.2-0.9c-0.6,0-1.1,0.2-1.6,0.6l-0.5-0.9c0.6-0.6,1.5-0.8,2.3-0.8
        c1.2,0,2.3,0.5,2.3,2v3.4H395.8z M395.8,376.3v-0.7c-0.2-0.3-0.7-0.5-1.1-0.5c-0.5,0-1,0.3-1,0.8c0,0.5,0.5,0.8,1,0.8
        C395.1,376.8,395.6,376.6,395.8,376.3z"/>
      <path class="st21" d="M398,376.9l0.6-1c0.4,0.4,1.2,0.7,1.8,0.7c0.6,0,0.9-0.2,0.9-0.6c0-0.9-3.2-0.2-3.2-2.3
        c0-0.9,0.8-1.7,2.2-1.7c0.9,0,1.6,0.3,2.1,0.7l-0.6,1c-0.3-0.3-0.9-0.6-1.6-0.6c-0.5,0-0.8,0.2-0.8,0.5c0,0.8,3.1,0.1,3.1,2.3
        c0,1-0.8,1.7-2.3,1.7C399.4,377.8,398.5,377.4,398,376.9z"/>
      <path class="st21" d="M407.2,377.6v-3.2c0-0.7-0.4-1-1-1c-0.5,0-1,0.3-1.2,0.6v3.6h-1.4v-5.3h1.4v0.7c0.3-0.4,1-0.8,1.9-0.8
        c1.2,0,1.7,0.7,1.7,1.7v3.8H407.2z"/>
      <path class="st21" d="M409.4,375c0-1.5,1.1-2.8,2.8-2.8c1.8,0,2.8,1.3,2.8,2.8c0,1.5-1.1,2.8-2.8,2.8
        C410.5,377.8,409.4,376.5,409.4,375z M413.6,375c0-0.8-0.5-1.5-1.4-1.5c-0.9,0-1.4,0.7-1.4,1.5c0,0.8,0.5,1.6,1.4,1.6
        C413.1,376.5,413.6,375.8,413.6,375z"/>
      <path class="st21" d="M419.7,377.6v-0.7c-0.4,0.5-1,0.8-1.6,0.8c-1.3,0-2.4-1-2.4-2.8c0-1.7,1-2.8,2.4-2.8c0.6,0,1.2,0.3,1.6,0.8
        v-2.7h1.4v7.3H419.7z M419.7,375.9V374c-0.2-0.4-0.7-0.6-1.2-0.6c-0.8,0-1.3,0.6-1.3,1.6c0,0.9,0.5,1.5,1.3,1.5
        C418.9,376.5,419.4,376.3,419.7,375.9z"/>
      <path class="st21" d="M425.5,377.6v-0.6c-0.4,0.4-1,0.7-1.7,0.7c-0.8,0-1.8-0.6-1.8-1.8c0-1.2,1-1.7,1.8-1.7
        c0.7,0,1.3,0.2,1.7,0.6v-0.7c0-0.5-0.5-0.9-1.2-0.9c-0.6,0-1.1,0.2-1.6,0.6l-0.5-0.9c0.6-0.6,1.5-0.8,2.3-0.8
        c1.2,0,2.3,0.5,2.3,2v3.4H425.5z M425.5,376.3v-0.7c-0.2-0.3-0.7-0.5-1.1-0.5c-0.6,0-1,0.3-1,0.8c0,0.5,0.5,0.8,1,0.8
        C424.9,376.8,425.3,376.6,425.5,376.3z"/>
      <path class="st21" d="M428.2,377.6v-5.3h1.4v0.7c0.4-0.5,1-0.9,1.7-0.9v1.4c-0.1,0-0.2,0-0.4,0c-0.5,0-1.1,0.3-1.3,0.6v3.5H428.2
        z"/>
    </g>
        <g id="novorossiysk-en_1_" class="st24">
      <path class="st21" d="M150.7,440.2l-3.5-4.8v4.8h-1.6v-7.3h1.6l3.4,4.6v-4.6h1.6v7.3H150.7z"/>
          <path class="st21" d="M153.2,437.5c0-1.5,1.1-2.8,2.8-2.8c1.8,0,2.8,1.3,2.8,2.8c0,1.5-1.1,2.8-2.8,2.8
        C154.3,440.3,153.2,439,153.2,437.5z M157.4,437.5c0-0.8-0.5-1.5-1.4-1.5c-0.9,0-1.4,0.7-1.4,1.5c0,0.8,0.5,1.6,1.4,1.6
        C156.9,439.1,157.4,438.4,157.4,437.5z"/>
          <path class="st21" d="M161,440.2l-2.1-5.3h1.5l1.4,3.7l1.4-3.7h1.5l-2.1,5.3H161z"/>
          <path class="st21" d="M164.6,437.5c0-1.5,1.1-2.8,2.8-2.8c1.8,0,2.8,1.3,2.8,2.8c0,1.5-1.1,2.8-2.8,2.8
        C165.7,440.3,164.6,439,164.6,437.5z M168.8,437.5c0-0.8-0.5-1.5-1.4-1.5s-1.4,0.7-1.4,1.5c0,0.8,0.5,1.6,1.4,1.6
        S168.8,438.4,168.8,437.5z"/>
          <path class="st21" d="M171.2,440.2v-5.3h1.4v0.7c0.4-0.5,1-0.9,1.7-0.9v1.4c-0.1,0-0.2,0-0.4,0c-0.5,0-1.1,0.3-1.3,0.6v3.5H171.2
        z"/>
          <path class="st21" d="M174.6,437.5c0-1.5,1.1-2.8,2.8-2.8c1.8,0,2.8,1.3,2.8,2.8c0,1.5-1.1,2.8-2.8,2.8
        C175.7,440.3,174.6,439,174.6,437.5z M178.8,437.5c0-0.8-0.5-1.5-1.4-1.5c-0.9,0-1.4,0.7-1.4,1.5c0,0.8,0.5,1.6,1.4,1.6
        C178.3,439.1,178.8,438.4,178.8,437.5z"/>
          <path class="st21" d="M180.7,439.5l0.6-1c0.4,0.4,1.2,0.7,1.8,0.7c0.6,0,0.9-0.2,0.9-0.6c0-0.9-3.2-0.2-3.2-2.3
        c0-0.9,0.8-1.7,2.2-1.7c0.9,0,1.6,0.3,2.1,0.7l-0.6,1c-0.3-0.3-0.9-0.6-1.6-0.6c-0.5,0-0.8,0.2-0.8,0.5c0,0.8,3.1,0.1,3.1,2.3
        c0,1-0.8,1.7-2.3,1.7C182.1,440.3,181.2,440,180.7,439.5z"/>
          <path class="st21" d="M185.8,439.5l0.6-1c0.4,0.4,1.2,0.7,1.8,0.7c0.6,0,0.9-0.2,0.9-0.6c0-0.9-3.2-0.2-3.2-2.3
        c0-0.9,0.8-1.7,2.2-1.7c0.9,0,1.6,0.3,2.1,0.7l-0.6,1c-0.3-0.3-0.9-0.6-1.6-0.6c-0.5,0-0.8,0.2-0.8,0.5c0,0.8,3.1,0.1,3.1,2.3
        c0,1-0.8,1.7-2.3,1.7C187.2,440.3,186.3,440,185.8,439.5z"/>
          <path class="st21" d="M191.2,433.5c0-0.5,0.4-0.8,0.8-0.8c0.5,0,0.8,0.4,0.8,0.8c0,0.5-0.4,0.8-0.8,0.8
        C191.6,434.3,191.2,433.9,191.2,433.5z M191.3,440.2v-5.3h1.4v5.3H191.3z"/>
          <path class="st21" d="M194.1,441c0.1,0.1,0.3,0.1,0.4,0.1c0.4,0,0.6-0.1,0.7-0.4l0.2-0.5l-2.2-5.4h1.5l1.4,3.7l1.4-3.7h1.5
        l-2.5,6.2c-0.4,1-1.1,1.3-2,1.3c-0.2,0-0.5,0-0.7-0.1L194.1,441z"/>
          <path class="st21" d="M199.1,439.5l0.6-1c0.4,0.4,1.2,0.7,1.8,0.7c0.6,0,0.9-0.2,0.9-0.6c0-0.9-3.2-0.2-3.2-2.3
        c0-0.9,0.8-1.7,2.2-1.7c0.9,0,1.6,0.3,2.1,0.7l-0.6,1c-0.3-0.3-0.9-0.6-1.6-0.6c-0.5,0-0.8,0.2-0.8,0.5c0,0.8,3.1,0.1,3.1,2.3
        c0,1-0.8,1.7-2.3,1.7C200.5,440.3,199.7,440,199.1,439.5z"/>
          <path class="st21" d="M208.1,440.2l-1.4-2l-0.6,0.7v1.4h-1.4v-7.3h1.4v4.4l2-2.4h1.7l-2.1,2.4l2.2,2.9H208.1z"/>
    </g>
        <g id="sochi-en_1_" class="st24">
      <path class="st21" d="M524.4,683l0.8-1.2c0.5,0.5,1.3,1,2.3,1c0.9,0,1.3-0.4,1.3-0.8c0-1.3-4.2-0.4-4.2-3.2
        c0-1.2,1.1-2.2,2.8-2.2c1.2,0,2.1,0.4,2.9,1l-0.9,1.1c-0.6-0.6-1.4-0.8-2.1-0.8c-0.7,0-1,0.3-1,0.7c0,1.2,4.2,0.4,4.2,3.1
        c0,1.3-1,2.4-2.9,2.4C526.1,684.1,525.1,683.7,524.4,683z"/>
          <path class="st21" d="M531.1,681.3c0-1.5,1.1-2.8,2.8-2.8c1.8,0,2.8,1.3,2.8,2.8c0,1.5-1.1,2.8-2.8,2.8
        C532.1,684.1,531.1,682.9,531.1,681.3z M535.2,681.3c0-0.8-0.5-1.5-1.4-1.5s-1.4,0.7-1.4,1.5c0,0.8,0.5,1.6,1.4,1.6
        S535.2,682.2,535.2,681.3z"/>
          <path class="st21" d="M537.3,681.3c0-1.6,1.2-2.8,2.8-2.8c1.1,0,1.7,0.5,2.1,1l-0.9,0.9c-0.3-0.4-0.6-0.6-1.1-0.6
        c-0.8,0-1.4,0.6-1.4,1.5s0.6,1.6,1.4,1.6c0.5,0,0.9-0.2,1.1-0.6l0.9,0.8c-0.4,0.5-1,1-2.1,1C538.5,684.1,537.3,683,537.3,681.3z"
          />
          <path class="st21" d="M546.5,684v-3.2c0-0.7-0.4-1-1-1c-0.5,0-1,0.3-1.2,0.6v3.6h-1.4v-7.3h1.4v2.7c0.3-0.4,1-0.8,1.9-0.8
        c1.2,0,1.7,0.6,1.7,1.7v3.8H546.5z"/>
          <path class="st21" d="M549,677.3c0-0.5,0.4-0.8,0.8-0.8c0.5,0,0.8,0.4,0.8,0.8s-0.4,0.8-0.8,0.8C549.4,678.1,549,677.7,549,677.3
        z M549.1,684v-5.3h1.4v5.3H549.1z"/>
    </g>
  </g>
      <g id="cities" class="st22">
    <g id="krasnodar_1_">
      <path class="st21"
            d="M383.6,377.6l-2.4-3l-0.6,0.8v2.3h-1.2v-7.2h1.2v3.5l2.9-3.5h1.6l-3,3.4l3.2,3.8L383.6,377.6L383.6,377.6z"
      />
      <path class="st21" d="M387.1,376.9v2.7H386v-7.2h1.2v0.7c0.4-0.5,1-0.9,1.7-0.9c1.4,0,2.3,1,2.3,2.7s-0.9,2.8-2.3,2.8
        C388.2,377.7,387.5,377.4,387.1,376.9z M389.9,374.9c0-1-0.5-1.7-1.5-1.7c-0.5,0-1.1,0.3-1.3,0.7v2.1c0.2,0.4,0.9,0.7,1.3,0.7
        C389.4,376.7,389.9,375.9,389.9,374.9z"/>
      <path class="st21" d="M395.5,377.6V377c-0.4,0.5-1,0.7-1.7,0.7c-0.9,0-1.9-0.5-1.9-1.7s0.9-1.7,1.9-1.7c0.7,0,1.3,0.2,1.7,0.7
        v-0.8c0-0.6-0.5-1-1.2-1c-0.6,0-1.1,0.2-1.6,0.7l-0.5-0.8c0.6-0.5,1.4-0.9,2.2-0.9c1.2,0,2.2,0.5,2.2,1.9v3.5L395.5,377.6
        L395.5,377.6z M395.5,376.4v-0.8c-0.2-0.4-0.8-0.5-1.2-0.5c-0.6,0-1.1,0.4-1.1,0.9s0.5,0.9,1.1,0.9
        C394.8,376.9,395.2,376.7,395.5,376.4z"/>
      <path class="st21" d="M397.8,374.9c0-1.6,1.1-2.7,2.7-2.7c1,0,1.6,0.5,2,0.9l-0.8,0.7c-0.3-0.4-0.7-0.6-1.2-0.6
        c-0.9,0-1.6,0.7-1.6,1.7s0.6,1.8,1.6,1.8c0.5,0,0.9-0.2,1.2-0.6l0.8,0.7c-0.4,0.5-1,0.9-2,0.9
        C398.9,377.7,397.8,376.6,397.8,374.9z"/>
      <path class="st21" d="M403.5,377.6v-5.3h1.2v2.1h2.4v-2.1h1.2v5.3h-1.2v-2.2h-2.4v2.2H403.5z"/>
      <path class="st21" d="M409.4,374.9c0-1.5,1-2.7,2.7-2.7s2.7,1.2,2.7,2.7s-1,2.8-2.7,2.8S409.4,376.5,409.4,374.9z M413.5,374.9
        c0-0.9-0.5-1.7-1.6-1.7c-0.9,0-1.5,0.9-1.5,1.7c0,0.9,0.5,1.8,1.5,1.8C413,376.7,413.5,375.9,413.5,374.9z"/>
      <path class="st21" d="M415.3,376.6c0.5-0.1,0.7-0.4,0.9-1.8l0.3-2.6h4v4.3h0.5v2.3h-1.2v-1.3h-3.6v1.3H415L415.3,376.6
        L415.3,376.6z M417.3,375c-0.1,0.7-0.3,1.2-0.7,1.6h2.8v-3.3h-1.9L417.3,375z"/>
      <path class="st21" d="M425.3,377.6V377c-0.4,0.5-1,0.7-1.7,0.7c-0.9,0-1.9-0.5-1.9-1.7s0.9-1.7,1.9-1.7c0.7,0,1.3,0.2,1.7,0.7
        v-0.8c0-0.6-0.5-1-1.2-1c-0.6,0-1.1,0.2-1.6,0.7l-0.5-0.8c0.6-0.5,1.4-0.9,2.2-0.9c1.2,0,2.2,0.5,2.2,1.9v3.5L425.3,377.6
        L425.3,377.6z M425.3,376.4v-0.8c-0.2-0.4-0.8-0.5-1.2-0.5c-0.6,0-1.1,0.4-1.1,0.9s0.5,0.9,1.1,0.9
        C424.6,376.9,425,376.7,425.3,376.4z"/>
      <path class="st21" d="M429.1,376.9v2.7h-1.2v-7.2h1.2v0.7c0.4-0.5,1-0.9,1.7-0.9c1.4,0,2.3,1,2.3,2.7s-0.9,2.8-2.3,2.8
        C430.1,377.7,429.5,377.4,429.1,376.9z M431.9,374.9c0-1-0.5-1.7-1.5-1.7c-0.5,0-1.1,0.3-1.3,0.7v2.1c0.2,0.4,0.9,0.7,1.3,0.7
        C431.3,376.7,431.9,375.9,431.9,374.9z"/>
    </g>
        <g id="novorossiysk_1_">
      <path class="st21" d="M141.7,439.9v-3.2h-3.8v3.2h-1.3v-7.3h1.3v3h3.8v-3h1.3v7.3H141.7z"/>
          <path class="st21" d="M144.2,437.3c0-1.5,1-2.8,2.7-2.8s2.7,1.3,2.7,2.8s-1,2.8-2.7,2.8C145.2,440.1,144.2,438.8,144.2,437.3z
         M148.5,437.3c0-0.9-0.5-1.8-1.5-1.8s-1.5,0.8-1.5,1.8c0,0.9,0.6,1.8,1.5,1.8C147.9,439.1,148.5,438.2,148.5,437.3z"/>
          <path class="st21" d="M150.8,439.9v-5.3h3.2c1,0,1.6,0.6,1.6,1.4c0,0.6-0.4,1.1-0.9,1.2c0.6,0.1,1,0.7,1,1.3
        c0,0.8-0.6,1.4-1.6,1.4H150.8L150.8,439.9z M154.4,436.2c0-0.3-0.2-0.6-0.6-0.6H152v1.1h1.8C154.2,436.7,154.4,436.5,154.4,436.2
        z M154.5,438.3c0-0.3-0.2-0.6-0.7-0.6H152v1.2h1.9C154.2,438.9,154.5,438.7,154.5,438.3z"/>
          <path class="st21" d="M156.8,437.3c0-1.5,1-2.8,2.7-2.8s2.7,1.3,2.7,2.8s-1,2.8-2.7,2.8C157.8,440.1,156.8,438.8,156.8,437.3z
         M161.1,437.3c0-0.9-0.5-1.8-1.5-1.8s-1.5,0.8-1.5,1.8c0,0.9,0.6,1.8,1.5,1.8C160.5,439.1,161.1,438.2,161.1,437.3z"/>
          <path class="st21" d="M164.6,439.2v2.8h-1.2v-7.3h1.2v0.7c0.4-0.5,1-0.9,1.7-0.9c1.4,0,2.4,1,2.4,2.8s-1,2.8-2.4,2.8
        C165.6,440.1,165,439.8,164.6,439.2z M167.4,437.3c0-1-0.6-1.8-1.5-1.8c-0.5,0-1.1,0.3-1.4,0.7v2.1c0.3,0.4,0.8,0.7,1.4,0.7
        C166.9,439.1,167.4,438.3,167.4,437.3z"/>
          <path class="st21" d="M169.5,437.3c0-1.5,1-2.8,2.7-2.8s2.7,1.3,2.7,2.8s-1,2.8-2.7,2.8C170.5,440.1,169.5,438.8,169.5,437.3z
         M173.7,437.3c0-0.9-0.5-1.8-1.5-1.8s-1.5,0.8-1.5,1.8c0,0.9,0.6,1.8,1.5,1.8C173.2,439.1,173.7,438.2,173.7,437.3z"/>
          <path class="st21" d="M175.8,437.3c0-1.6,1.1-2.8,2.8-2.8c1,0,1.7,0.4,2,0.9l-0.8,0.7c-0.3-0.4-0.7-0.6-1.2-0.6
        c-1,0-1.6,0.7-1.6,1.8s0.6,1.8,1.6,1.8c0.5,0,0.9-0.2,1.2-0.6l0.8,0.7c-0.4,0.5-1,0.9-2,0.9C176.9,440.1,175.8,438.9,175.8,437.3
        z"/>
          <path class="st21" d="M181.2,437.3c0-1.6,1.1-2.8,2.8-2.8c1,0,1.7,0.4,2,0.9l-0.8,0.7c-0.3-0.4-0.7-0.6-1.2-0.6
        c-1,0-1.6,0.7-1.6,1.8s0.6,1.8,1.6,1.8c0.5,0,0.9-0.2,1.2-0.6l0.8,0.7c-0.4,0.5-1,0.9-2,0.9C182.4,440.1,181.2,438.9,181.2,437.3
        z"/>
          <path class="st21" d="M187,439.9v-5.3h1.2v3.6l2.4-3.6h1.2v5.3h-1.2v-3.7l-2.5,3.7H187z"/>
          <path class="st21" d="M193.3,439.9v-5.3h1.2v3.6l2.4-3.6h1.2v5.3h-1.2v-3.7l-2.5,3.7H193.3z M195.6,434.1c-0.8,0-1.5-0.4-1.9-1
        l0.5-0.4c0.3,0.4,0.8,0.7,1.4,0.7c0.6,0,1.1-0.3,1.4-0.7l0.5,0.4C197.1,433.7,196.4,434.1,195.6,434.1z"/>
          <path class="st21" d="M199.2,437.3c0-1.6,1.1-2.8,2.8-2.8c1,0,1.7,0.4,2,0.9l-0.8,0.7c-0.3-0.4-0.7-0.6-1.2-0.6
        c-1,0-1.6,0.7-1.6,1.8s0.6,1.8,1.6,1.8c0.5,0,0.9-0.2,1.2-0.6l0.8,0.7c-0.4,0.5-1,0.9-2,0.9C200.3,440.1,199.2,438.9,199.2,437.3
        z"/>
          <path class="st21" d="M208.5,439.9l-1.6-2.2l-0.7,0.8v1.4H205v-5.3h1.2v2.6l2.3-2.6h1.4l-2.2,2.4l2.3,2.9L208.5,439.9
        L208.5,439.9z"/>
    </g>
        <g id="sochi_1_">
      <path class="st21" d="M524.2,680.3c0-2.3,1.7-3.8,3.9-3.8c1.5,0,2.4,0.8,2.9,1.7l-1.1,0.6c-0.4-0.6-1.1-1.1-1.8-1.1
        c-1.5,0-2.5,1.1-2.5,2.7c0,1.5,1.1,2.7,2.5,2.7c0.8,0,1.5-0.5,1.8-1.1l1.1,0.6c-0.6,0.9-1.5,1.6-2.9,1.6
        C525.9,684.1,524.2,682.6,524.2,680.3z"/>
          <path class="st21" d="M531.7,681.3c0-1.5,1-2.8,2.7-2.8s2.7,1.3,2.7,2.8s-1,2.8-2.7,2.8S531.7,682.8,531.7,681.3z M535.9,681.3
        c0-0.9-0.5-1.8-1.5-1.8s-1.5,0.8-1.5,1.8c0,0.9,0.5,1.8,1.5,1.8S535.9,682.3,535.9,681.3z"/>
          <path class="st21" d="M539.3,678.7v1.4c0,0.8,0.4,1.1,1.3,1.1c0.5,0,0.9-0.1,1.2-0.2v-2.3h1.2v5.3h-1.2v-2.1
        c-0.4,0.2-0.9,0.3-1.5,0.3c-1.5,0-2.1-0.6-2.1-1.7v-1.8H539.3L539.3,678.7z"/>
          <path class="st21" d="M544.4,684v-5.3h1.2v3.6l2.4-3.6h1.2v5.3H548v-3.7l-2.5,3.7H544.4z"/>
    </g>
  </g>
      <g id="cities-markers" class="st22">
        <circle id="Krasnodar_2_" class="st21" cx="398.7" cy="365.4" r="2.5"/>
        <circle id="Novorossiisk_2_" class="st21" cx="207" cy="427.4" r="2.5"/>
        <circle id="Sochi_2_" class="st21" cx="536.3" cy="691.5" r="2.5"/>
      </g>
    </g>
    <g id="zone-names-en" class="st11">
      <g id="Northern" class="st24">
        <path class="st1" d="M418.8,122l-4.9-6.7v6.7h-1.6v-9.3h1.7l4.8,6.5v-6.5h1.6v9.3H418.8z"/>
        <path class="st1" d="M422,118.6c0-1.9,1.3-3.5,3.5-3.5c2.2,0,3.5,1.6,3.5,3.5c0,1.9-1.3,3.6-3.5,3.6
          C423.3,122.2,422,120.6,422,118.6z M427.4,118.6c0-1.2-0.7-2.2-2-2.2c-1.2,0-1.9,1.1-1.9,2.2c0,1.2,0.7,2.3,1.9,2.3
          C426.7,120.9,427.4,119.8,427.4,118.6z"/>
        <path class="st1"
              d="M430.4,122v-6.8h1.5v1c0.5-0.6,1.3-1.1,2.2-1.1v1.5c-0.1,0-0.3,0-0.5,0c-0.6,0-1.4,0.4-1.7,0.9v4.6H430.4z"/>
        <path class="st1" d="M435.9,120.5v-3.9h-1.1v-1.3h1.1v-1.8h1.5v1.8h1.4v1.3h-1.4v3.5c0,0.5,0.2,0.8,0.6,0.8c0.3,0,0.5-0.1,0.7-0.2
          l0.4,1.1c-0.3,0.3-0.7,0.4-1.4,0.4C436.5,122.2,435.9,121.6,435.9,120.5z"/>
        <path class="st1" d="M444.6,122v-4.3c0-1.1-0.5-1.4-1.4-1.4c-0.7,0-1.4,0.4-1.7,0.9v4.7h-1.5v-9.3h1.5v3.5
          c0.4-0.5,1.3-1.1,2.4-1.1c1.5,0,2.2,0.8,2.2,2.1v4.8H444.6z"/>
        <path class="st1" d="M447.5,118.6c0-2,1.4-3.5,3.4-3.5c2,0,3.3,1.6,3.3,3.7v0.4h-5.2c0.1,1,0.9,1.9,2.2,1.9c0.7,0,1.4-0.3,1.9-0.7
          l0.7,1c-0.7,0.6-1.7,1-2.7,1C449,122.2,447.5,120.8,447.5,118.6z M451,116.3c-1.3,0-1.8,1-1.9,1.8h3.8
          C452.8,117.3,452.3,116.3,451,116.3z"/>
        <path class="st1"
              d="M455.7,122v-6.8h1.5v1c0.5-0.6,1.3-1.1,2.2-1.1v1.5c-0.1,0-0.3,0-0.5,0c-0.6,0-1.4,0.4-1.7,0.9v4.6H455.7z"/>
        <path class="st1" d="M465.1,122v-4.2c0-1.1-0.5-1.4-1.4-1.4c-0.8,0-1.4,0.4-1.7,0.9v4.7h-1.5v-6.8h1.5v0.9
          c0.4-0.5,1.3-1.1,2.4-1.1c1.5,0,2.2,0.8,2.2,2.2v4.8H465.1z"/>
      </g>
      <g id="Eastern" class="st24">
    <path class="st1" d="M642.3,336.5v-9.3h6.4v1.4H644v2.4h4.7v1.4H644v2.6h4.8v1.4H642.3z"/>
        <path class="st1" d="M654.5,336.5v-0.7c-0.5,0.6-1.3,0.9-2.2,0.9c-1.1,0-2.4-0.7-2.4-2.2c0-1.6,1.2-2.2,2.4-2.2
      c0.9,0,1.7,0.3,2.2,0.9v-1c0-0.8-0.6-1.3-1.6-1.3c-0.8,0-1.4,0.3-2,0.9l-0.6-1c0.8-0.7,1.8-1.1,2.8-1.1c1.5,0,2.8,0.6,2.8,2.5v4.5
      H654.5z M654.5,335v-1c-0.3-0.5-1-0.7-1.6-0.7c-0.8,0-1.4,0.5-1.4,1.2c0,0.7,0.6,1.2,1.4,1.2C653.5,335.7,654.1,335.4,654.5,335z"
        />
        <path class="st1" d="M657.2,335.6l0.7-1.1c0.5,0.5,1.4,1,2.3,1s1.3-0.4,1.3-0.9c0-1.3-4.1-0.4-4.1-3c0-1.1,1-2.1,2.7-2.1
      c1.1,0,2,0.4,2.6,1l-0.6,1c-0.4-0.5-1.2-0.8-2-0.8c-0.8,0-1.3,0.4-1.3,0.8c0,1.2,4.1,0.3,4.1,3c0,1.2-1,2.1-2.8,2.1
      C659,336.7,657.9,336.3,657.2,335.6z"/>
        <path class="st1" d="M664.8,335V331h-1.1v-1.3h1.1v-1.8h1.5v1.8h1.4v1.3h-1.4v3.5c0,0.5,0.2,0.8,0.6,0.8c0.3,0,0.5-0.1,0.7-0.2
      l0.4,1.1c-0.3,0.3-0.7,0.4-1.4,0.4C665.4,336.7,664.8,336.1,664.8,335z"/>
        <path class="st1" d="M668.3,333.1c0-2,1.4-3.5,3.4-3.5c2,0,3.3,1.6,3.3,3.7v0.4h-5.2c0.1,1,0.9,1.9,2.2,1.9c0.7,0,1.4-0.3,1.9-0.7
      l0.7,1c-0.7,0.6-1.7,1-2.7,1C669.8,336.7,668.3,335.3,668.3,333.1z M671.8,330.8c-1.3,0-1.8,1-1.9,1.8h3.8
      C673.6,331.8,673.1,330.8,671.8,330.8z"/>
        <path class="st1"
              d="M676.5,336.5v-6.8h1.5v1c0.5-0.6,1.3-1.1,2.2-1.1v1.5c-0.1,0-0.3,0-0.5,0c-0.6,0-1.4,0.4-1.7,0.9v4.6H676.5z"
        />
        <path class="st1" d="M685.9,336.5v-4.2c0-1.1-0.5-1.4-1.4-1.4c-0.8,0-1.4,0.4-1.7,0.9v4.7h-1.5v-6.8h1.5v0.9
      c0.4-0.5,1.3-1.1,2.4-1.1c1.5,0,2.2,0.8,2.2,2.2v4.8H685.9z"/>
  </g>
      <g id="Submontane" class="st24">
    <path class="st1" d="M681.6,519.5l0.9-1.3c0.6,0.7,1.7,1.3,3,1.3c1.3,0,1.9-0.7,1.9-1.3c0-2-5.4-0.7-5.4-4.2
      c0-1.5,1.4-2.7,3.4-2.7c1.5,0,2.6,0.5,3.5,1.3l-0.9,1.2c-0.7-0.7-1.7-1.1-2.7-1.1c-1,0-1.6,0.5-1.6,1.2c0,1.7,5.4,0.7,5.4,4.1
      c0,1.6-1.1,2.9-3.6,2.9C683.7,521,682.4,520.4,681.6,519.5z"/>
        <path class="st1" d="M695,520.8v-0.9c-0.5,0.5-1.3,1.1-2.4,1.1c-1.5,0-2.2-0.8-2.2-2.1V514h1.5v4.3c0,1,0.5,1.4,1.4,1.4
      c0.7,0,1.4-0.4,1.7-0.9V514h1.5v6.8H695z"/>
        <path class="st1" d="M698.3,520.8v-9.3h1.5v3.5c0.5-0.7,1.3-1.1,2.2-1.1c1.7,0,3,1.4,3,3.6c0,2.2-1.3,3.5-3,3.5
      c-0.9,0-1.7-0.4-2.2-1.1v0.9H698.3z M701.5,519.7c1.2,0,1.9-0.9,1.9-2.2c0-1.3-0.7-2.3-1.9-2.3c-0.7,0-1.4,0.4-1.7,0.9v2.7
      C700.1,519.3,700.8,519.7,701.5,519.7z"/>
        <path class="st1" d="M714.7,520.8v-4.4c0-0.7-0.3-1.2-1.1-1.2c-0.7,0-1.3,0.5-1.6,0.9v4.7h-1.5v-4.4c0-0.7-0.3-1.2-1.1-1.2
      c-0.6,0-1.2,0.5-1.6,0.9v4.7h-1.5V514h1.5v0.9c0.3-0.4,1.2-1.1,2.2-1.1c1,0,1.6,0.5,1.8,1.2c0.4-0.6,1.3-1.2,2.3-1.2
      c1.2,0,1.9,0.7,1.9,2v4.9H714.7z"/>
        <path class="st1" d="M717.7,517.4c0-1.9,1.3-3.5,3.5-3.5c2.2,0,3.5,1.6,3.5,3.5c0,1.9-1.3,3.6-3.5,3.6
      C719,521,717.7,519.4,717.7,517.4z M723.1,517.4c0-1.2-0.7-2.2-2-2.2c-1.2,0-1.9,1.1-1.9,2.2c0,1.2,0.7,2.3,1.9,2.3
      C722.4,519.7,723.1,518.6,723.1,517.4z"/>
        <path class="st1" d="M730.6,520.8v-4.2c0-1-0.5-1.4-1.4-1.4c-0.8,0-1.4,0.4-1.7,0.9v4.7h-1.5V514h1.5v0.9c0.4-0.5,1.3-1.1,2.4-1.1
      c1.5,0,2.2,0.8,2.2,2.2v4.8H730.6z"/>
        <path class="st1" d="M734.3,519.2v-3.9h-1.1V514h1.1v-1.8h1.5v1.8h1.4v1.3h-1.4v3.5c0,0.5,0.2,0.8,0.6,0.8c0.3,0,0.5-0.1,0.7-0.2
      l0.3,1.1c-0.3,0.3-0.7,0.4-1.4,0.4C734.9,521,734.3,520.4,734.3,519.2z"/>
        <path class="st1" d="M742.6,520.8v-0.7c-0.5,0.6-1.3,0.9-2.2,0.9c-1.1,0-2.4-0.7-2.4-2.2c0-1.6,1.2-2.2,2.4-2.2
      c0.9,0,1.7,0.3,2.2,0.9v-1c0-0.8-0.6-1.3-1.6-1.3c-0.8,0-1.4,0.3-2,0.9l-0.6-1c0.8-0.7,1.8-1.1,2.8-1.1c1.5,0,2.8,0.6,2.8,2.5v4.5
      H742.6z M742.6,519.3v-1c-0.3-0.5-1-0.7-1.6-0.7c-0.8,0-1.4,0.5-1.4,1.2c0,0.7,0.6,1.2,1.4,1.2C741.6,520,742.2,519.7,742.6,519.3
      z"/>
        <path class="st1" d="M750.4,520.8v-4.2c0-1-0.5-1.4-1.4-1.4c-0.8,0-1.4,0.4-1.7,0.9v4.7h-1.5V514h1.5v0.9c0.4-0.5,1.3-1.1,2.4-1.1
      c1.5,0,2.2,0.8,2.2,2.2v4.8H750.4z"/>
        <path class="st1" d="M753.3,517.4c0-2,1.4-3.5,3.4-3.5c2,0,3.3,1.6,3.3,3.7v0.4h-5.2c0.1,1,0.9,1.9,2.2,1.9c0.7,0,1.4-0.3,1.9-0.7
      l0.7,1c-0.7,0.6-1.7,1-2.7,1C754.8,521,753.3,519.6,753.3,517.4z M756.8,515.1c-1.3,0-1.8,1-1.9,1.8h3.8
      C758.6,516.1,758.1,515.1,756.8,515.1z"/>
  </g>
      <g id="Central" class="st24">
    <path class="st1" d="M318.9,265.5c0-2.9,2.1-4.8,4.9-4.8c1.9,0,3.1,1,3.8,2.1l-1.4,0.7c-0.4-0.8-1.3-1.4-2.4-1.4
      c-1.8,0-3.2,1.4-3.2,3.4s1.4,3.4,3.2,3.4c1,0,1.9-0.6,2.4-1.4l1.4,0.7c-0.7,1.1-1.9,2.1-3.8,2.1
      C321.1,270.4,318.9,268.4,318.9,265.5z"/>
        <path class="st1" d="M328.5,266.8c0-2,1.4-3.5,3.4-3.5c2,0,3.3,1.6,3.3,3.7v0.4H330c0.1,1,0.9,1.9,2.2,1.9c0.7,0,1.4-0.3,1.9-0.7
      l0.7,1c-0.7,0.6-1.7,1-2.7,1C330,270.4,328.5,269,328.5,266.8z M331.9,264.5c-1.3,0-1.8,1-1.9,1.8h3.8
      C333.8,265.5,333.2,264.5,331.9,264.5z"/>
        <path class="st1" d="M341.2,270.2V266c0-1.1-0.5-1.4-1.4-1.4c-0.8,0-1.4,0.4-1.7,0.9v4.7h-1.5v-6.8h1.5v0.9
      c0.4-0.5,1.3-1.1,2.4-1.1c1.5,0,2.2,0.8,2.2,2.2v4.8H341.2z"/>
        <path class="st1" d="M344.8,268.6v-3.9h-1.1v-1.3h1.1v-1.8h1.5v1.8h1.4v1.3h-1.4v3.5c0,0.5,0.2,0.8,0.6,0.8c0.3,0,0.5-0.1,0.7-0.2
      l0.3,1.1c-0.3,0.3-0.7,0.4-1.4,0.4C345.4,270.4,344.8,269.8,344.8,268.6z"/>
        <path class="st1"
              d="M349,270.2v-6.8h1.5v1c0.5-0.6,1.3-1.1,2.2-1.1v1.5c-0.1,0-0.3,0-0.5,0c-0.6,0-1.4,0.4-1.7,0.9v4.6H349z"/>
        <path class="st1" d="M357.9,270.2v-0.7c-0.5,0.6-1.3,0.9-2.2,0.9c-1.1,0-2.4-0.7-2.4-2.2c0-1.6,1.2-2.2,2.4-2.2
      c0.9,0,1.7,0.3,2.2,0.9v-1c0-0.8-0.6-1.3-1.6-1.3c-0.8,0-1.4,0.3-2,0.9l-0.6-1c0.8-0.7,1.8-1.1,2.8-1.1c1.5,0,2.8,0.6,2.8,2.5v4.5
      H357.9z M357.9,268.7v-1c-0.3-0.5-1-0.7-1.6-0.7c-0.8,0-1.4,0.5-1.4,1.2c0,0.7,0.6,1.2,1.4,1.2C357,269.4,357.6,269.1,357.9,268.7
      z"/>
        <path class="st1" d="M361.2,270.2v-9.3h1.5v9.3H361.2z"/>
  </g>
      <g id="Black_Sea" class="st24">
    <path class="st1" d="M102.1,413.8v-9.3h4.6c1.7,0,2.7,1.1,2.7,2.4c0,1.2-0.8,2-1.6,2.1c1,0.2,1.8,1.1,1.8,2.3c0,1.4-1,2.5-2.7,2.5
      H102.1z M107.7,407.1c0-0.7-0.5-1.2-1.3-1.2h-2.6v2.4h2.6C107.2,408.3,107.7,407.8,107.7,407.1z M107.9,411.1
      c0-0.7-0.5-1.3-1.4-1.3h-2.7v2.6h2.7C107.4,412.4,107.9,411.9,107.9,411.1z"/>
        <path class="st1" d="M111.2,413.8v-9.3h1.5v9.3H111.2z"/>
        <path class="st1" d="M118.7,413.8v-0.7c-0.5,0.6-1.3,0.9-2.2,0.9c-1.1,0-2.4-0.7-2.4-2.2c0-1.6,1.2-2.2,2.4-2.2
      c0.9,0,1.7,0.3,2.2,0.9v-1c0-0.8-0.6-1.3-1.6-1.3c-0.8,0-1.4,0.3-2,0.9l-0.6-1c0.8-0.7,1.8-1.1,2.8-1.1c1.5,0,2.8,0.6,2.8,2.5v4.5
      H118.7z M118.7,412.3v-1c-0.3-0.5-1-0.7-1.6-0.7c-0.8,0-1.4,0.5-1.4,1.2c0,0.7,0.6,1.2,1.4,1.2C117.7,413,118.3,412.7,118.7,412.3
      z"/>
        <path class="st1" d="M121.6,410.4c0-2,1.4-3.5,3.5-3.5c1.3,0,2.1,0.6,2.6,1.2l-1,0.9c-0.4-0.5-0.9-0.8-1.6-0.8c-1.2,0-2,0.9-2,2.2
      s0.8,2.3,2,2.3c0.7,0,1.2-0.3,1.6-0.8l1,0.9c-0.5,0.6-1.3,1.2-2.6,1.2C123,414,121.6,412.5,121.6,410.4z"/>
        <path class="st1" d="M133.3,413.8l-2.1-2.8l-0.9,1v1.8h-1.5v-9.3h1.5v5.8l3-3.3h1.8l-2.8,3.1l2.9,3.7H133.3z"/>
        <path class="st1" d="M139.2,412.5l0.9-1.3c0.6,0.7,1.7,1.3,3,1.3c1.3,0,1.9-0.7,1.9-1.3c0-2-5.4-0.7-5.4-4.2
      c0-1.5,1.4-2.7,3.4-2.7c1.5,0,2.6,0.5,3.5,1.3l-0.9,1.2c-0.7-0.7-1.7-1.1-2.7-1.1c-1,0-1.6,0.5-1.6,1.2c0,1.7,5.4,0.7,5.4,4.1
      c0,1.6-1.1,2.9-3.6,2.9C141.3,414,140.1,413.4,139.2,412.5z"/>
        <path class="st1" d="M147.7,410.4c0-2,1.4-3.5,3.4-3.5c2,0,3.3,1.6,3.3,3.7v0.4h-5.2c0.1,1,0.9,1.9,2.2,1.9c0.7,0,1.4-0.3,1.9-0.7
      l0.7,1c-0.7,0.6-1.7,1-2.7,1C149.2,414,147.7,412.6,147.7,410.4z M151.1,408.1c-1.3,0-1.8,1-1.9,1.8h3.8
      C153,409.1,152.5,408.1,151.1,408.1z"/>
        <path class="st1" d="M160,413.8v-0.7c-0.5,0.6-1.3,0.9-2.2,0.9c-1.1,0-2.4-0.7-2.4-2.2c0-1.6,1.2-2.2,2.4-2.2
      c0.9,0,1.7,0.3,2.2,0.9v-1c0-0.8-0.6-1.3-1.6-1.3c-0.8,0-1.4,0.3-2,0.9l-0.6-1c0.8-0.7,1.8-1.1,2.8-1.1c1.5,0,2.8,0.6,2.8,2.5v4.5
      H160z M160,412.3v-1c-0.3-0.5-1-0.7-1.6-0.7c-0.8,0-1.4,0.5-1.4,1.2c0,0.7,0.6,1.2,1.4,1.2C159.1,413,159.7,412.7,160,412.3z"/>
  </g>
      <g id="Krasnodar_agglomeration" class="st24">
    <path class="st1" d="M363.4,400.9l-3.4-4.2l-0.9,1v3.2h-1.8v-10h1.8v4.8l3.9-4.8h2.2l-4.1,4.7l4.4,5.3H363.4z"/>
        <path class="st1"
              d="M366.8,400.9v-7.2h1.6v1.1c0.5-0.7,1.4-1.2,2.4-1.2v1.6c-0.1,0-0.3,0-0.5,0c-0.7,0-1.5,0.4-1.9,1v4.9H366.8z"
        />
        <path class="st1" d="M376.3,400.9v-0.8c-0.5,0.6-1.4,1-2.3,1c-1.2,0-2.5-0.8-2.5-2.4c0-1.7,1.3-2.4,2.5-2.4c1,0,1.8,0.3,2.3,0.9
      v-1.1c0-0.8-0.7-1.4-1.7-1.4c-0.8,0-1.5,0.3-2.1,0.9l-0.6-1.1c0.8-0.8,1.9-1.2,3-1.2c1.6,0,3,0.7,3,2.6v4.8H376.3z M376.3,399.3
      v-1.1c-0.4-0.5-1-0.8-1.7-0.8c-0.9,0-1.5,0.5-1.5,1.3c0,0.8,0.7,1.3,1.5,1.3C375.3,400,376,399.7,376.3,399.3z"/>
        <path class="st1" d="M379.3,400l0.7-1.1c0.5,0.5,1.5,1,2.5,1c0.9,0,1.4-0.4,1.4-1c0-1.4-4.4-0.4-4.4-3.2c0-1.2,1-2.2,2.8-2.2
      c1.2,0,2.2,0.4,2.8,1l-0.7,1.1c-0.4-0.5-1.2-0.9-2.1-0.9c-0.8,0-1.3,0.4-1.3,0.9c0,1.3,4.4,0.3,4.4,3.2c0,1.3-1.1,2.3-3,2.3
      C381.1,401.1,380,400.7,379.3,400z"/>
        <path class="st1" d="M391.8,400.9v-4.5c0-1.1-0.6-1.5-1.5-1.5c-0.8,0-1.5,0.5-1.9,1v5.1h-1.6v-7.2h1.6v1c0.5-0.6,1.4-1.2,2.5-1.2
      c1.6,0,2.3,0.8,2.3,2.3v5.1H391.8z"/>
        <path class="st1" d="M394.9,397.3c0-2.1,1.4-3.8,3.7-3.8c2.3,0,3.7,1.7,3.7,3.8c0,2.1-1.4,3.8-3.7,3.8
      C396.3,401.1,394.9,399.4,394.9,397.3z M400.7,397.3c0-1.3-0.8-2.4-2.1-2.4c-1.3,0-2.1,1.1-2.1,2.4c0,1.3,0.8,2.4,2.1,2.4
      C400,399.7,400.7,398.6,400.7,397.3z"/>
        <path class="st1" d="M409.1,400.9v-1c-0.6,0.7-1.4,1.2-2.3,1.2c-1.9,0-3.2-1.4-3.2-3.8c0-2.3,1.3-3.8,3.2-3.8
      c0.9,0,1.7,0.4,2.3,1.2v-3.8h1.6v10H409.1z M409.1,398.7v-2.9c-0.4-0.5-1.1-1-1.9-1c-1.2,0-2,1-2,2.4c0,1.4,0.8,2.4,2,2.4
      C408,399.7,408.7,399.3,409.1,398.7z"/>
        <path class="st1" d="M417.1,400.9v-0.8c-0.5,0.6-1.4,1-2.3,1c-1.2,0-2.5-0.8-2.5-2.4c0-1.7,1.3-2.4,2.5-2.4c1,0,1.8,0.3,2.3,0.9
      v-1.1c0-0.8-0.7-1.4-1.7-1.4c-0.8,0-1.5,0.3-2.1,0.9l-0.6-1.1c0.8-0.8,1.9-1.2,3-1.2c1.6,0,3,0.7,3,2.6v4.8H417.1z M417.1,399.3
      v-1.1c-0.4-0.5-1-0.8-1.7-0.8c-0.9,0-1.5,0.5-1.5,1.3c0,0.8,0.7,1.3,1.5,1.3C416,400,416.7,399.7,417.1,399.3z"/>
        <path class="st1"
              d="M420.6,400.9v-7.2h1.6v1.1c0.5-0.7,1.4-1.2,2.4-1.2v1.6c-0.1,0-0.3,0-0.5,0c-0.7,0-1.5,0.4-1.9,1v4.9H420.6z"
        />
        <path class="st1" d="M361.8,414.9v-0.8c-0.5,0.6-1.4,1-2.3,1c-1.2,0-2.5-0.8-2.5-2.4c0-1.7,1.3-2.4,2.5-2.4c1,0,1.8,0.3,2.3,0.9
      v-1.1c0-0.8-0.7-1.4-1.7-1.4c-0.8,0-1.5,0.3-2.1,0.9l-0.6-1.1c0.8-0.8,1.9-1.2,3-1.2c1.6,0,3,0.7,3,2.6v4.8H361.8z M361.8,413.3
      v-1.1c-0.4-0.5-1-0.8-1.7-0.8c-0.9,0-1.5,0.5-1.5,1.3c0,0.8,0.7,1.3,1.5,1.3C360.8,414,361.5,413.7,361.8,413.3z"/>
        <path class="st1" d="M365.3,416.8l0.7-1.1c0.6,0.7,1.3,0.9,2.3,0.9c1,0,2.1-0.5,2.1-2v-0.9c-0.6,0.7-1.4,1.2-2.3,1.2
      c-1.8,0-3.2-1.3-3.2-3.7c0-2.3,1.3-3.7,3.2-3.7c0.9,0,1.7,0.4,2.3,1.2v-1h1.6v6.9c0,2.6-1.9,3.3-3.7,3.3
      C367.1,417.8,366.2,417.6,365.3,416.8z M370.5,412.5v-2.7c-0.3-0.5-1.1-1-1.9-1c-1.2,0-2,0.9-2,2.3s0.8,2.3,2,2.3
      C369.4,413.5,370.2,413.1,370.5,412.5z"/>
        <path class="st1" d="M374,416.8l0.7-1.1c0.6,0.7,1.3,0.9,2.3,0.9c1,0,2.1-0.5,2.1-2v-0.9c-0.6,0.7-1.4,1.2-2.3,1.2
      c-1.8,0-3.2-1.3-3.2-3.7c0-2.3,1.3-3.7,3.2-3.7c0.9,0,1.7,0.4,2.3,1.2v-1h1.6v6.9c0,2.6-1.9,3.3-3.7,3.3
      C375.8,417.8,374.9,417.6,374,416.8z M379.2,412.5v-2.7c-0.3-0.5-1.1-1-1.9-1c-1.2,0-2,0.9-2,2.3s0.8,2.3,2,2.3
      C378.1,413.5,378.9,413.1,379.2,412.5z"/>
        <path class="st1" d="M382.7,414.9v-10h1.6v10H382.7z"/>
        <path class="st1" d="M385.9,411.3c0-2.1,1.4-3.8,3.7-3.8c2.3,0,3.7,1.7,3.7,3.8c0,2.1-1.4,3.8-3.7,3.8
      C387.3,415.1,385.9,413.4,385.9,411.3z M391.7,411.3c0-1.3-0.8-2.4-2.1-2.4c-1.3,0-2.1,1.1-2.1,2.4c0,1.3,0.8,2.4,2.1,2.4
      C391,413.7,391.7,412.6,391.7,411.3z"/>
        <path class="st1" d="M403.8,414.9v-4.7c0-0.8-0.3-1.3-1.2-1.3c-0.7,0-1.4,0.5-1.7,1v5.1h-1.6v-4.7c0-0.8-0.3-1.3-1.2-1.3
      c-0.7,0-1.3,0.5-1.7,1v5.1h-1.6v-7.2h1.6v1c0.3-0.5,1.3-1.2,2.4-1.2c1.1,0,1.7,0.5,2,1.3c0.4-0.7,1.4-1.3,2.5-1.3
      c1.3,0,2.1,0.7,2.1,2.2v5.2H403.8z"/>
        <path class="st1" d="M406.9,411.3c0-2.1,1.5-3.8,3.7-3.8c2.2,0,3.6,1.7,3.6,3.9v0.4h-5.6c0.1,1.1,0.9,2,2.3,2
      c0.7,0,1.5-0.3,2.1-0.8l0.7,1c-0.7,0.7-1.8,1-2.9,1C408.5,415.1,406.9,413.6,406.9,411.3z M410.6,408.8c-1.4,0-2,1-2,1.9h4.1
      C412.6,409.8,412,408.8,410.6,408.8z"/>
        <path class="st1"
              d="M415.7,414.9v-7.2h1.6v1.1c0.5-0.7,1.4-1.2,2.4-1.2v1.6c-0.1,0-0.3,0-0.5,0c-0.7,0-1.5,0.4-1.9,1v4.9H415.7z"
        />
        <path class="st1" d="M425.2,414.9v-0.8c-0.5,0.6-1.4,1-2.3,1c-1.2,0-2.5-0.8-2.5-2.4c0-1.7,1.3-2.4,2.5-2.4c1,0,1.8,0.3,2.3,0.9
      v-1.1c0-0.8-0.7-1.4-1.7-1.4c-0.8,0-1.5,0.3-2.1,0.9l-0.6-1.1c0.8-0.8,1.9-1.2,3-1.2c1.6,0,3,0.7,3,2.6v4.8H425.2z M425.2,413.3
      v-1.1c-0.4-0.5-1-0.8-1.7-0.8c-0.9,0-1.5,0.5-1.5,1.3c0,0.8,0.7,1.3,1.5,1.3C424.2,414,424.9,413.7,425.2,413.3z"/>
        <path class="st1" d="M429.1,413.2V409h-1.2v-1.4h1.2v-2h1.6v2h1.5v1.4h-1.5v3.8c0,0.5,0.2,0.9,0.7,0.9c0.3,0,0.6-0.1,0.7-0.3
      l0.4,1.2c-0.3,0.3-0.8,0.5-1.5,0.5C429.7,415.1,429.1,414.4,429.1,413.2z"/>
        <path class="st1" d="M433.4,405.8c0-0.5,0.5-1,1-1c0.5,0,1,0.4,1,1s-0.4,1-1,1C433.8,406.8,433.4,406.3,433.4,405.8z M433.5,414.9
      v-7.2h1.6v7.2H433.5z"/>
        <path class="st1" d="M436.6,411.3c0-2.1,1.4-3.8,3.7-3.8c2.3,0,3.7,1.7,3.7,3.8c0,2.1-1.4,3.8-3.7,3.8
      C438,415.1,436.6,413.4,436.6,411.3z M442.4,411.3c0-1.3-0.8-2.4-2.1-2.4c-1.3,0-2.1,1.1-2.1,2.4c0,1.3,0.8,2.4,2.1,2.4
      C441.7,413.7,442.4,412.6,442.4,411.3z"/>
        <path class="st1" d="M450.5,414.9v-4.5c0-1.1-0.6-1.5-1.5-1.5c-0.8,0-1.5,0.5-1.9,1v5.1h-1.6v-7.2h1.6v1c0.5-0.6,1.4-1.2,2.5-1.2
      c1.6,0,2.3,0.8,2.3,2.3v5.1H450.5z"/>
  </g>
      <g id="Sochi_agglomeration" class="st24">
    <path class="st1" d="M518.8,636.5l1-1.3c0.7,0.7,1.8,1.4,3.2,1.4c1.4,0,2-0.7,2-1.4c0-2.1-5.8-0.8-5.8-4.5c0-1.7,1.5-2.9,3.6-2.9
      c1.6,0,2.8,0.5,3.8,1.4l-1,1.3c-0.8-0.8-1.8-1.1-2.9-1.1c-1,0-1.7,0.5-1.7,1.2c0,1.9,5.8,0.7,5.8,4.4c0,1.7-1.2,3.1-3.9,3.1
      C521,638.1,519.6,637.4,518.8,636.5z"/>
        <path class="st1" d="M527.8,634.2c0-2.1,1.4-3.8,3.7-3.8c2.3,0,3.7,1.7,3.7,3.8c0,2.1-1.4,3.8-3.7,3.8
      C529.3,638.1,527.8,636.3,527.8,634.2z M533.7,634.2c0-1.3-0.8-2.4-2.1-2.4c-1.3,0-2.1,1.1-2.1,2.4c0,1.3,0.8,2.4,2.1,2.4
      C532.9,636.7,533.7,635.5,533.7,634.2z"/>
        <path class="st1" d="M536.4,634.2c0-2.2,1.5-3.8,3.8-3.8c1.4,0,2.3,0.6,2.8,1.3l-1,1c-0.4-0.6-1-0.9-1.7-0.9c-1.3,0-2.2,1-2.2,2.4
      s0.9,2.4,2.2,2.4c0.7,0,1.3-0.3,1.7-0.9l1,1c-0.5,0.7-1.4,1.3-2.8,1.3C538,638.1,536.4,636.5,536.4,634.2z"/>
        <path class="st1" d="M549.1,637.9v-4.6c0-1.1-0.6-1.5-1.5-1.5c-0.8,0-1.5,0.5-1.9,1v5.1h-1.6v-10h1.6v3.8c0.5-0.6,1.4-1.2,2.6-1.2
      c1.6,0,2.3,0.8,2.3,2.3v5.1H549.1z"/>
        <path class="st1" d="M552.5,628.8c0-0.5,0.5-1,1-1c0.5,0,1,0.4,1,1s-0.4,1-1,1C552.9,629.7,552.5,629.3,552.5,628.8z M552.7,637.9
      v-7.2h1.6v7.2H552.7z"/>
        <path class="st1" d="M523.8,651.9v-0.8c-0.5,0.6-1.4,1-2.3,1c-1.2,0-2.5-0.8-2.5-2.4c0-1.7,1.3-2.4,2.5-2.4c1,0,1.8,0.3,2.3,0.9
      v-1.1c0-0.8-0.7-1.4-1.7-1.4c-0.8,0-1.5,0.3-2.1,0.9l-0.6-1.1c0.8-0.8,1.9-1.2,3-1.2c1.6,0,3,0.7,3,2.6v4.8H523.8z M523.8,650.2
      v-1.1c-0.4-0.5-1-0.8-1.7-0.8c-0.9,0-1.5,0.5-1.5,1.3c0,0.8,0.7,1.3,1.5,1.3C522.8,651,523.4,650.7,523.8,650.2z"/>
        <path class="st1" d="M527.3,653.7l0.7-1.1c0.6,0.7,1.3,0.9,2.3,0.9c1,0,2.1-0.5,2.1-2v-0.9c-0.6,0.7-1.4,1.2-2.3,1.2
      c-1.8,0-3.2-1.3-3.2-3.7c0-2.3,1.3-3.7,3.2-3.7c0.9,0,1.7,0.4,2.3,1.2v-1h1.6v6.9c0,2.6-1.9,3.3-3.7,3.3
      C529.1,654.8,528.2,654.5,527.3,653.7z M532.5,649.5v-2.7c-0.3-0.5-1.1-1-1.9-1c-1.2,0-2,0.9-2,2.3s0.8,2.3,2,2.3
      C531.3,650.5,532.1,650.1,532.5,649.5z"/>
        <path class="st1" d="M536,653.7l0.7-1.1c0.6,0.7,1.3,0.9,2.3,0.9c1,0,2.1-0.5,2.1-2v-0.9c-0.6,0.7-1.4,1.2-2.3,1.2
      c-1.8,0-3.2-1.3-3.2-3.7c0-2.3,1.3-3.7,3.2-3.7c0.9,0,1.7,0.4,2.3,1.2v-1h1.6v6.9c0,2.6-1.9,3.3-3.7,3.3
      C537.8,654.8,536.9,654.5,536,653.7z M541.1,649.5v-2.7c-0.3-0.5-1.1-1-1.9-1c-1.2,0-2,0.9-2,2.3s0.8,2.3,2,2.3
      C540,650.5,540.8,650.1,541.1,649.5z"/>
        <path class="st1" d="M544.7,651.9v-10h1.6v10H544.7z"/>
        <path class="st1" d="M547.8,648.2c0-2.1,1.4-3.8,3.7-3.8c2.3,0,3.7,1.7,3.7,3.8c0,2.1-1.4,3.8-3.7,3.8
      C549.2,652.1,547.8,650.3,547.8,648.2z M553.6,648.2c0-1.3-0.8-2.4-2.1-2.4c-1.3,0-2.1,1.1-2.1,2.4c0,1.3,0.8,2.4,2.1,2.4
      C552.9,650.7,553.6,649.5,553.6,648.2z"/>
        <path class="st1" d="M565.7,651.9v-4.7c0-0.8-0.3-1.3-1.2-1.3c-0.7,0-1.4,0.5-1.7,1v5.1h-1.6v-4.7c0-0.8-0.3-1.3-1.2-1.3
      c-0.7,0-1.3,0.5-1.7,1v5.1h-1.6v-7.2h1.6v1c0.3-0.5,1.3-1.2,2.4-1.2c1.1,0,1.7,0.5,2,1.3c0.4-0.7,1.4-1.3,2.5-1.3
      c1.3,0,2.1,0.7,2.1,2.2v5.2H565.7z"/>
        <path class="st1" d="M568.8,648.2c0-2.1,1.5-3.8,3.7-3.8c2.2,0,3.6,1.7,3.6,3.9v0.4h-5.6c0.1,1.1,0.9,2,2.3,2
      c0.7,0,1.5-0.3,2.1-0.8l0.7,1c-0.7,0.7-1.8,1-2.9,1C570.5,652.1,568.8,650.5,568.8,648.2z M572.5,645.7c-1.4,0-2,1-2,1.9h4.1
      C574.5,646.8,573.9,645.7,572.5,645.7z"/>
        <path class="st1"
              d="M577.6,651.9v-7.2h1.6v1.1c0.5-0.7,1.4-1.2,2.4-1.2v1.6c-0.1,0-0.3,0-0.5,0c-0.7,0-1.5,0.4-1.9,1v4.9H577.6z"
        />
        <path class="st1" d="M587.2,651.9v-0.8c-0.5,0.6-1.4,1-2.3,1c-1.2,0-2.5-0.8-2.5-2.4c0-1.7,1.3-2.4,2.5-2.4c1,0,1.8,0.3,2.3,0.9
      v-1.1c0-0.8-0.7-1.4-1.7-1.4c-0.8,0-1.5,0.3-2.1,0.9l-0.6-1.1c0.8-0.8,1.9-1.2,3-1.2c1.6,0,3,0.7,3,2.6v4.8H587.2z M587.2,650.2
      v-1.1c-0.4-0.5-1-0.8-1.7-0.8c-0.9,0-1.5,0.5-1.5,1.3c0,0.8,0.7,1.3,1.5,1.3C586.1,651,586.8,650.7,587.2,650.2z"/>
        <path class="st1" d="M591,650.2V646h-1.2v-1.4h1.2v-2h1.6v2h1.5v1.4h-1.5v3.8c0,0.5,0.2,0.9,0.7,0.9c0.3,0,0.6-0.1,0.7-0.3
      l0.4,1.2c-0.3,0.3-0.8,0.5-1.5,0.5C591.7,652.1,591,651.4,591,650.2z"/>
        <path class="st1" d="M595.3,642.8c0-0.5,0.5-1,1-1c0.5,0,1,0.4,1,1s-0.4,1-1,1C595.7,643.7,595.3,643.3,595.3,642.8z M595.5,651.9
      v-7.2h1.6v7.2H595.5z"/>
        <path class="st1" d="M598.6,648.2c0-2.1,1.4-3.8,3.7-3.8c2.3,0,3.7,1.7,3.7,3.8c0,2.1-1.4,3.8-3.7,3.8
      C600,652.1,598.6,650.3,598.6,648.2z M604.4,648.2c0-1.3-0.8-2.4-2.1-2.4c-1.3,0-2.1,1.1-2.1,2.4c0,1.3,0.8,2.4,2.1,2.4
      C603.6,650.7,604.4,649.5,604.4,648.2z"/>
        <path class="st1" d="M612.5,651.9v-4.5c0-1.1-0.6-1.5-1.5-1.5c-0.8,0-1.5,0.5-1.9,1v5.1h-1.6v-7.2h1.6v1c0.5-0.6,1.4-1.2,2.5-1.2
      c1.6,0,2.3,0.8,2.3,2.3v5.1H612.5z"/>
  </g>
</g>
    <g id="zone-names" class="st11">
      <text class="st1 st3 st24" x="404.49945" y="122.14779" style="font-size:13.3333px">Северный</text>
      <text class="st1 st3 st24" style="font-size:13.3333px" x="295.92862" y="269.50607">Центральный</text>
      <text class="st1 st3 st24" style="font-size:13.3333px" x="81.109673" y="414.36472">Черноморский</text>
      <text class="st1 st3 st24" style="font-size:13.3333px" x="627.73529" y="337.63873">Восточный</text>
      <text class="st1 st3 st24" style="font-size:13.3333px" x="678.88562" y="521.17993">Предгорный</text>
      <text class="st1 st3 st24" style="font-size:13.3333px" x="355.88098" y="399.63336">
        <tspan x="355.88098" y="399.63336">Краснодарская</tspan>
        <tspan x="355.88098" y="416.29999">агломерация</tspan>
      </text>
      <text class="st1 st3 st24" style="font-size:13.3333px" x="519.27759" y="637.2157">
        <tspan x="519.27759" y="637.2157">Сочинская</tspan>
        <tspan x="519.27759" y="653.88232">агломерация</tspan>
      </text>
    </g>
  </svg>
</template>

<script>
import { gsap } from 'gsap';

export default {
  name: "Map",
  props: {
    projects: Array,
    locale: {
      default() {
        return 'ru'
      }
    },
    level: {
      default() {
        return 1
      }
    },
    curDistrict: Object
  },
  data() {
    return {
      intervalId: null,
      curAnimationZone: 0,
      zones: null,
      zone: null,
      district: null,
      allPoints: []
    };
  },
  mounted() {
    this.zones = this.$refs.map.querySelectorAll('.js-MapZone');
    let districts = this.$refs.map.querySelectorAll('.js-MapDistrict');
    districts.forEach((district) => district.addEventListener('click', () => this.selectDistrict(district)));
    this.startAnimation();
    this.debugAreaMap = this.$refs.svgMap.getBBox();
  },
  computed: {
    eyskiyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'eyskiy_rayon')
    },
    pavlovskiyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'pavlovskiy_rayon')
    },
    shcherbinovskiyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'shcherbinovskiy_rayon')
    },
    starominskiyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'starominskiy_rayon')
    },
    kushchevskiyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'kushchevskiy_rayon')
    },
    kanevskoyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'kanevskoy_rayon')
    },
    leningradskiyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'leningradskiy_rayon')
    },
    krylovskiyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'krylovskiy_rayon')
    },
    dinskoyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'dinskoy_rayon')
    },
    gorodKrasnodar: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'gorod_krasnodar')
    },
    severskiyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'severskiy_rayon')
    },
    gorodGoryachiyKlyuch: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'gorod_goryachiy_klyuch')
    },
    takhtamukayskiyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'takhtamukayskiy_rayon')
    },
    teuchezhskiyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'teuchezhskiy_rayon')
    },
    temryukskiyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'temryukskiy_rayon')
    },
    gorodKurortAnapa: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'gorod-kurort_anapa')
    },
    gorodGeroyNovorossiysk: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'gorod-geroy_novorossiysk')
    },
    gorodKurortGelendzhik: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'gorod-kurort_gelendzhik')
    },
    tuapsinskiyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'tuapsinskiy_rayon')
    },
    gorodKurortSochi: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'gorod-kurort_sochi')
    },
    novopokrovskyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'novopokrovsky_rayon')
    },
    tikhoretskiyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'tikhoretskiy_rayon')
    },
    beloglinskiyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'beloglinskiy_rayon')
    },
    tbilisskiyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'tbilisskiy_rayon')
    },
    kavkazskiyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'kavkazskiy_rayon')
    },
    gulkevichskiyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'gulkevichskiy_rayon')
    },
    kurganinskiyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'kurganinskiy_rayon')
    },
    novokubanskiyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'novokubanskiy_rayon')
    },
    gorodArmavir: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'gorod_armavir')
    },
    uspenskiyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'uspenskiy_rayon')
    },
    belorechenskiyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'belorechenskiy_rayon')
    },
    apsheronskiyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'apsheronskiy_rayon')
    },
    mostovskoyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'mostovskoy_rayon')
    },
    labinskiyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'labinskiy_rayon')
    },
    otradnenskiyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'otradnenskiy_rayon')
    },
    primorskoAkhtarskiyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'primorsko_akhtarskiy_rayon')
    },
    slavyanskiyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'slavyanskiy_rayon')
    },
    kalininskiyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'kalininskiy_rayon')
    },
    krasnoarmeyskiyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'krasnoarmeyskiy_rayon')
    },
    bryukhovetskiyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'bryukhovetskiy_rayon')
    },
    krymskiyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'krymskiy_rayon')
    },
    abinskiyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'abinskiy_rayon')
    },
    vyselkovskiyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'vyselkovskiy_rayon')
    },
    korenovskiyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'korenovskiy_rayon')
    },
    timashevskiyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'timashevskiy_rayon')
    },
    ustLabinskiyRayon: function () {
      return this.allPoints.filter((p) => p.project.district_code === 'ust_labinskiy_rayon')
    }
  },
  methods: {
    selectZone(idx) {
      if (this.level > 1) return;

      this.zone = this.zones[idx];
      this.$emit('zoneSelected', this.zone.getAttribute('data-zone'));
      this.$refs.svgMap.classList.add('js-MapSvgZone');
      this.zone.classList.add('js-MapZone--active');

      this.zoomTo(this.zone);
    },
    selectDistrict(district) {
      if (this.level !== 2) return;

      this.district = district;
      this.$emit('district-selected', this.district.getAttribute('data-district'));
      this.$refs.svgMap.classList.remove('js-MapSvgZone');
      this.$refs.svgMap.classList.add('js-MapSvgDistrict');
      this.district.classList.add('js-MapDistrict--active');

      this.zoomTo(this.district);
    },
    hoverZone(event) {
      if (!this.zone) {
        this.stopAnimation();
        event.target.classList.add('js-MapZone-animated');
        this.$refs.map.append(event.target);
      }
    },
    startAnimation() {
      if (!this.zone) {
        this.intervalId = setInterval(function() {
          this.zones[this.curAnimationZone].classList.remove('js-MapZone-animated');
          this.curAnimationZone++;
          if (this.curAnimationZone === this.zones.length) {
            this.curAnimationZone = 0;
          }
          this.zones[this.curAnimationZone].classList.add('js-MapZone-animated');
          this.$refs.map.append(this.zones[this.curAnimationZone]);
        }.bind(this), 3000);
      }
    },
    stopAnimation() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.curAnimationZone = 0;
      let curDistrict = this.$refs.map.querySelector('.js-MapZone-animated');
      if (curDistrict) {
        curDistrict.classList.remove('js-MapZone-animated');
      }
    },
    zoomTo(element) {
      let timeline = gsap.timeline({defauts:{duration: 2}});
      let mapBound = this.$refs.svgMap.getBBox();
      let zoneBound = element.getBBox();

      let mapCenterTop =  mapBound.y + (mapBound.height / 2);
      let mapCenterLeft = mapBound.x + (mapBound.width  / 2);
      let zoneCenterTop = zoneBound.y + (zoneBound.height / 2);
      let zoneCenterLeft = zoneBound.x + (zoneBound.width / 2);
      let factor = window.innerWidth < 420
          ? window.innerWidth / mapBound.width
          : window.innerWidth * 0.58 / mapBound.width;

      let scale = Math.min(4, 0.9 / Math.max((zoneBound.width) / mapBound.width, (zoneBound.height) / mapBound.height))

      let mapTop = zoneCenterTop > mapCenterTop ? mapCenterTop - zoneCenterTop : mapCenterTop - zoneCenterTop;
      let mapLeft = zoneCenterTop > mapCenterTop ? mapCenterLeft - zoneCenterLeft  : mapCenterLeft - zoneCenterLeft;
      mapTop = mapTop * factor * scale;
      mapLeft = mapLeft * factor * scale;

      timeline.to(this.$refs.svgMap, {transform: 'matrix('+ scale +', 0, 0, '+ scale +', '+ mapLeft +', '+ mapTop +')'})
    },
    calculatePointPosition(lat, long) {
      let imageNorthLat=46.939345;
      let imageSouthLat=43.124246;

      let imageWestLong=36.374489;
      let imageEastLong=42.035908;

      let imageLongPixels=880;
      let imageLatPixels=750;

      let pixelsPerLat=imageLatPixels/(imageNorthLat-imageSouthLat);
      let pixelsPerLong=imageLongPixels/(imageEastLong-imageWestLong);

      let xPixelPosition=(long-imageWestLong)*pixelsPerLong;
      let yPixelPosition=Math.abs(lat-imageNorthLat)*pixelsPerLat;

      return {x: xPixelPosition, y:yPixelPosition};
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    projects: function (newVal) {
      let points = [];
      newVal.forEach((project) => {
            let point = this.calculatePointPosition(project.lat, project.lon);
            point.color = project.type_project_id === 'investment_project' ? '#00b8ff' : '#ffcc0f';
            point.icon = project.industry_icon;
            point.iconWidth = 8.75;
            point.iconHeight = 10;
            point.iconX = point.x - (point.iconWidth / 2);
            point.iconY = point.y - point.iconHeight;
            point.project = project;
            points.push(point);
          });

      this.allPoints = points;
    },
    locale: function (newVal) {
      if (newVal === 'en') {
        this.$refs.svgMap.classList.add('js-MapSvg-en');
      } else {
        this.$refs.svgMap.classList.remove('js-MapSvg-en');
      }
    },
    level: function(newVal, oldVal) { // watch it
      if (newVal === 1 && this.zone) {
        let timeline = gsap.timeline({defauts:{duration: 2}});
        timeline.to(this.$refs.svgMap, {transform: 'matrix(1, 0, 0, 1, 0, 0)'});
        this.$refs.svgMap.classList.remove('js-MapSvgZone', 'js-MapSvgDistrict');
        if (this.district != null) {
          this.district.classList.remove('js-MapDistrict--active');
          this.district = null;
        }
        this.zone.classList.remove('js-MapZone--active');
        this.zone = null;
      } else if (newVal === 2 && oldVal === 3) {
        this.$refs.svgMap.classList.add('js-MapSvgZone');
        this.$refs.svgMap.classList.remove('js-MapSvgDistrict');
        this.district.classList.remove('js-MapDistrict--active');
        this.district = null;
        this.zoomTo(this.zone);
      }
    },
    curDistrict: function (newVal) {
      if (!this.district || this.district.getAttribute('data-district') !== newVal.code) {
        this.district = this.$refs.svgMap.querySelector('[data-district="'+newVal.code+'"]');
        this.$refs.svgMap.classList.remove('js-MapSvgZone');
        this.$refs.svgMap.classList.add('js-MapSvgDistrict');
        this.district.classList.add('js-MapDistrict--active');
        this.zoomTo(this.district);
      }
    }
  }
}
</script>