<template>
    <div class="page__content">
      <router-view/>
    </div>
    <AnimatedBackground />
</template>

<style lang="scss">
  @import "./assets/css/main";
</style>
<script>
import AnimatedBackground from "./components/AnimatedBackground";
export default {
  components: {
    AnimatedBackground
  }
}
</script>