<link rel="stylesheet" href="../../../forum2022.my/css/main.scss">
<template>
  <div class="info">
    <div class="info__header">
      <div class="info__controls">
        <a href="javascript:void(0)" @click="$emit('back')" class="back">
          <svg class="icon icon-arrow">
            <use xlink:href="#icon-arrow"></use>
          </svg>
          {{ $t('back') }}
        </a>
        <div class="close js-manInfoClose" @click="$emit('toggle-info')">
          <svg class="icon icon-close">
            <use xlink:href="#icon-close"></use>
          </svg>
        </div>
      </div>
      <div class="info__title">
        <div class="title">{{project.name}}</div>
      </div>
    </div>
    <swiper
        :direction="'vertical'"
        :slidesPerView="'auto'"
        :freeMode="true"
        :scrollbar="true"
        :mousewheel="true"
        :modules="modules"
        class="info__container swiper"
    >
      <swiper-slide>
        <div class="project-info">
          <div class="project-info__img">
            <swiper
                :loop="true"
                :lazy="true"
                :observer="true"
                :observeParents="true"
                :autoplay="{delay: 4500, disableOnInteraction: false}"
                class="slider swiper"
            >
              <swiper-slide v-for="photo in project.photos" v-bind:key="photo" class="slider__item swiper-slide">
                <img :src="photo" alt="" class="slider__img">
              </swiper-slide>
            </swiper>
          </div>
          <div class="project-table">
            <div class="project-table__item project-table__item_big">
              <div class="project-table__title">{{ $t('location') }}</div>
              <div class="project-table__value project-table__value--adress">{{project.location}}</div>
            </div>
            <div class="project-table__item">
              <div class="project-table__title">{{ $t('cadastral_number') }}</div>
              <div class="project-table__value" v-html="project.cadastral_number"></div>
            </div>
            <div class="project-table__item">
              <div class="project-table__title">{{ $t('industry_affiliation') }}</div>
              <div class="project-table__value">{{project.industry}}</div>
            </div>
            <div class="project-table__item">
              <div class="project-table__title">{{ $t('hazard_class') }}</div>
              <div class="project-table__value">{{project.hazard_class}}</div>
            </div>
            <div class="project-table__item">
              <div class="project-table__title">{{ $t('project_capacity') }}</div>
              <div class="project-table__value" v-html="project.project_capacity" />
            </div>
            <div class="project-table__item">
              <div class="project-table__title">{{ $t('price') }}</div>
              <div class="project-table__subtitle">{{ $t('total_cost') }}</div>
              <div class="project-table__value">{{project.price}}</div>
            </div>
            <div class="project-table__item">
              <div class="project-table__title">{{ $t('investments') }}</div>
              <div class="project-table__subtitle">{{ $t('need_investments') }}</div>
              <div class="project-table__value">{{project.investments}}</div>
            </div>
            <div class="project-table__item">
              <div class="project-table__title">{{ $t('return_investment') }}</div>
              <div class="project-table__value">{{project.return_period}}</div>
            </div>
            <div class="project-table__item">
              <div class="project-table__title">{{ $t('implementation_period') }}</div>
              <div class="project-table__value">{{project.implementation_period}}</div>
            </div>
            <div class="project-table__item">
              <div class="project-table__title">{{ $t('workplaces') }}</div>
              <div class="project-table__subtitle">{{ $t('new_jobs') }}</div>
              <div class="project-table__value">{{project.workplaces}}</div>
            </div>
            <div class="project-table__item">
              <div class="project-table__title">{{ $t('volume_revenues') }}</div>
              <div class="project-table__subtitle">{{ $t('budget_revenue') }}</div>
              <div class="project-table__value">{{project.budget_revenue}}</div>
            </div>
          </div>
          <div class="project-table">
            <div class="project-table__item project-table__item_big">
              <div class="project-table__title">{{ $t('contacts') }}</div>
              <div class="project-table__contacts">
                <p><strong>{{ $t('investment_authorized') }}:</strong> {{project.invest_authorized}}</p>
                <p v-if="project.address"><strong>{{ $t('address') }}:</strong> {{project.address}}</p>
                <p v-if="project.phone"><strong>{{ $t('phone') }}:</strong> {{project.phone}}</p>
                <p v-if="project.email"><strong>{{ $t('email') }}:</strong> {{project.email}}</p>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import "swiper/swiper-bundle.min.css";
import "swiper/modules/free-mode/free-mode.min.css";
import "swiper/modules/scrollbar/scrollbar.min.css";

import { FreeMode, Scrollbar, Mousewheel } from "swiper";

export default {
  name: "ProjectInfo",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [FreeMode, Scrollbar, Mousewheel],
    };
  },
  props: {
    project: Object
  }
}
</script>

<style scoped>

</style>