<template>
  <div class="bg">
    <div class="bg__lines">
      <div class="bg__line" style="bottom: -30%;"></div>
      <div class="bg__line" style="bottom: -30%;"></div>
      <div class="bg__line" style="bottom: -30%;"></div>
      <div class="bg__line" style="bottom: -30%;"></div>
      <div class="bg__line" style="bottom: -30%;"></div>
      <div class="bg__line" style="bottom: -30%;"></div>
      <div class="bg__line" style="bottom: -30%;"></div>
      <div class="bg__line" style="bottom: -30%;"></div>
      <div class="bg__line" style="bottom: -30%;"></div>
      <div class="bg__line" style="bottom: -30%;"></div>
      <div class="bg__line" style="bottom: -30%;"></div>
      <div class="bg__line" style="bottom: -30%;"></div>
      <div class="bg__line" style="bottom: -30%;"></div>
      <div class="bg__line" style="bottom: -30%;"></div>
      <div class="bg__line" style="bottom: -30%;"></div>
      <div class="bg__line" style="bottom: -30%;"></div>
    </div>
    <div class="bg__grid"></div>
    <div class="bg__squares">
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
      <div class="bg__square" style="opacity: 0.0;"></div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap';

export default {
  name: "AnimatedBackground",
  data() {
    return {
      squares: [],
      lines: [],
    }
  },
  mounted() {
    this.lines = document.querySelectorAll('.bg__line');
    this.squares = document.querySelectorAll('.bg__square');
    this.lines.forEach((line) => {
      gsap.timeline({
        repeat: -1,
        repeatRefresh: true,
        delay: gsap.utils.random(1, 5)
      })
          .to(line, {bottom: '110%', duration: 4 })
          .from(line, {bottom: '-30%', duration: 4, delay: gsap.utils.random(1, 5) })
    });

    this.squares.forEach((square) => {
      gsap.timeline({
        repeat: -1,
        repeatRefresh: true,
        delay: gsap.utils.random(5, 10),
      })
          .to(square, {opacity: 1, duration: 1, delay: gsap.utils.random(5, 10)})
          .to(square, {opacity: 0, duration: 1 })
    });
  },
  methods: {
    random(min, max) {
      if (max == null) { max = min; min = 0; }
      if (min > max) { var tmp = min; min = max; max = tmp; }
      return min + (max - min) * Math.random();
    }
  }
}
</script>

<style scoped>

</style>