<template>
  <div class="info">
    <div class="info__header">
      <div class="info__controls">
        <a href="javascript:void(0)" @click="$emit('back')" class="back">
          <svg class="icon icon-arrow">
            <use xlink:href="#icon-arrow"></use>
          </svg>
          {{ $t('back') }}
        </a>
        <div class="close js-manInfoClose" @click="$emit('toggle-info')">
          <svg class="icon icon-close">
            <use xlink:href="#icon-close"></use>
          </svg>
        </div>
      </div>
      <div class="info__title">
        <div class="title">{{project.name}}</div>
      </div>
    </div>
    <swiper
        :direction="'vertical'"
        :slidesPerView="'auto'"
        :freeMode="true"
        :scrollbar="true"
        :mousewheel="true"
        :modules="modules"
        class="info__container swiper"
    >
      <swiper-slide>
        <div class="project-info">
          <div class="project-info__img">
            <swiper
                :loop="true"
                :lazy="true"
                :observer="true"
                :observeParents="true"
                :autoplay="{delay: 4500, disableOnInteraction: false}"
                class="slider swiper"
            >
              <swiper-slide v-for="photo in project.photos" v-bind:key="photo" class="slider__item swiper-slide">
                <img :src="photo" alt="" class="slider__img">
              </swiper-slide>
            </swiper>
          </div>
          <div class="project-table">
            <div class="project-table__item project-table__item_big">
              <div class="project-table__title">{{ $t('additional_permitted_use') }}</div>
              <div v-if="project.permitted_use_two" class="project-table__value project-table__value--adress">
                {{project.permitted_use_two}}
              </div>
              <div v-if="project.permitted_use_three" class="project-table__value project-table__value--adress">
                {{project.permitted_use_three}}
              </div>
            </div>
          </div>
          <div class="project-table">
            <div class="project-table__item project-table__item_big">
              <div class="project-table__title">{{ $t('location') }}</div>
              <div class="project-table__value project-table__value--adress">{{project.location}}</div>
            </div>
            <div class="project-table__item">
              <div class="project-table__title">{{ $t('cadastral_number') }}</div>
              <div class="project-table__value" v-html="project.cadastral_number"></div>
            </div>
            <div class="project-table__item">
              <div class="project-table__title">{{ $t('industry_affiliation') }}</div>
              <div class="project-table__value">{{project.industry}}</div>
            </div>
            <div class="project-table__item">
              <div class="project-table__title">{{ $t('declared_area') }}</div>
              <div class="project-table__value">{{project.area_declared}}</div>
            </div>
            <div class="project-table__item">
              <div class="project-table__title">{{ $t('land_category') }}</div>
              <div class="project-table__value">{{project.land_category}}</div>
            </div>
            <div class="project-table__item">
              <div class="project-table__title">{{ $t('permitted_use') }}</div>
              <div class="project-table__value">{{project.permitted_use}}</div>
            </div>
            <div class="project-table__item">
              <div class="project-table__title">{{ $t('owner') }}</div>
              <div class="project-table__value">{{project.owner}}</div>
            </div>
          </div>
          <div class="project-info__subtitle">{{ $t('distance_from') }}:</div>
          <div class="project-table project-table__remoteness">
            <div class="project-table__item">
              <div class="project-table__title">{{ $t('distance_to_center') }}</div>
              <div class="project-table__subtitle">{{ project.distance_to_capacity_name }}</div>
              <div class="project-table__value">{{ project.distance_to_capacity }}</div>
            </div>
            <div class="project-table__item">
              <div class="project-table__title">{{ $t('nearest_town') }}</div>
              <div class="project-table__subtitle">{{ project.distance_to_city_name }}</div>
              <div class="project-table__value">{{ project.distance_to_city }}</div>
            </div>
            <div class="project-table__item">
              <div class="project-table__title">{{ $t('krasnodar') }}</div>
              <div class="project-table__subtitle">{{ $t('krasnodar') }}</div>
              <div class="project-table__value">{{ project.distance_to_krasnodar }}</div>
            </div>
            <div class="project-table__item">
              <div class="project-table__title">{{ $t('highways') }}</div>
              <div class="project-table__subtitle">{{ project.distance_to_road_name }}</div>
              <div class="project-table__value">{{ project.distance_to_road }}</div>
            </div>
            <div class="project-table__item">
              <div class="project-table__title">{{ $t('nearest_railway_station') }}</div>
              <div class="project-table__subtitle">{{ project.distance_to_train_station_name }}</div>
              <div class="project-table__value">{{ project.distance_to_train_station }}</div>
            </div>
            <div class="project-table__item">
              <div class="project-table__title">{{ $t('nearest_railroad_tracks') }}</div>
              <div class="project-table__subtitle">{{ project.distance_to_railway_name }}</div>
              <div class="project-table__value">{{ project.distance_to_railway }}</div>
            </div>
            <div class="project-table__item">
              <div class="project-table__title">{{ $t('airport') }}</div>
              <div class="project-table__subtitle">{{ project.distance_to_airport_name }}</div>
              <div class="project-table__value">{{ project.distance_to_airport }}</div>
            </div>
            <div class="project-table__item">
              <div class="project-table__title">{{ $t('seaport') }}</div>
              <div class="project-table__subtitle">{{ project.distance_to_sea_port_name }}</div>
              <div class="project-table__value">{{ project.distance_to_sea_port }}</div>
            </div>
          </div>
          <div class="project-info__subtitle">{{ $t('power_supply') }}:</div>
          <div class="project-table project-table__infrastructure">
            <div class="project-table__item project-table__item_big">
              <div class="project-table__title">{{ $t('name_power_center') }}</div>
              <div class="project-table__value">{{ project.distance_to_electricity_name }}</div>
            </div>
            <div class="project-table__item">
              <div class="project-table__title">{{ $t('distance_to_connection_point') }}</div>
              <div class="project-table__value">{{ project.distance_to_electricity }}</div>
            </div>
            <div class="project-table__item">
              <div class="project-table__title">{{ $t('free_power') }}</div>
              <div class="project-table__value">{{ distance_to_electricity_mwt }}</div>
            </div>
          </div>
          <div class="project-info__subtitle">{{ $t('gas_supply') }}:</div>
          <div class="project-table project-table__infrastructure">
            <div class="project-table__item project-table__item_big">
              <div class="project-table__title">{{ $t('name_gds') }}</div>
              <div class="project-table__value">{{ project.distance_to_gas_name }}</div>
            </div>
            <div class="project-table__item">
              <div class="project-table__title">{{ $t('distance_to_connection_point') }}</div>
              <div class="project-table__value">{{ project.distance_to_gas }}</div>
            </div>
            <div class="project-table__item">
              <div class="project-table__title">{{ $t('throughput_capacity') }}</div>
              <div class="project-table__value">{{ project.distance_to_gas_power }}</div>
            </div>
          </div>
          <div class="project-info__subtitle">{{ $t('water_supply') }}:</div>
          <div class="project-table project-table__infrastructure">
            <div class="project-table__item project-table__item_big">
              <div class="project-table__title">{{ $t('source_water_supply') }}</div>
              <div class="project-table__value">{{ project.distance_to_water_name }}</div>
            </div>
            <div class="project-table__item">
              <div class="project-table__title">{{ $t('distance_to_connection_point') }}</div>
              <div class="project-table__value">{{ project.distance_to_water }}</div>
            </div>
            <div class="project-table__item">
              <div class="project-table__title">{{ $t('power_water_supply') }}</div>
              <div class="project-table__value">{{ project.distance_to_water_power }}</div>
            </div>
          </div>
          <div class="project-table">
            <div class="project-table__item project-table__item_big">
              <div class="project-table__title">{{ $t('contacts') }}</div>
              <div class="project-table__contacts">
                <p><strong>{{ $t('investment_authorized') }}:</strong> {{project.invest_authorized}}</p>
                <p v-if="project.address"><strong>{{ $t('address') }}:</strong> {{project.address}}</p>
                <p v-if="project.phone"><strong>{{ $t('phone') }}:</strong> {{project.phone}}</p>
                <p v-if="project.email"><strong>{{ $t('email') }}:</strong> {{project.email}}</p>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import "swiper/swiper-bundle.min.css";
import "swiper/modules/free-mode/free-mode.min.css";
import "swiper/modules/scrollbar/scrollbar.min.css";

import { FreeMode, Scrollbar, Mousewheel } from "swiper";

export default {
  name: "LandInfo",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [FreeMode, Scrollbar, Mousewheel],
    };
  },
  props: {
    project: Object
  },
  computed: {
    distance_to_electricity_mwt: function () {
      return this.project.distance_to_electricity_mwt === 'уточняется при получении ТУ'
        || this.project.distance_to_electricity_mwt === 'Уточняется по заявке'
          ? this.$t('free_power_na')
          : this.project.distance_to_electricity_mwt;
    }
  }
}
</script>

<style scoped>

</style>