<template>
  <div class="main-title">
    {{ $t('interactive_catalog')}}<br>
    <span class="main-title__subtitle">{{ $t('investment_proposals')}}</span>
  </div>
  <div class="filter">
    <div class="filter__row" v-for="filter in filters" v-bind:key="filter.params">
      <div class="filter__title">{{ $t(filter.title) }}</div>
      <div class="filter__items">
        <div class="filter__item" v-for="param in filter.params" v-bind:key="param.title">
          <input
              :id="param.id"
              type="checkbox"
              class="filter__switch"
              v-on:input="changeFilter(param, $event.target.checked)"
              :checked="param.active">
          <label :for="param.id"  class="filter__label">
            <span class="filter__name">{{ param.title }}</span>
            <span class="filter__number">({{ param.count }})</span>
          </label>
        </div>
        <div v-if="filter.all" class="filter__item">
          <input
              :id="filter.code"
              type="checkbox"
              class="filter__switch"
              v-on:input="toggleSelectAll(filter)"
              :checked="isAllSelected(filter)">
          <label :for="filter.code" class="filter__label filter__label--all">
            <span class="filter__name">{{ $t('select_all') }}</span>
            <span class="filter__number">({{ filter.count }})</span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Filter",
  props: {
    projects: Array,
    filterGroup: {
      type: Object,
      default() {
        return {
          type_project: "type_project",
          industry: "industry"
        }
      }
    }
  },
  data() {
    return {
      activeFilters: this.initialParams(this.filterGroup),
      filters: this.createFilter(),
      filteredProjects: null,
      countFilters: 0,
      chooseAllStates: this.initChooseAll(this.filterGroup)
    };
  },
  mounted() {
    this.applyFilter();
  },
  methods:  {
    initialParams(params) {
      let groups = {};
      for (let i in params) {
        groups[i] = [];
      }
      return groups;
    },
    initChooseAll(params) {
      let groups = {};
      for (let i in params) {
        groups[i] = false;
      }
      return groups;
    },
    prepareParams(objParam) {
      let params = [];
      for (let i in objParam) {
        params.push(objParam[i]);
      }
      return params;
    },
    applyFilter: function () {
      this.filteredProjects = [];
      if (this.countFilters > 0) {
        this.projects.forEach((project) => {
          let isExist = true;
          for (let i in this.activeFilters) {
            if (this.activeFilters[i].length > 0 && !this.activeFilters[i].includes(project[i])) {
              isExist = false;
            }
          }
          if (isExist)
            this.filteredProjects.push(project);
        });
        this.$emit('filtered-projects', this.filteredProjects);
      } else {
        this.$emit('filtered-projects', this.projects);
      }
    },
    createFilter: function() {
      let groups = [];
      this.filteredProjects = [];
      for (let i in this.filterGroup) {
        let params = {},
            countInGroup = 0,
            idx = 1;
        this.projects.forEach((project) => {
          if (!(project[i] in params)) {
            params[project[i]] = {
              "id": i+"_"+idx,
              "title": project[i],
              "type": i,
              "count": 0,
              "active": (this.activeFilters && this.activeFilters[i].includes(project[i]))
            };
            idx++;
          }
          let isExist = true;
          for (let k in this.activeFilters) {
            let activeParams = this.activeFilters[k];
            if (k !== i && (activeParams.length > 0 && !activeParams.includes(project[k]))) {
              isExist = false;
              break;
            }
          }
          if (isExist) {
            params[project[i]]['count']++;
            countInGroup++;
          }
        });
        groups.push({
          "title": this.filterGroup[i],
          "code": i,
          "all": i === 'industry',
          "count": countInGroup,
          "params": this.prepareParams(params)
        });
      }
      return groups;
    },
    changeFilter(param, isActive) {
      if (!isActive) {
        let index = this.activeFilters[param.type].indexOf(param.title);
        this.activeFilters[param.type].splice(index, 1);
        this.chooseAllStates[param.type] = false;
      } else if (!this.activeFilters[param.type].includes(param.title)) {
        this.activeFilters[param.type].push(param.title);
      }
      this.countFilters = 0;
      for (let i in this.activeFilters) {
        this.countFilters += this.activeFilters[i].length;
      }
      this.filters = this.createFilter();
      this.applyFilter();
    },
    toggleSelectAll(filter) {
      let state = this.isAllSelected(filter)
      if (!state) {
        this.activeFilters[filter.code] = [];
        filter.params.forEach((param) => {
          this.activeFilters[filter.code].push(param.title);
        })
      } else {
        this.activeFilters[filter.code] = [];
      }
      this.chooseAllStates[filter.code] = !state;
      this.filters = this.createFilter();
      this.applyFilter();
    },
    isAllSelected(filter) {
      return filter.code in this.chooseAllStates ? this.chooseAllStates[filter.code] : false
    }
  }
}
</script>

<style scoped>

</style>