<template>
  <iframe
      v-if="loaded && !project.video"
      :src="iframe.src"
      :style="iframe.style"
      :height="iframe.style.height"
      :width="iframe.style.width"
      type="application/pdf"
      frameborder="0"
  ></iframe>
  <div v-show="loaded && project.video" class="project-video">
    <video ref="video" class="project-video__item" preload="none" loop></video>
  </div>
</template>

<script>
export default {
  name: "Project",
  props: {
    project: Object
  },
  data() {
    return {
      loaded: false,
      iframe: {
        src: null,
        style: null,
        wrapperStyle: null,
      }
    }
  },
  mounted() {
    this.iframe.style = {
      position: 'absolute',
      width: window.innerWidth,
      height: window.innerHeight,
    };
    this.iframe.src = this.project.tour;
    this.loaded = true;

    if (this.project.video) {
      this.$refs.video.src = this.project.video;
      this.$refs.video.play()
    }
  }
}
</script>

<style scoped>

</style>