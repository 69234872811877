<template>
  <div class="header-logo">
    <a href="javascript:void(0)" class="" @click="onGoToHome"><img class="header-logo__img" :src="require('../assets/images/logo.svg')" alt="Краснодарский край"/></a>
    <a href="javascript:void(0)" class="" @click="onGoToHome"><img class="header-logo__img header-logo__img--en" :src="require('../assets/images/logo_en.svg')" alt="Краснодарский край"/></a>
  </div>
  <div class="header-controls">
    <a href="javascript:void(0)" @click="onGoToHome" class="header-controls__button">
      <svg class="icon icon-home">
        <use xlink:href="#icon-home"></use>
      </svg>
    </a>
    <a href="javascript:void(0)" @click="onLanguageChanged" class="header-controls__button">
      {{ $i18n.fallbackLocale.charAt(0).toUpperCase() + $i18n.fallbackLocale.slice(1) }}
    </a>
  </div>
  <section class="main js-mainInfo" v-if="loaded" ref="main" :class="{
    'main--zone': screenLevel === 2,
    'main--district': screenLevel === 3,
    'main--project': screenLevel === 4,
    'js-mainInfo--open': isShowInfo}">
    <div v-if="screenLevel > 0" class="main__info-toogle js-mainInfoOpen" :class="{'js-mainInfoOpen--visible': !isShowInfo}" @click="onToggleInfo">
      <svg class="icon icon-arrow-next">
        <use xlink:href="#icon-arrow-next"></use>
      </svg>
    </div>
    <div class="main__filter" :class="{'main__filter--hide': screenLevel > 1, 'main__filter--active': screenLevel === 1}">
      <div class="close js-manInfoClose" @click="onToggleInfo">
        <svg class="icon icon-close">
          <use xlink:href="#icon-close"></use>
        </svg>
      </div>
      <Filter
          v-bind:projects="projects"
          v-on:filtered-projects="onFilteredProjects"/>
    </div>
    <div class="main__info">
      <ProjectInfo
          v-if="screenLevel === 4 && project.type_project_id === 'investment_project'"
          v-bind:project="project"
          v-on:toggle-info="onToggleInfo"
          v-on:back="onBack"
      />
      <LandInfo
          v-if="screenLevel === 4 && project.type_project_id === 'investment_platform'"
          v-bind:project="project"
          v-on:toggle-info="onToggleInfo"
          v-on:back="onBack"
      />
      <DistrictInfo
          v-if="screenLevel === 3"
          v-bind:district="district"
          v-bind:projects="projects"
          @project-selected="onProjectSelected"
          v-on:toggle-info="onToggleInfo"
          v-on:back="onBack"
      />
      <DistrictsList
          v-if="screenLevel === 2"
          v-bind:zone="zone"
          v-bind:districts="districts"
          v-on:district-selected="onDistrictSelected"
          v-on:toggle-info="onToggleInfo"
          v-on:back="onBack"
      />
    </div>
    <div class="main__map js-Map">
      <Project
          v-if="screenLevel === 4"
          v-bind:project="project" />
      <Map
          v-bind:level="screenLevel"
          v-bind:projects="filteredProjects"
          v-bind:locale="$i18n.locale"
          v-bind:curDistrict="district"
          @zone-selected="onZoneSelected"
          @district-selected="onDistrictSelected"
          v-on:project-selected="onProjectSelected"/>
    </div>
  </section>
  <div class="footer">
    <div class="footer__qr">
      <div class="qr">
        <img :src="require('../assets/images/qr.svg')" class="qr__img" alt="investkuban.ru">
        <a href="https://investkuban.ru" target="_blank" class="qr__link">investkuban.ru</a>
      </div>
    </div>
    <div class="footer__hint">
      <div v-if="screenLevel < 4" class="hint js-projectInfo">
        <div class="hint__icon">
          <svg class="icon icon-touch">
            <use xlink:href="#icon-touch"></use>
          </svg>
        </div>
        <div v-if="screenLevel === 1" class="hint__title">{{ $t('select_zone') }}</div>
        <div v-if="screenLevel === 2" class="hint__title">{{ $t('select_district') }}</div>
        <div v-if="screenLevel === 3" class="hint__title">{{ $t('select_project') }}</div>
      </div>
      <div v-if="screenLevel === 4" class="hint js-projectInfo" @click="onToggleInfo">
        <div class="hint__icon">
          <svg class="icon icon-touch">
            <use xlink:href="#icon-touch"></use>
          </svg>
        </div>
        <div v-if="isShowInfo" class="hint__title">{{ $t('hide_description') }}</div>
        <div v-if="!isShowInfo" class="hint__title">{{ $t('show_description') }}</div>
      </div>
    </div>
    <div class="footer__legend">
      <div class="legend">
        <div class="legend__item">
          <div class="legend__value legend__value--project"></div>
          {{ $t('project') }}
        </div>
        <div class="legend__item">
          <div class="legend__value legend__value--area"></div>
          {{ $t('area') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import axios from "axios";
import Map from '@/components/Map.vue'
import Filter from "../components/Filter";
import DistrictsList from "../components/DistrictsList";
import DistrictInfo from "../components/DistrictInfo";
import Project from "../components/Project";
import ProjectInfo from "../components/ProjectInfo";
import LandInfo from "../components/LandInfo";

export default {
  name: 'Home',
  components: {
    LandInfo,
    Project,
    DistrictInfo,
    DistrictsList,
    ProjectInfo,
    Filter,
    Map
  },
  data() {
    return {
      loaded: false,
      zones: null,
      zone: null,
      districts: null,
      district: null,
      projects: null,
      filteredProjects: [],
      project: null,
      zoneDistricts: [],
      screenLevel: 1,
      isShowInfo: false
    };
  },
  mounted() {
    document.body.classList.add('page', 'page--main', 'page--' + this.$i18n.locale);
    axios
        .all([
          axios.get('/api/1.0/ru/zones'),
          axios.get('/api/1.0/ru/projects'),
          axios.get('/api/1.0/ru/districts'),
        ])
        .then(axios.spread((zonesRequest, projectsRequest, districtsRequest) => {
          this.zones = zonesRequest.data.data;
          this.projects = projectsRequest.data.data;
          this.districts = districtsRequest.data.data;
        }))
        .finally(() => this.loaded = true);
  },
  methods: {
    onLanguageChanged() {
      let lang = this.$i18n.locale;
      this.loaded = false;
      this.screenLevel = 1;
      this.isShowInfo = false;
      this.$i18n.locale = this.$i18n.fallbackLocale;
      this.$i18n.fallbackLocale = lang;
      document.body.classList.remove('page--zone', 'page--district', 'page--project', 'page--' + this.$i18n.fallbackLocale);
      document.body.classList.add('page--main', 'page--'+this.$i18n.locale);
      axios
          .all([
            axios.get('/api/1.0/'+this.$i18n.locale+'/zones'),
            axios.get('/api/1.0/'+this.$i18n.locale+'/projects'),
            axios.get('/api/1.0/'+this.$i18n.locale+'/districts'),
          ])
          .then(axios.spread((zonesRequest, projectsRequest, districtsRequest) => {
            this.zones = zonesRequest.data.data;
            this.projects = projectsRequest.data.data;
            this.districts = districtsRequest.data.data;
          }))
          .finally(() => this.loaded = true);
    },
    onToggleInfo() {
      this.isShowInfo = !this.isShowInfo;
    },
    onZoneSelected(zoneCode) {
      this.zone = this.zones.find((zone) => zone.code === zoneCode);
      this.isShowInfo = false;
      setTimeout(() => {
        this.screenLevel = 2;
        document.body.classList.remove('page--main');
        document.body.classList.add('page--zone');
        this.isShowInfo = true;
      }, 250);
    },
    onDistrictSelected(districtCode) {
      this.isShowInfo = false;
      this.district = this.districts.find((district) => district.code === districtCode);
      setTimeout(() => {
        this.screenLevel = 3;
        document.body.classList.remove('page--zone');
        document.body.classList.add('page--district');
        this.isShowInfo = true;
      }, 1000);
    },
    onProjectSelected(project) {
      this.project = project;
      this.isShowInfo = false;
      setTimeout(() => {
        this.screenLevel = 4;
        document.body.classList.remove('page--district')
        document.body.classList.add('page--project');
        this.isShowInfo = true;
      }, 250);
    },
    onFilteredProjects(projects) {
      this.filteredProjects = projects;
    },
    onBack() {
      this.isShowInfo = false;
      setTimeout(() => {
        this.screenLevel--;
        this.isShowInfo = true;
        if (this.screenLevel === 3) {
          document.body.classList.remove('page--project');
          document.body.classList.add('page--district');
        } else if (this.screenLevel === 2) {
          document.body.classList.remove('page--district');
          document.body.classList.add('page--zone');
        } else if (this.screenLevel === 1) {
          document.body.classList.remove('page--zone');
          document.body.classList.add('page--main');
          this.isShowInfo = false;
        }
      }, 250);
    },
    onGoToHome() {
      this.isShowInfo = false;
      setTimeout(() => {
        document.body.classList.remove('page--zone', 'page--district', 'page--project');
        document.body.classList.add('page--main');
        this.screenLevel = 1;
      }, 250);
    }
  }
}
</script>
